import React, { useEffect, useState } from 'react';

import './App.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { Debug, DialogContactUs, DialogDesktopSettings, DialogGDPR, DialogHelp, DialogHelpSelect, DialogHelpVideo, GetDefaultDesktop, GetLanguage, LoadCountry, Loading, LoadLanguage, LoadLogin, Menu, MenuTitle, MenuTop, ResetLogin, SaveCountry, SaveLanguage, ShowError } from './items';
import { styles } from './styles';
import './items.css';

// IMAGES
import logo from './react/app/logo.png';

import { Backdrop, CircularProgress, Fade, useMediaQuery } from '@mui/material';
import { Testovanie } from './Test';
import { Shortcuts } from './shortcuts';
import { Desktop } from './desktop';
import { faB, faCalendarDays, faCashRegister, faChartBar, faComments, faDollarSign, faE, faEdit, faEye, faGlobe, faIdCard, faInfo, faInfoCircle, faLayerGroup, faMessage, faP, faR, faShop, faShoppingCart, faUsers, faWifi } from '@fortawesome/free-solid-svg-icons';
import { Settings } from './Settings';
import { Products } from './products';
import { Categories } from './categories';
import { ProductsInfo } from './products_info';
import { Groups } from './groups';
import { Services } from './services';
import { ServicesInfo } from './services_info';
import { Clients } from './clients';
import { ClientCard } from './client_card';
import { Calendar } from './calendar';
import { ProductsShop } from './products_shop';
import { ProductsShopHistory } from './products_shop_history';
import { ProductsBooking } from './products_booking';
import { ProductsBookingHistory } from './products_booking_history';
import { BonusBooking } from './bonus_booking';
import { BonusBookingHistory } from './bonus_booking_history';
import { EshopHistory } from './eshop_history';
import { StatsToday } from './stats_today';
import { StatsInfo } from './stats_info';
import { StatsProductsSalon } from './stats_products_salon';
import { StatsProductsEshop } from './stats_products_eshop';
import { StatsServices } from './stats_services';
import { StatsProductsInfo } from './stats_products_info';
import { StatsClients } from './stats_clients';
import { StatsProductsBooking } from './stats_products_booking';
import { StatsEmployees } from './stats_employees';
import { Informations } from './informations';
import { WebPage } from './web_page';
import { WebSettings } from './web_settings';
import { RegisterSalon } from './RegisterSalon';
import { PinCode } from './pin_code';
import { Chat } from './chat';
import { Salons } from './salons';
import { SalonCard } from './salon_card';
import { StatsProductsSalonAgent } from './stats_products_salon_agent';
import { StatsProductsEshopAgent } from './stats_products_eshop_agent';
import { StatsServicesAgent } from './stats_services_agent';
import { StatsInfoSalons } from './stats_info_salons';
import { StatsInfoSalonAgent } from './stats_info_salon_agent';
import { ProductsStatsAgent } from './products_stats_agent';
import { StatsSalonsServices } from './stats_salons_services';
import { StatsSalonsCards } from './stats_salons_cards';
import { StatsSalonsReps } from './stats_salons_reps';
import { BonusBookingAgent } from './bonus_booking_agent';
import { ProductsBookingAgent } from './products_booking_agent';
import { BonusInfo } from './bonus';
import { Reklamacie } from './reklamacie';
import { NewsAgent } from './news_agent';
import { Ipad } from './ipad';
import { LessonsAgent } from './lessons_agent';
import { Lessons, LessonsVideo } from './lessons';
import { LessonsCategories } from './lessons_categories';
import { LessonsAbsolved } from './lessons_absolved';
import { WebInfo } from './web_info';
import { StatsLessonsAgent } from './stats_lessons_agent';
import { StatsLessonsAgentProducts } from './stats_lessons_agent_products';
import { TestConditions } from './TestCondition';
import { ReklamacieSalon } from './reklamacie_salon';
import { Faktury } from './faktury';
import { ChatAgents } from './chat_agents';
import { ChatAgentsRep } from './chat_agents_rep';
import { Expirations, ExpirationsAgent } from './expirations';


export const Main = (props) => {
    const navigate = useNavigate();

    const [isBusy, setBusy] = useState(false);
    const [theme, setTheme] = useState(1);

    // INTERNET CONNECTION
    const [isOnline, setIsOnline] = useState(navigator.onLine);

    // APLIKACIA
    const [appVersion, setAppVersion] = useState(2);
    const [remoteApp, setRemoteApp] = useState(props.remote == true ? true : false); // aplikacia spustená z iPAD aplikácie
    const [showRegister, setShowRegister] = useState(false);
    const [language, setLanguage] = useState(0);
    const [languageManual, setLanguageManual] = useState(false);
    const [token, setToken] = useState('');
    const [logged, setLogged] = useState(true);
    const [menuData, setMenuData] = useState([]);
    const [menuLabel, setMenuLabel] = useState('');
    const [shortcuts, setShortcuts] = useState([]);
    const [version, setVersion] = useState('');
    const [appFocus, setAppFocus] = useState(false);

    // VZHLAD APKY
    const [offset, setOffset] = useState(16);
    const [radius, setRadius] = useState(16);

    // MENU
    const [bodyID, setBodyID] = useState(0);
    const [bodyIDUnlock, setBodyIDUnlock] = useState(0); // MENU-ID, ktore sa spusti po vlození PINU
    const [menuID, setMenuID] = useState(-1);
    const [label, setLabel] = useState('');

    // SALON - AGENT - NASTAVENIA
    const [user, setUser] = useState(false);
    const [typ, setTyp] = useState(2); // TYP UCTU
    const [admin, setAdmin] = useState(false); // AGENT ADMIN -> GM
    const [settings, setSettings] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [employeeSelected, setEmployeeSelected] = useState(false);
    const [employeeID, setEmployeeID] = useState(0);

    // KLIENT - ID POSLEDNY OTVORENY
    const [clientID, setClientID] = useState(0);
    const [client, setClient] = useState(false);
    const [showCard, setShowCard] = useState(false);
    const [showSalonCard, setShowSalonCard] = useState(false);

    // ZOZNAM KRAJIN
    const [countries, setCountries] = useState(false);
    const [country, setCountry] = useState(false); // vybrata krajina

    // BANNER
    const [banner, setBanner] = useState(false);

    // BANNER
    const [pinEnabled, setPinEnabled] = useState(false);
    const [pin, setPin] = useState(false);
    const [showPin, setShowPin] = useState(false);
    const [pinVerified, setPinVerified] = useState(false);

    // OKNO
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    // GDPR
    const [showGDPR, setShowGDPR] = useState(false);

    // DESKTOP SETTINGS
    const [showDesktopSettings, setShowDesktopSettings] = useState(false);

    // HELP
    const [showHelpSelect, setShowHelpSelect] = useState(false);
    const [showHelp, setShowHelp] = useState(false);
    const [showContactUs, setShowContactUs] = useState(false);
    const [showVideoHelp, setShowVideoHelp] = useState(false);

    // POCET NOVYCH SPRAV V DISKUSII
    const [chatNotify, setChatNotify] = useState(0);

    // POCET NOVYCH OBJEDNAVOK A REKLAMACII
    const [productsNotify, setProductsNotify] = useState(0);
    const [bonusNotify, setBonusNotify] = useState(0);
    const [reklamacieNotify, setReklamacieNotify] = useState(0);
    const [expireNotify, setExpireNotify] = useState(0);
    const [comNotify, setComNotify] = useState(0);

    // CHYBA
    const [showError, setShowError] = useState(false);

    // BLACK FADE
    const [showBlack, setShowBlack] = useState(false);


    const isLarge = useMediaQuery('(min-width:' + (parseInt(global.device_medium) + 1) + 'px)');
    const isMedium = useMediaQuery('(min-width: ' + (parseInt(global.device_small) + 1) + 'px) and (max-width: ' + global.device_medium + 'px)');
    const isSmall = useMediaQuery('(max-width:' + global.device_small + 'px)');


    var lang = GetLanguage(language);

    var focused = false;
    var started = false;


    useEffect(() => {

        Debug('------------------------');
        Debug('Esthederm Salon');
        Debug('------------------------');

        Debug('isSmall: ' + isSmall);
        Debug('isMedium: ' + isMedium);
        Debug('isLarge: ' + isLarge);

        function handleResize() {
            setWidth(window.innerWidth);
            //setHeight(window.innerHeight);
        }

        // Update network status
        const handleStatusChange = () => {
            setIsOnline(navigator.onLine);
        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // WINDOW GOT FOCUS
        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        if (started == false) {
            started = true;

            if (window.innerWidth <= global.device_small) {
                // MOBILE
                setRadius(0);
                setOffset(0);
            } else if (window.innerWidth > global.device_small && window.innerWidth <= global.device_medium) {
                setOffset(global.offset / 2);
                setRadius(global.radius);
            } else {
                // DESKTOP
                setRadius(global.radius);
                setOffset(global.offset);

                // RELOAD PAGE - AK SA ZMENI VELKOST OKNA               
                window.addEventListener('resize', handleResize);
            }

            setWidth(window.innerWidth);
            setHeight(window.innerHeight);

            SaveLanguage(0);
            lang = LoadLanguage();
            setMenuData(lang.menu);

            var login = LoadLogin();
            let version = login.version == undefined ? 2 : login.version;
            setAppVersion(version);

            db_token(login.token, true);

        }

        // Pravidelná kontrola aplikácie
        // ! SPOSOBUJE PROBLEMY - PREKRESLENIE A VYMAZANIE Rozpísaného textového pola
        /*
        let intervalID = setInterval(() => {
            Debug('##### TIMER -> check data #####');

            var login = LoadLogin();
            db_token(login.token, true);
        }, global.check_period);
        */

        // REMOVE AFTER APPLICATION ENDS
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('focus', handleFocus);
            window.removeEventListener('blur', handleBlur);
            //clearInterval(intervalID);
        };

    }, [window.innerWidth, isOnline])


    const handleFocus = (e) => {

        if (focused == false) {
            focused = true;
            Debug('FOCUS');

            var login = LoadLogin();
            db_token(login.token, true);

            setAppFocus(true);
        }
    }

    const handleBlur = (e) => {
        focused = false;
        setAppFocus(false);
    }

    const db_token = async (token, changeCoutry) => {
        // získanie údajov USER -> z tokenu + aktualizácia informácii
        Debug('GET TOKEN');

        if (isBusy == false) {

            if (isBusy == true) {
                setBusy(true);
            }
            try {
                const response = await fetch(
                    global.db_url + 'token_check', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: token
                    })
                })

                const json = await response.json();

                Debug(json);
                setBusy(false);
                if (json.ok > 0) {
                    var user_ = json.user;

                    if (user_ != false) {
                        setToken(token);

                        var user_typ = user_.typ;
                        var user_admin = false;

                        if (user_typ == global.account_agent_gm) {
                            user_typ = global.account_agent;
                            user_.typ = global.account_agent;
                            user_admin = true;
                        }

                        if (user_typ == global.account_admin) {
                            // ZMENA UCTU ADMIN NA GM-ADMIN
                            user_typ = global.account_agent;
                            user_.typ = global.account_agent;
                            user_admin = true;
                        }

                        if (user_typ == global.account_salon && user_.salon1_id == 1) {
                            // ZMENA UCTU nA MEDICAL
                            user_.typ = global.account_medical;
                            user_typ = global.account_medical;
                        }

                        setTyp(user_typ);
                        setAdmin(user_admin);
                        setUser(user_);
                        setTheme(parseInt(user_.theme));

                        var settings_ = json.settings;
                        if (json.settings != false) {
                            if (json.settings.desktop_settings != null) {
                                settings_ = json.settings;
                                if (settings_.desktop_settings != null) {
                                    settings_.desktop_settings = JSON.parse(settings_.desktop_settings);
                                }
                            } else {
                                settings_.desktop_settings = GetDefaultDesktop(GetLanguage(user_.language), user_typ);
                            }
                        } else {
                            settings_ = {
                                desktop_settings: GetDefaultDesktop(GetLanguage(user_.language), user_typ)
                            };
                        }

                        setSettings(settings_);

                        // JAZYK
                        var lang_ = lang;
                        if (languageManual == false) {
                            let language_ = user_.language;
                            setLanguage(language_);
                            lang_ = GetLanguage(language_);
                            setMenuData(lang_.menu);
                        }

                        CreateShortcuts(user_typ, lang_, settings_, json.client, user_);

                        setBanner(json.banner);
                        setClient(json.client);

                        // *********************************************************************************************************
                        // ZAMESTNANCI
                        // *********************************************************************************************************
                        var list = [...json.employees];

                        // zoznam zamestnancov + salon id:0
                        var data = { id: 0, name: user_.salon_name, surname: '', color: 0, photo: 2, enabled: true };
                        list.push(data);

                        list.sort((a, b) => {
                            if (a.id > b.id) {
                                return 1;
                            } else {
                                return -1;
                            }
                        })
                        setEmployees(list);

                        // KONTROLA, JE USER ZAREGISTROVANY
                        if (user_ != false) {
                            if (user_.name.trim() == '' && user_.surname.trim() == '') {
                                setTimeout(() => {
                                    setShowRegister(true);
                                }, 500);
                            }
                        }

                        // ODSUHLASENIE GDPR - V2 -> LEN WEBOVA VERZIA
                        if (user_.gdpr_v2 == false && user_typ == global.account_salon) {
                            if (remoteApp == false) {
                                setShowGDPR(true);
                            }
                        }

                        // OCHRANA PIN KODOM
                        setPinEnabled(user_.protect == false ? false : true);
                        setPin(user_.pin);

                        // VERZIA APLIKACIE
                        setVersion(json.version);

                        // ****************************************************************************************
                        // ZOZNAM KRAJIN + VYBER KRAJINY PODLA AGENTA
                        setCountries(json.countries);
                        if (json.countries != false) {

                            // --------------------------------------------------------------------------------
                            // NASTAVENIE KRAJINY
                            // --------------------------------------------------------------------------------
                            // kontrola, ci je ulozená hodnota
                            var country_id = parseInt(LoadCountry());
                            var lang_id = parseInt(user_.language);
                            if (country_id < 0) {
                                // KRAJINA NEBOLA ULOZENA - ULOZENIE HODNOTY PODLA LANGUAGE_ID
                                SaveCountry(lang_id + 1);
                            } else {
                                // KRAJINA BOLA ULOZENA - vytvorenie language_id (country_id - 1)                            
                                lang_id = country_id - 1;
                            }
                            Debug('country.language = ' + lang_id);
                            // --------------------------------------------------------------------------------

                            var country_ = json.countries.find(x => x.language_id == lang_id);
                            if (country_ != undefined) {
                                if (changeCoutry == true) {
                                    setCountry(country_);
                                }
                            } else {
                                setCountry(false);
                            }
                        }
                        // ****************************************************************************************

                        // POCET NOVYCH SPRAV V DISKUSII
                        setChatNotify(json.chat_count);

                        // POCET NOVYCH OBJEDNAVOK / REKLAMACII
                        setProductsNotify(json.products_count);
                        setBonusNotify(json.bonus_count);
                        setReklamacieNotify(json.reklamacie_count);
                        setComNotify(json.com_count);

                        // POCET NEVYBAVENYCH EXPIRACII
                        setExpireNotify(json.expire_count);

                        setAppFocus(true);

                    } else {
                        // neexistujuci user -> spat na login                        
                        ResetLogin();
                        navigate('/logout');
                    }
                } else {
                    setShowError(true);
                }

            } catch (error) {
                console.log(error);
                setBusy(false);
            }
        }
    }

    const db_update = async (data) => {
        try {
            const response = await fetch(
                global.db_url + 'user_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
            }

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const MenuTopPress = (typ, children_id, item) => {

        if (typ == 1) {
            // DESKTOP SETTINGS
            setShowDesktopSettings(true);
        }
        else if (typ == 2) {
            // NASTAVENIA
            setLabel(lang.settings);
            if (pinEnabled == true) {
                setPinVerified(false);
                setBodyIDUnlock(9901);
                setShowPin(true);
            } else {
                setBodyID(9901);
            }
        }
        else if (typ == 3) {
            // HELP
            setShowHelpSelect(true);
        }
        else if (typ == 5) {
            // ZMENA ZAMESTNANCA
            setEmployeeID(item);
        }
        else if (typ == 6) {
            // ZMENA JAZYKA V TESTOVACOM UCTE
            var language_ = 0;
            if (language == 0) {
                language_ = 1;
            } else {
                language_ = 0;
            }
            setLanguageManual(true);
            setLanguage(language_);
            let lang_ = GetLanguage(language_);
            CreateShortcuts(user.typ, lang_, settings, client, user);
            setMenuData(lang_.menu);
        }
        else if (typ == 7) {
            // ZMENA KRAJINY            
            setCountry(item);
            // Ulozenie country.id -> je kontrolované pri kontrole tokenu DB_Token()
            SaveCountry(item.id);
        }
        else if (typ == 8) {
            // ZMENA TEMY - FARIEB            
            var n = parseInt(theme) + 1;

            if (n > 2) {
                n = 0;
            }

            let data = {
                id: user.id,
                theme: n
            }
            setShowBlack(true);
            setTimeout(() => {
                setTheme(n);
            }, 500);
            setTimeout(() => {
                setShowBlack(false);
            }, 900);


            db_update(data);
        }
        else {
            // stlacene menu-button
            MenuPress(children_id, item);
        }
    }

    const MenuPress = (typ, item) => {
        if (typ == 0) {
            Home();
        }
        if (typ == 1) {
            if (item.id == 0) {
                // SPAT NA HLAVNE MENU
                Home();
            } else {
                if (item.children != undefined) {
                    // zobrazenie children menu
                    setMenuData(item.children);
                    setMenuLabel(item.name);
                } else {
                    // vykonanie príkazu                    
                    setLabel(item.label);
                    setBodyID(item.id);
                }
            }
        }
        if (typ == 999) {
            // ID MENU OPENED            
            setMenuID(item.id);
        }
    }

    const MenuTitlePress = (typ, data) => {
        // MENU TITLE - KLIKNUTIE NA AVATAR        
        if (typ == 1) {
            user.photo = data.photo;
        }
    }

    const Home = () => {
        // MENU -> HOME
        setMenuData(lang.menu);
        setMenuLabel('');
        setBodyID(0);
        var login = LoadLogin();
        db_token(login.token, false);
    }

    const ShortcutPress = (item) => {
        // KLIKNUTIE NA SKRATKU        
        if (item.typ == 0 || item.typ == 2) {
            // HLAVNE SKRATKY
            setLabel(item.label);
            if (pinEnabled == true && item.exec_id == 201) {
                setPinVerified(false);
                setShowPin(true);
                setBodyIDUnlock(item.exec_id);
            } else {
                setBodyID(item.exec_id);
            }

        }
        if (item.typ == 1) {
            // ZAMESTNANCI
            setEmployeeSelected(item);
            setEmployeeID(item.exec_id);
        }
        if (item.typ == 3) {
            // POSLEDNA OTVORENA KARTA ZAKAZNIKA            
            setClientID(item.exec_id);
            setShowCard(true);
        }
        if (item.typ == 4) {
            // POSLEDNA OTVORENA KARTA SALONA            
            setClientID(item.exec_id);
            setShowSalonCard(true);
        }
    }

    const CreateShortcuts = (typ, lang, settings_, client_, user_) => {
        /* --------------------------------------------------------------
                        TVORBA SKRATIEK
     
                        TYP     0 -> príkaz - ikona
                                2 -> príkaz - prvé pismenko z textu
                                1 -> zamestnanci
                                3 -> posledná otvorená karta
                        -------------------------------------------------------------- */
        // HLAVNE SKRATKY
        var items = [];

        if (typ == global.account_salon) {
            // PREDEFINOVANE SKRATKY - SALON
            items.push({ id: 0, label: lang.clients, icon: faUsers, typ: 0, exec_id: 9020, color: 0 });
            if (user_.forum_enabled == true) {
                items.push({ id: 1, label: lang.chat, icon: faComments, typ: 0, exec_id: 9021, color: 0 });
            }
            items.push({ id: 2, label: lang.calendar, icon: faCalendarDays, typ: 0, exec_id: 9022, color: 0 });
            items.push({ id: 3, label: lang.chat_agents, icon: faMessage, typ: 0, exec_id: 9023, color: 0 });
        }
        if (typ == global.account_agent) {
            // PREDEFINOVANE SKRATKY - AGENT
            items.push({ id: 0, label: lang.salons, icon: faUsers, typ: 0, exec_id: 5001, color: 0 });
            items.push({ id: 1, label: lang.chat_agents, icon: faMessage, typ: 0, exec_id: 9024, color: 0 });
        }

        var n = items.length;

        if (settings_ != false) {
            if (settings_.desktop_settings != false) {
                // AGENT -> OBJEDNAVKA PRODUKTOV
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'booking_products');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.sc_salon_booking_products, icon: faP, typ: 0, exec_id: 5101, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // AGENT -> OBJEDNAVKA BONUSU
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'booking_bonus');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.sc_salon_booking_bonus, icon: faB, typ: 0, exec_id: 5102, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // AGENT -> REKLAMACIE
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'reklamacie');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.reklamacie, icon: faR, typ: 0, exec_id: 5103, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // AGENT -> EXPIRACIE
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'reklamacie');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.expirations_, icon: faE, typ: 0, exec_id: 5104, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // SALON -> DENNA ŠTATISTIKA
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'stat_day');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.sc_stats_day, icon: faChartBar, typ: 0, exec_id: 201, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // SALON -> PREDAJ PRODUKTOV
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'product_selling');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.sc_selling, icon: faCashRegister, typ: 0, exec_id: 101, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // SALON -> OBJEDNAVKA PRODUKTOV NA SKLAD
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'product_booking');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.sc_products_booking, icon: faLayerGroup, typ: 0, exec_id: 106, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // SALON -> ÚPRAVA PRODUKTOV
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'product_settings');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.sc_products_settings, icon: faEdit, typ: 0, exec_id: 301, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // SALON -> OBJEDNAVKA BONUSU NA SKLAD
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'bonus_booking');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.sc_bonus_booking, icon: 'B', typ: 2, exec_id: 109, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // SALON -> PRODUKT INFO
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'product_info');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.products, icon: lang.products[0], typ: 2, exec_id: 303, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // SALON -> SERVICES INFO
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'service_info');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.services, icon: lang.services[0], typ: 2, exec_id: 403, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // SALON -> NEWSLETTER
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'info_newsletter');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.sc_info_newsletter, icon: faInfo, typ: 0, exec_id: 601, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // SALON -> CENNÍK
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'info_cennik');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.sc_info_cennik, icon: faDollarSign, typ: 0, exec_id: 602, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // SALON -> AKCIA
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'info_action');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.sc_info_action, icon: faInfoCircle, typ: 0, exec_id: 603, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // SALON -> WEB SETTINGS
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'web_settings');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.sc_page_settings, icon: faGlobe, typ: 0, exec_id: 510, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // SALON -> WEB PREVIEW
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'web_preview');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.sc_page_web, icon: faEye, typ: 0, exec_id: 501, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }

                // SALON -> POSLEDNA OTVORENA KARTA
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'last_client');
                if (filter != undefined) {
                    if (filter.value == true) {
                        if (client_ != false) {
                            var data = { id: 100, label: client_.name + ' ' + client_.surname, icon: faIdCard, typ: 3, exec_id: client_.id, color: 0, background: global.shortcut_background };
                            items.push(data);
                            n++;
                        }
                    }
                }

                // AGENT -> SKLADOVE STAVY
                /*
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'stock');
                if (filter != undefined) {
                    if (filter.value == true) {
                        items.push({ id: n, label: lang.sc_salon_stock, icon: faLayerGroup, typ: 0, exec_id: 5003, color: 0, background: global.shortcut_background });
                        n++;
                    }
                }
                */

                // AGENT -> POSLEDNY OTVORENY SALON
                var filter = settings_.desktop_settings.shortcuts.find(x => x.tid == 'last_salon');
                if (filter != undefined) {
                    if (filter.value == true) {
                        if (client_ != false) {
                            items.push({ id: n, label: client_.name + ' ' + client_.surname, icon: faShop, typ: 4, exec_id: client_.id, color: 0, background: global.shortcut_background });
                            n++;
                        }
                    }
                }

            }
        }
        setShortcuts(items);
    }

    const SettingsClose = () => {
        var login = LoadLogin();
        db_token(login.token, false);
        setBodyID(0);
    }

    const ClientsClose = () => {
        var login = LoadLogin();
        db_token(login.token, false);
        setBodyID(0);
    }

    const SalonsClose = () => {
        var login = LoadLogin();
        db_token(login.token, false);
        setBodyID(0);
    }

    const RegisterResult = (value) => {
        if (value == true) {
            // REGISTRACIA OK
            setShowRegister(false);
            var login = LoadLogin();
            db_token(login.token, true);
        } else {
            // ODHLASENIE SA
            ResetLogin();
            navigate('/logout');
        }
    }

    const DialogGDPRResult = (value) => {
        setShowGDPR(false);
        if (value == false) {
            // NESUHLASI - odhlasenie a späť na login
            ResetLogin();
            navigate('/logout');
        } else {
            // SUHLASI
        }
    }


    const DialogDesktopSettingsResult = (typ, data) => {
        setShowDesktopSettings(false);
        if (typ == true) {
            // ZMENA NASTAVENI            
            settings.desktop_settings = data.desktop_settings;
            CreateShortcuts(user.typ, lang, settings, client, user);
        }
    }

    const PinResult = (typ, code) => {
        setShowPin(false);

        if (typ == true) {
            if (code == pin) {
                // PIN OK
                setPinVerified(true);
                setBodyID(bodyIDUnlock);
            } else {
                // NESPRAVNY PIN
                setPinVerified(false);
                setBodyID(0);
            }
        } else {
            setBodyID(0);
        }
    }

    const DesktopPress = (typ, value) => {
        if (typ == 1) {
            // OBJEDNAVKY Z ESHOPU
            if (value == true) {
                setBodyID(104);
            }
        }
        if (typ == 2) {
            // NARODENINY      
            setClientID(value.id);
            setShowCard(true);
        }
        if (typ == 3) {
            // OBJEDNAVKY PRODUKTOV NA SKLAD - AGENT
            if (value == true) {
                setBodyID(5101);
            }
        }
        if (typ == 4) {
            // OBJEDNAVKY BONUSU - AGENT
            if (value == true) {
                setBodyID(5102);
            }
        }
        if (typ == 5) {
            // REKLAMACIE PRODUKTOV A BONUSU - AGENT
            if (value == true) {
                setBodyID(5103);
            }
        }
    }


    const SalonCardClose = (typ) => {
        if (typ == 0) {
            setShowSalonCard(false);
        }
    }


    const ClientCardClose = () => {
        setShowCard(false);
        ClientsClose();
    }


    const HelpSelectResult = (typ) => {
        setShowHelpSelect(false);

        if (typ == 1) {
            // POMOCNIK
            setShowHelp(true);
        }
        if (typ == 2) {
            // KONTAKTUJTE NAS
            setShowContactUs(true);
        }
        if (typ == 3) {
            // VIDEO NAVOD
            setShowVideoHelp(true);
        }
    }


    return (
        <div style={{ ...styles.Block, height: height, alignItems: 'flex-start', justifyContent: 'flex-start', fontFamily: 'roboto', msOverflowStyle: 'none', scrollbarWidth: 'none', backgroundImage: `url(${global.themes[theme].background_image})`, backgroundSize: 'cover' }} className='container'>
            <div style={{ ...styles.BlockLeft, height: height }}>

                {/* MENU - VRCHNA LISTA */}
                <MenuTop masterclass={user.masterclass == false ? false : true} theme={theme} user={user} admin={admin} countries={countries} country={country} menuID={menuID} pinEnabled={pinEnabled} pin={pin} version={version} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} label={isSmall ? menuLabel : label} body_id={bodyID} menuData={menuData} offset={offset} radius={radius} isSmall={isSmall} token={token} bodyID={bodyID} lang={lang} screenWidth={width} width={width} height={height} logged={logged} func={MenuTopPress.bind(this)} />

                <div style={{ ...styles.BlockRow, height: height - global.topmenu_height }}>
                    {isSmall == false ?
                        (bodyID == 0) || (bodyID > 0 && width > global.max_screen) ?
                            <div style={{ ...styles.BlockCenter, alignItems: 'center', width: global.menu_width + (2 * offset), height: height - global.topmenu_height }}>

                                {/* MENU - TITLE */}
                                <MenuTitle masterclass={user.masterclass == false ? false : true} appFocus={appFocus} theme={theme} menuID={menuID} admin={admin} pinEnabled={pinEnabled} pin={pin} remoteApp={remoteApp} appVersion={appVersion} user={user} employees={employees} employeeSelected={employeeSelected} name={user.name == undefined ? '' : user.name + ' ' + user.surname} salon_name={user.typ == global.account_salon ? user.salon_name : ''} offset={offset} radius={radius} language={language} isSmall={isSmall} body_id={bodyID} token={token} lang={lang} height={global.menutitle_height} func={MenuTitlePress.bind(this)} />

                                {/* MAIN MENU */}
                                <Menu masterclass={user.masterclass == false ? false : true} theme={theme} menuID={menuID} admin={admin} pinEnabled={pinEnabled} pin={pin} version={version} remoteApp={remoteApp} appVersion={appVersion} user={user} employees={employees} label={menuLabel} offset={offset} radius={radius} isSmall={isSmall} body_id={bodyID} token={token} lang={lang} language={language} width={width} menuData={menuData} height={height - global.topmenu_height - global.menutitle_height} func={MenuPress.bind(this)} />

                            </div>
                            : null
                        :
                        null
                    }

                    <div style={{ ...styles.Block, width: isSmall ? '100%' : (bodyID == 0) || (bodyID > 0 && width > global.max_screen) ? width - global.menu_width - (2 * offset) : width, height: height - global.topmenu_height }}>

                        <div style={{ ...styles.BlockLeft, height: height - global.statusbar_height - 0, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                            {logged == true ?
                                bodyID == 0 ?
                                    <div style={{ ...styles.Block, alignItems: width > global.max_screen ? 'flex-start' : 'flex-start' /*, height: height - global.statusbar_height - 0*/, paddingTop: isSmall ? global.smallOffset : offset }}>
                                        {/* SKRATKY */}
                                        <Shortcuts expireNotify={expireNotify} chatNotify={chatNotify} productsNotify={productsNotify} bonusNotify={bonusNotify} reklamacieNotify={reklamacieNotify} comNotify={comNotify} theme={theme} settings={settings} remoteApp={remoteApp} appVersion={appVersion} employee_id={employeeID} user={user} admin={admin} items={shortcuts} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} screenWidth={width} width={isSmall ? width : width - global.menu_width - (3 * offset)} height={global.menutitle_height} token={token} func={ShortcutPress.bind(this)} />

                                        {/* DESKTOP */}
                                        <Desktop theme={theme} languageManual={languageManual} settings={settings} remoteApp={remoteApp} appVersion={appVersion} banner={banner} employee_id={employeeID} employees={employees} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} isMedium={isMedium} title={''} sub_title={''} screenWidth={width} width={isSmall ? width : width - global.menu_width - (3 * offset)} height={height - global.topmenu_height - global.menutitle_height - offset} token={token} func={DesktopPress.bind(this)} />
                                    </div>
                                    :
                                    <div style={{ ...styles.Block, alignItems: width > global.max_screen ? 'flex-start' : 'center', paddingTop: offset }}>

                                        {/*
                                        *
                                        * =================================================================================================================
                                        * OBCHOD
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* Predaj produktov - Predajňa */}
                                        {bodyID == 101 ?
                                            <ProductsShop theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.products_shop} sub_title={lang.shop} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {bodyID == 102 ?
                                            <ProductsShopHistory theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.products_shop_history} sub_title={lang.shop} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}

                                        {bodyID == 104 ?
                                            <EshopHistory theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.eshop_history} sub_title={lang.shop} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}

                                        {bodyID == 106 ?
                                            <ProductsBooking theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.booking_label} sub_title={lang.booking_stock} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {bodyID == 107 ?
                                            <ProductsBookingHistory theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.booking_history_label} sub_title={lang.booking_stock} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}

                                        {bodyID == 109 ?
                                            <BonusBooking theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.bonus_booking} sub_title={lang.booking_stock} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {bodyID == 110 ?
                                            <BonusBookingHistory theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.bonus_booking} sub_title={lang.booking_stock} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {bodyID == 112 ?
                                            <ReklamacieSalon theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.reklamacie_products_bonus} sub_title={lang.salons} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {bodyID == 115 ?
                                            <Expirations theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.expirations} sub_title={lang.salons} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {bodyID == 114 ?
                                            <Faktury theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.faktury_list} sub_title={lang.salons} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {/*
                                        *
                                        * =================================================================================================================
                                        * ZAKAZNICI
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* ZOZNAM ZAKAZNIKOV */}
                                        {bodyID == 9020 ?
                                            <Clients theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.clients_list} sub_title={lang.client_cards} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {/*

                                        {/*
                                        *
                                        * =================================================================================================================
                                        * DISKUSIA
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* CHAT - DISKUSIA */}
                                        {bodyID == 9021 ?
                                            <Chat theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.chat} sub_title={''} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {/*

                                        {/* CHAT - KOMUNIKACIA - SALONS */}
                                        {bodyID == 9023 ?
                                            <ChatAgents theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.chat} sub_title={''} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {/*
                                        {/* CHAT - KOMUNIKACIA - AGENTS */}
                                        {bodyID == 9024 ?
                                            <ChatAgentsRep theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.chat} sub_title={''} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}

                                        {/*
                                        *
                                        * =================================================================================================================
                                        * KALENDAR
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* KALENDAR */}
                                        {bodyID == 9022 ?
                                            <Calendar theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.calendar} sub_title={''} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}

                                        {/*
                                        *
                                        * =================================================================================================================
                                        * ŠTATISTIKY
                                        * =================================================================================================================
                                        *
                                        */}
                                        {bodyID == 201 ?
                                            <StatsToday theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.stats_today} sub_title={lang.stats_today_text} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {bodyID == 203 ?
                                            <StatsInfo theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.stats_info} sub_title={lang.stats_info_text} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {bodyID == 204 ?
                                            <StatsProductsSalon theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.products} sub_title={lang.stats_products_text} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {bodyID == 205 ?
                                            <StatsServices theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.services} sub_title={lang.stats_services} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {bodyID == 207 ?
                                            <StatsProductsEshop theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.products} sub_title={lang.stats_products_eshop_} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {bodyID == 209 ?
                                            <StatsProductsInfo theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.products} sub_title={lang.stats_product_label} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {bodyID == 210 ?
                                            <StatsClients theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.stats_clients_label} sub_title={lang.stats_clients_text} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {bodyID == 211 ?
                                            <StatsEmployees theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.employees} sub_title={lang.stats_employees} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {bodyID == 212 ?
                                            <StatsProductsBooking theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.products} sub_title={lang.stats_booking_text} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {/*
                                        *
                                        * =================================================================================================================
                                        * PRODUKTY
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* INFORMACIE O PRODUKTOCH */}
                                        {bodyID == 303 ?
                                            <ProductsInfo theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={typ == 2 ? language : country.language_id} offset={offset} radius={radius} isSmall={isSmall} title={lang.products_list} sub_title={lang.info} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => setBodyID(0)} />
                                            : null}

                                        {/* NASTAVENIE PRODUKTOV */}
                                        {bodyID == 301 ?
                                            <Products theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} pinEnabled={pinEnabled} pin={pin} title={lang.products_list} sub_title={lang.products} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => setBodyID(0)} />
                                            : null}

                                        {/* KATEGORIE */}
                                        {bodyID == 302 ?
                                            <Categories theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.categories} sub_title={lang.products} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => setBodyID(0)} />
                                            : null}

                                        {/* INFORMACIE O BONUSOCH */}
                                        {bodyID == 304 ?
                                            <BonusInfo theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={typ == 2 ? language : country.language_id} offset={offset} radius={radius} isSmall={isSmall} title={lang.bonus} sub_title={lang.info} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => setBodyID(0)} />
                                            : null}

                                        {/*
                                        *
                                        * =================================================================================================================
                                        * SLUZBY
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* INFORMACIE O SLUZBACH */}
                                        {bodyID == 403 ?
                                            <ServicesInfo theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={typ == 2 ? language : country.language_id} offset={offset} radius={radius} isSmall={isSmall} title={lang.services_list} sub_title={lang.info} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}

                                        {/* NASTAVENIE SLUZIEB */}
                                        {bodyID == 401 ?
                                            <Services theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} pinEnabled={pinEnabled} pin={pin} title={lang.services_list} sub_title={lang.services} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}

                                        {/* SKUPINY */}
                                        {bodyID == 402 ?
                                            <Groups theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.groups} sub_title={lang.services} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}

                                        {/*
                                        *
                                        * =================================================================================================================
                                        * INFORMACIE
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* INFORMACIE - NEWSLETTER */}
                                        {bodyID == 601 ?
                                            <Informations theme={theme} remoteApp={remoteApp} appVersion={appVersion} typ={0} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={typ == 2 ? language : country.language_id} offset={offset} radius={radius} isSmall={isSmall} title={lang.info_newsletter} sub_title={lang.info} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {/* INFORMACIE - CENNIK */}
                                        {bodyID == 602 ?
                                            <Informations theme={theme} remoteApp={remoteApp} appVersion={appVersion} typ={1} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={typ == 2 ? language : country.language_id} offset={offset} radius={radius} isSmall={isSmall} title={lang.info_cennik} sub_title={lang.info} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {/* INFORMACIE - AKCIA */}
                                        {/* bodyID == 603 ?
                                            <Informations theme={theme} remoteApp={remoteApp} appVersion={appVersion}  typ={2} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={typ == 2 ? language : country.language_id} offset={offset} radius={radius} isSmall={isSmall} title={lang.info_action} sub_title={lang.info} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                        : null */}
                                        {/* MASTERCLASS */}
                                        {bodyID == 605 ?
                                            <Informations theme={theme} remoteApp={remoteApp} appVersion={appVersion} typ={3} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={typ == 2 ? language : country.language_id} offset={offset} radius={radius} isSmall={isSmall} title={lang.info_action} sub_title={lang.info} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {/* APLIKACIA PRE IPAD */}
                                        {bodyID == 604 ?
                                            <Ipad theme={theme} remoteApp={remoteApp} appVersion={appVersion} typ={2} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={typ == 2 ? language : country.language_id} offset={offset} radius={radius} isSmall={isSmall} title={lang.vision_system} sub_title={lang.application} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {/*
                                        *
                                        * =================================================================================================================
                                        * WEB PAGE - webová stránka a e-shop
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* INFORMACIE O ESHOPE A STRANKE */}
                                        {bodyID == 500 ?
                                            <WebInfo theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.web_page_preview} sub_title={lang.web_page} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {/* NAHLAD WEBOVEJ STRANKY */}
                                        {bodyID == 501 ?
                                            <WebPage theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.web_page_preview} sub_title={lang.web_page} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {/* NASTAVENIE WEBOVEJ STRANKY */}
                                        {bodyID == 510 ?
                                            <WebSettings theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.web_page_settings} sub_title={lang.web_page} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}

                                        {/*
                                        *
                                        * =================================================================================================================
                                        * ŠKOLENIA - SALON
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* SKOLENIA - zoznam planovaných */}
                                        {bodyID == 800 ?
                                            <Lessons theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.lessons_planned} sub_title={lang.lessons} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {/* SKOLENIA - zoznam absolvovaných */}
                                        {bodyID == 801 ?
                                            <LessonsAbsolved theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.lessons_finished} sub_title={lang.lessons} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {/* SKOLENIA - zoznam absolvovaných */}
                                        {bodyID == 802 ?
                                            <LessonsAgent masterclass={true} countries={countries} country={country} theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.lesson_update} sub_title={lang.lessons} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}
                                        {/* SKOLENIA - video z webinárov */}
                                        {bodyID == 803 ?
                                            <LessonsVideo theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.video_webinar} sub_title={lang.lessons} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}

                                        {/*
                                        *
                                        * =================================================================================================================
                                        * REST + NASTAVENIE APKY
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* TESTOVANIE SCRIPTOV */}
                                        {bodyID == 9999 ?
                                            <TestConditions theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={'Testovanie'} sub_title={'testovacie scripty'} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => setBodyID(0)} />
                                            : null}

                                        {/* NASTAVENIA */}
                                        {bodyID == 9901 ?
                                            (pinEnabled == true && pinVerified == true) || (pinEnabled == false) ?
                                                <Settings theme={theme} version={version} remoteApp={remoteApp} appVersion={appVersion} user={user} admin={admin} lang={lang} language={language} employee_id={employeeID} offset={offset} radius={radius} isSmall={isSmall} title={lang.settings} sub_title={lang.vision_system} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} countries={countries} func={SettingsClose.bind(this)} />
                                                : null
                                            : null}

                                        {/*
                                        *
                                        * =================================================================================================================
                                        * AGENT - SALONY
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* ZOZNAM SALONOV */}
                                        {bodyID == 5001 ?
                                            <Salons theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.salons_list} sub_title={lang.salons} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}

                                        {/*
                                        *
                                        * =================================================================================================================
                                        * AGENT - OBJEDNAVKY PRODUKTOV / BONUSU / REKLAMACIE
                                        * =================================================================================================================
                                        *
                                        */}
                                        {bodyID == 5101 ?
                                            <ProductsBookingAgent theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.booking_products} sub_title={lang.salons} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}
                                        {bodyID == 5102 ?
                                            <BonusBookingAgent theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.bonus_bookings} sub_title={lang.salons} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}
                                        {bodyID == 5103 ?
                                            <Reklamacie theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.reklamacie_products_bonus} sub_title={lang.salons} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}
                                        {bodyID == 5104 ?
                                            <ExpirationsAgent theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.expirations} sub_title={lang.salons} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => ClientsClose()} />
                                            : null}

                                        {/*
                                        *
                                        * =================================================================================================================
                                        * AGENT / GM - ŠTATISTIKY
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* PRODUKTY PREDANE V SALONE - AGENT */}
                                        {bodyID == 5203 ?
                                            <StatsInfoSalonAgent theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.info} sub_title={lang.stats_info_texts} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}
                                        {/* PRODUKTY PREDANE V SALONE - AGENT */}
                                        {bodyID == 5204 ?
                                            <StatsProductsSalonAgent theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.salon_stat_products_salon} sub_title={lang.salon_stat_products_salon_label} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}
                                        {/* PRODUKTY PREDANE CEZ E-SHOP - AGENT */}
                                        {bodyID == 5207 ?
                                            <StatsProductsEshopAgent theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.salon_stat_products_eshop} sub_title={lang.salon_stat_products_eshop_label} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}
                                        {/* SLUZBY - AGENT */}
                                        {bodyID == 5205 ?
                                            <StatsServicesAgent theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.salon_stat_services} sub_title={lang.salon_stat_services_label} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}
                                        {/* JEDNOTLIVE PRODUKTY */}
                                        {bodyID == 5209 ?
                                            <ProductsStatsAgent theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.products} sub_title={lang.stats_product_label} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}
                                        {/* ZOZNAM SALONOV - POCET KARIET, PREDAJE */}
                                        {bodyID == 5211 ?
                                            <StatsSalonsCards theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.salons} sub_title={lang.stats_salons} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}
                                        {/* ZOZNAM OSETRENI JEDNOTLIVYCH SALONOV */}
                                        {bodyID == 5212 ?
                                            <StatsSalonsServices theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.services_} sub_title={lang.stats_services_label} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}
                                        {/* PODLA OBCHODNYCH ZASTUPCOV */}
                                        {bodyID == 5213 ?
                                            <StatsSalonsReps theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.reps} sub_title={lang.stats_salons} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}
                                        {/* SKOLENIA */}
                                        {bodyID == 5214 ?
                                            <StatsLessonsAgent theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.lessons_stats} sub_title={lang.lessons_stats_text} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}
                                        {bodyID == 5215 ?
                                            <StatsLessonsAgentProducts theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.lessons_stats} sub_title={lang.lessons_stats_text} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}
                                        {/*
                                        *
                                        * =================================================================================================================
                                        * AKTUALITY
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* AKTUALITY */}
                                        {bodyID == 5701 ?
                                            <NewsAgent theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.news} sub_title={lang.news_title} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}

                                        {/*
                                        *
                                        * =================================================================================================================
                                        * ŠKOLENIA
                                        * =================================================================================================================
                                        *
                                        */}
                                        {/* PRODUKTY PREDANE V SALONE - AGENT */}
                                        {bodyID == 701 ?
                                            <LessonsAgent theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.lessons} sub_title={lang.lessons_list} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}
                                        {/* KATEGORIE */}
                                        {bodyID == 702 ?
                                            <LessonsCategories theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.categories} sub_title={lang.lessons} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={() => SalonsClose()} />
                                            : null}
                                    </div>
                                :
                                null
                            }
                        </div>
                    </div>

                </div>

            </div>

            {/* KARTA ZAKAZNIKA - POSLEDNA OTVORENA */}
            {showCard == true ?
                <ClientCard isSmall={isSmall} theme={theme} remoteApp={remoteApp} appVersion={appVersion} employees={employees} employee_id={employeeID} user_id={user.id} user={user} admin={admin} client_id={clientID} lang={lang} language={language} radius={radius} offset={offset} token={token} func={() => ClientCardClose()} />
                : null}

            {/* KARTA SALONU - POSLEDNA OTVORENA */}
            {showSalonCard == true ?
                <SalonCard editing={true} theme={theme} salon_id={clientID} remoteApp={remoteApp} appVersion={appVersion} user={user} admin={admin} countries={countries} country={country} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.salons_list} sub_title={lang.salons} func={SalonCardClose.bind(this)} />
                : null}

            {/* SPRIEVODCA REGISTRACIOU */}
            {showRegister == true ?
                <RegisterSalon theme={theme} remoteApp={remoteApp} appVersion={appVersion} user={user} admin={admin} lang={lang} language={language} offset={offset} radius={radius} isSmall={isSmall} title={lang.register_form} sub_title={lang.vision_system} screenWidth={width} width={width > global.max_screen ? width - global.menu_width - (3 * offset) : width - (2 * offset)} height={height - global.topmenu_height - (2 * offset)} token={token} func={RegisterResult.bind(this)} />
                : null}

            {/* SUHLAS S GDPR */}
            {showGDPR == true ?
                <DialogGDPR isSmall={isSmall} theme={theme} remoteApp={remoteApp} appVersion={appVersion} user={user} admin={admin} lang={lang} radius={radius} token={token} func={DialogGDPRResult.bind(this)} />
                : null}

            {/* DESKTOP SETTINGS */}
            {showDesktopSettings == true ?
                <DialogDesktopSettings isSmall={isSmall} theme={theme} remoteApp={remoteApp} appVersion={appVersion} user={user} admin={admin} lang={lang} radius={radius} token={token} func={DialogDesktopSettingsResult.bind(this)} />
                : null}

            {/* HELP -> VYBER POMOCNIK / KONTAKTUJTE NAS */}
            {showHelpSelect == true ?
                <DialogHelpSelect isSmall={isSmall} theme={theme} user={user} admin={admin} remoteApp={remoteApp} appVersion={appVersion} radius={radius} language={language} lang={lang} title={lang.help} sub_title={lang.vision_system} background={global.theme_dark_gray} offset={offset} func={HelpSelectResult.bind(this)} />
                : null}

            {/* HELP -> KONTAKTUJTE NAS */}
            {showContactUs == true ?
                <DialogContactUs isSmall={isSmall} theme={theme} user={user} admin={admin} remoteApp={remoteApp} appVersion={appVersion} radius={radius} language={language} lang={lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} offset={offset} func={() => setShowContactUs(false)} />
                : null}

            {/* HELP -> POMOCNÍK */}
            {showHelp == true ?
                <DialogHelp isSmall={isSmall} theme={theme} user={user} admin={admin} remoteApp={remoteApp} appVersion={appVersion} radius={radius} language={language} lang={lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} offset={offset} func={() => setShowHelp(false)} />
                : null}

            {/* HELP -> VIDEO YOUTUBE */}
            {showVideoHelp == true ?
                <DialogHelpVideo isSmall={isSmall} theme={theme} user={user} admin={admin} remoteApp={remoteApp} appVersion={appVersion} radius={radius} language={language} lang={lang} title={lang.preview} sub_title={lang.vision_system} background={global.theme_dark_gray} offset={offset} func={() => setShowVideoHelp(false)} />
                : null}

            {/* OCHRANA PIN KODOM */}
            {showPin == true ?
                <PinCode theme={theme} type={0} pin={pin} alert={true} lang={lang} radius={radius} title={lang.settings_pin_enter_label} token={token} func={PinResult.bind(this)} />
                : null}

            {showError == true ?
                <ShowError theme={theme} text={lang.app_error} func={() => setShowError(false)} />
                : null}

            {isBusy == true ?
                <Loading theme={theme} checkout screenWidth={width} offset={offset} lang={lang} center={true} func={() => setBusy(false)} />
                : null}

            {/* VYPADOK INTERNETOVEHO PRIPOJENIA */}
            {isOnline == false ?
                <ShowError theme={theme} text={lang.internet_error} sub_text={lang.internet_error_text} icon={faWifi} func={() => setShowError(false)} />
                : null}

            {/* FADE BLACK -> ZMENA TEMY */}
            <Backdrop open={showBlack} transitionDuration={500} style={{ zIndex: 20000, width: width, height: height, backgroundColor: global.theme_black }}>
                <div style={{ ...styles.Block, width: width, height: height }}>
                    <img src={logo} style={{ width: '100%', height: '100%', maxWidth: 300, maxHeight: 120, objectFit: 'contain' }}></img>
                    <div style={{ ...styles.Block, height: 50 }}>
                        <CircularProgress style={{ color: global.theme_white }} thickness={2} size={20} />
                    </div>
                </div>
            </Backdrop>

        </div >
    );
}


