/*
*
* =================================================================================================================
* KARTA KOZMETICKEHO SALONA
* =================================================================================================================
*
*/

import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useNavigate, Navigate, useLocation, createRoutesFromChildren } from "react-router-dom";
import { cz, sk } from './language.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faXmark, faCircle, faBackward, faHome, faUser, faSearch, faRightFromBracket, faImage, faChevronRight, faChevronUp, faSquare, faBars, faTriangleExclamation, faInfoCircle, faTrash, faEdit, faAdd, faMessage, faQuestionCircle, faLayerGroup, faTag, faLocationDot, faBell, faCog, faList, faPlusCircle, faPlus, faCogs, faAngleLeft, faArrowLeft, faEye, faCheckCircle, faWarning, faPhoneAlt, faMobileAlt, faNoteSticky, faPenClip, faPen, faPhotoFilm, faChartBar, faHeart, faStar, faCashRegister, faInfo, faMagnifyingGlass, faQuestion, faSave, faBook, faEnvelope, faMobile, faRotateLeft, faMobilePhone, faPhone, faAppleAlt, faMobileAndroid, faMobileAndroidAlt, faExclamationTriangle, faCalendarDays, faPaperPlane, faXmarkCircle, faShoppingBasket, faShoppingCart, faCreditCard, faShare, faR, faB, faP, faBookOpen, faGlobe, faE } from '@fortawesome/free-solid-svg-icons'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Checkbox, Chip, Drawer, FormControlLabel, InputAdornment, Pagination, Slide, Tooltip } from '@mui/material';
import { Button, IconButton, MenuItem, Select, TextField, Paper, Tabs, Tab } from '@mui/material';
import { styles } from './styles.js';
import './items.css';
import { ConvertToPrice, CopyData, Debug, DialogAgreements, DialogEmail, DialogImagePreview, DialogInfo, DialogNotify, DialogYesNo, EmptyList, FormatMoney, GetAge, GetCountry, GetDPH, GetDate, GetDatum, GetDatum2, GetHoursFromMinutes, GetImageLink, GetLanguage, GetMoney, GetPrice, GetTimeHM, GetTodayMonth, GetTodayYear, GetTodayYearMonth, GetVoucher, GetVoucherPrice, Loading, SelectYear, ShowOK, TextInput, TextLine, UniqueID, Voucher } from './items.js';
import { DialogClient } from './clients_dialogs.js';
import { Photos } from './photos.js';
import { AddServiceNote, DialogServiceNote, ServiceSelect } from './service_select.js';
import { AddProductNote, ProductSelect } from './product_select.js';
import { Graph } from './graph.js';
import QRCode from "react-qr-code";
import { BonusBookingDetailsAgent } from './bonus_booking_agent.js';
import { ProductsStock } from './products_stock.js';
import { StatsSalons } from './stats_salons.js';
import { StatsInfoSalons } from './stats_info_salons.js';
import { ProductBookingDetailsAgent } from './products_booking_agent.js';
import { ReklamaciaDetailsAgent } from './reklamacie.js';
import { LessonDetails } from './lessons.js';
import { CreditStatus } from './bonus_booking.js';
import { Expiration } from './expirations.js';

export const SalonCard = (props) => {
    /*
    */

    const no_image = require('./react/app/no_image.jpg');
    const client_card = require('./react/app/client_card.png');

    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [index, setIndex] = useState(-1);
    const [subIndex, setSubIndex] = useState(-1);
    const [actualYear, setActualYear] = useState(GetTodayYear());
    const [dph, setDPH] = useState(1);

    // LIST
    const [data, setData] = useState(false);
    const [items, setItems] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [filters, setFilters] = useState({ year: actualYear, month: 12 });
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const [money, setMoney] = useState(props.lang.money);
    const [costAll, setCostAll] = useState(0);

    // GRAF
    const [graphData, setGraphData] = useState(false);
    const [label, setLabel] = useState('');
    const [cost, setCost] = useState('');
    const [quantity, setQuantity] = useState(0);

    // ZMENA AGENTA
    const [agentID, setAgentID] = useState(0);

    // BONUSOVA OBJEDNAVKA
    const [showBonusOrder, setShowBonusOrder] = useState(false);

    // OK
    const [showOK, setShowOK] = useState(false);

    // EXPORT KARTY
    const [showExport, setShowExport] = useState(false);

    // DETAIL OBJEDNAVOK
    const [showOrderProducts, setShowOrderProducts] = useState(false);
    const [showOrderBonus, setShowOrderBonus] = useState(false);

    // DETAIL REKLAMACIE
    const [showReklamacia, setShowReklamacia] = useState(false);

    // DETAIL EXPIRACIE
    const [showExpire, setShowExpire] = useState(false);

    // DETAIL SKOLENIA
    const [showLesson, setShowLesson] = useState(false);

    // STAV KREDITU
    const [showStatus, setShowStatus] = useState(false);

    const itemsMax = 24;


    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const [radius, setRadius] = useState(height > 850 ? props.radius : 0);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const titleHeight = 160;
    const avatar_size = 130;
    const rowHeight = 80;
    const menuWidth = props.isSmall ? 0 : 220;
    const button_size = 100;
    const creditSize = 130;
    const agentSize = 50;
    const labelSize = 50;
    const graphSize = 200;
    const noteSize = 25;

    const selectHeight = props.isSmall ? 40 : 0;

    const avatar_man = require('./react/app/man_.png');
    const avatar_woman = require('./react/app/woman_.png');

    const color_theme = global.themes[props.theme];
    const color = global.themes[props.theme];

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.themes[props.theme].theme_darker;

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            db_get();

        }

        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_agent + 'salon', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    salon_id: props.salon_id,
                    save_card: true
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                if (json.item != false) {
                    setMoney(GetMoney(json.item.language));
                    setDPH(GetDPH(json.item.language));

                    setData(json.item);
                    setAgentID(json.item.agent_id);
                }
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_get_data = async (typ, filter) => {
        setLoading(true);

        setItems(false);
        setItemsFiltered(false);
        setGraphData(false);

        try {
            const response = await fetch(
                global.db_url_agent + 'salon_data', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    salon_id: props.salon_id,
                    admin: props.admin,
                    typ: typ,
                    filter: filter,
                    dph: dph
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setGraphData(json.graph_data);

                if (typ == 3) {
                    var sum = 0;
                    json.graph_data.forEach(item => {
                        sum += parseFloat(item.value);
                    });
                    setCostAll(sum);
                }
                if (tmp != false) {
                    PageInit(tmp);
                }

            }
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }



    const db_update = async (data_, item) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url_agent + 'salon_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data_
                })
            })

            const json = await response.json();
            setLoading(false);

            if (json.ok > 0) {
                data.agent = item.name + ' ' + item.surname
                data.agent_id = item.id;
                Press(1, data);

                setShowOK(true);
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const db_update_register = async (element) => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url_agent + 'salon_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: element
                })
            })

            const json = await response.json();
            setLoading(false);

            if (json.ok > 0) {
                setShowOK(true);
                Press(2, data);
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }


    const db_export = async () => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url_agent + 'salon_export', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    salon_id: props.salon_id,
                    admin: props.admin,
                    language: props.country.language_id,
                    email: props.user.email,
                    lang: {
                        do_not_reply: lang.do_not_reply,
                        do_not_reply_text: lang.do_not_reply_text,

                        label: lang.salon,
                        created: lang.created,
                        email: lang.email_,
                        mobil: lang.mobil.toLowerCase(),
                        firm: lang.settings_company,
                        ico: lang.ico,
                        dic: lang.dic,
                        ic_dph: lang.ic_dph,
                        rep: lang.rep,
                        credit: lang.bonus_credit,
                        clients_count: lang.info_clients_count,

                        email_subject: lang.salon,
                        email_label: lang.salon,
                        email_text: lang.salon_card_export,
                    }
                })
            })

            const json = await response.json();
            setLoading(false);

            if (json.ok > 0) {
                setShowOK(true);
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }


    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(false, data);
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_orders-' + index);

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 300);
    }


    const Redraw = (items, p) => {
        // REDRAW PRODUKTOV
        var data = items;

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    const ChangeIndex = (id) => {
        setIndex(id);
        setSubIndex(-1);
        setActualYear(GetTodayYear());
        var filter = { year: GetTodayYear(), month: 12 };
        setFilters(filter);

        if (id != 0 && id != 1 && id != 2) {
            db_get_data(id, filter);
        }
    }

    const ChangeAgent = (item) => {
        setAgentID(item.id);

        let data_ = {
            id: data.id,
            agent_id: item.id
        }

        db_update(data_, item);
    }

    const BonusOrder = (item) => {
        if (parseInt(item.order_id) > 0) {
            setItemSelected(item);
            setShowBonusOrder(true);
        }
    }

    const ChangeYear = (year, month) => {
        setActualYear(year)

        let filter = { year: year, month: 12 };
        setFilters(filter);
        db_get_data(index, filter);
    }

    const SelectOrderProducts = (item) => {
        if (props.editing == true) {
            setItemSelected(item);
            setShowOrderProducts(true);
        }
    }

    const OrderProductsResult = (value, value2) => {
        setShowOrderProducts(false);

        if (value == true || value2 == true) {
            //let filter = { year: actualYear };
            db_get_data(index, filters);

        }
    }

    const ReklamaciaResult = (value, value2) => {
        setShowReklamacia(false);
        if (value == true || value2 == true) {
            db_get_data(index, filters);
        }
    }

    const SelectReklamacia = (item) => {
        if (props.editing == true) {
            setItemSelected(item);
            setShowReklamacia(true);
        }
    }

    const SelectExpire = (item) => {
        if (props.editing == true) {
            setItemSelected(item);
            setShowExpire(true);
        }
    }

    const SelectOrderBonus = (item) => {
        if (props.editing == true) {
            setItemSelected(item);
            setShowOrderBonus(true);
        }
    }

    const OrderBonusResult = (value, value2) => {
        setShowOrderBonus(false);
        if (value == true || value2 == true) {
            //let filter = { year: actualYear };
            db_get_data(index, filters);

        }
    }

    const ExportResult = (value) => {
        setShowExport(false);
        if (value == true) {
            db_export();
        }
    }

    const GraphResult = (id, value, value2) => {

        if (id == -2) {
            // ZMENA ROKA

        }
        if (id == -1) {
            // STLPEC - deselect
            var tmp = items;
            setItemsFiltered(tmp);
        }
        if (id >= 0) {
            // OZNACENY STLPEC
            if (index == 5) {
                var tmp = items.filter(x => x.updated.includes(value2.date));
                setItemsFiltered(tmp);
            } else {
                var tmp = items.filter(x => x.date.includes(value2.date));
                setItemsFiltered(tmp);
            }
        }
    }

    const LessonSelect = (item) => {
        setItemSelected(item);
        setShowLesson(true);
    }


    const ChangeRegister = (typ) => {
        if (props.admin == true) {
            data.salon1_id = typ;
            let element = {
                id: data.id,
                salon1_id: typ
            }

            db_update_register(element);
        }
    }

    const ExpirationResult = (redraw) => {
        setShowExpire(false);
        if (redraw == true) {
            db_get_data(index, filters);
        }
    }

    const SalonData = () => {
        return (
            <div style={{ ...styles.BlockRow, width: '90%', marginTop: 20, paddingBottom: 20, borderBottom: '1px solid ' + global.theme_light_gray }}>
                <div style={{ ...styles.BlockLeft, width: '50%', justifyContent: 'flex-start' }}>
                    <div style={{ ...styles.BlockLeft, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextTiny, marginTop: 0, textAlign: 'left', fontWeight: '600' }}>{lang.settings_company}:</p>
                        <p style={{ ...styles.TextTiny, marginTop: 5, textAlign: 'left' }}>{data.company}</p>
                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{data.street} {data.street_number}</p>
                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{data.psc} {data.town}</p>
                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.ico}: {data.ico}</p>
                        {data.language == 0 ?
                            <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.dic}: {data.dic}</p>
                            : null}
                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.ic_dph}: {data.ic_dph}</p>
                    </div>
                </div>

                <div style={{ ...styles.BlockLeft, width: '50%', justifyContent: 'flex-start' }}>
                    <div style={{ ...styles.BlockLeft, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextTiny, marginTop: 0, textAlign: 'left', fontWeight: '600' }}>{lang.settings_salon_address_}:</p>
                        <p style={{ ...styles.TextTiny, marginTop: 5, textAlign: 'left' }}>{data.salon_name}</p>
                        <p style={{ ...styles.TextTiny, marginTop: 0 }}>{data.salon_street} {data.salon_street_number}</p>
                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{data.salon_psc} {data.salon_town}</p>
                    </div>
                </div>
            </div>
        );
    }
    // *********************************************************************************************************
    //
    // RENDER
    //
    // *********************************************************************************************************
    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: '#000000AA', zIndex: 1000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: radius }}>
                <div style={{ ...styles.BlockRow, height: titleHeight, borderTopLeftRadius: radius, borderTopRightRadius: radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ?
                        <div style={{ ...styles.BlockCenter, width: '90%', height: titleHeight, alignItems: 'flex-start', marginLeft: 10 }}>
                            <p style={{ ...styles.TextSmall, fontWeight: '600', marginTop: 10, textAlign: 'left' }}><span style={{ ...styles.TextTiny, fontWeight: '400' }}>{props.user.id == 1 ? data.id + ' ' : ''}</span>{data.company}</p>
                            <div style={{ ...styles.BlockLeft, justifyContent: 'center', marginTop: 5 }}>
                                <p style={{ ...styles.TextTiny, fontWeight: '400' }}>{data.name} {data.surname}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{data.street} {data.street_number}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{data.psc} {data.town}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.mobil}: {data.mobil}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.email_}: {data.email}</p>
                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.rep}: {data.agent}</p>
                            </div>
                        </div>
                        : null}

                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: titleHeight, height: titleHeight }}>
                            <div style={{ ...styles.Block, width: avatar_size, height: avatar_size, background: data.photo == '' || data.photo == null ? global.icon_wite_background : '#00000000', borderRadius: 10 }} >
                                {data.photo == '' || data.photo == null ?
                                    <img src={no_image} style={{ width: '100%', height: '100%', width: avatar_size, height: avatar_size, objectFit: 'contain', borderRadius: 10 }}></img>
                                    :
                                    <img src={GetImageLink(data.photo)} style={{ width: '100%', height: '100%', width: avatar_size, height: avatar_size, objectFit: 'contain', borderRadius: 10 }}></img>
                                }
                            </div>
                        </div>
                    }
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: '80%', height: titleHeight }}>
                            {isBusy == false ?
                                data != false ?
                                    <div style={{ ...styles.BlockRow, justifyContent: 'center', height: titleHeight }}>
                                        <div style={{ ...styles.BlockLeft, width: '85%', height: titleHeight }}>
                                            <p style={{ ...styles.TextLarge, fontWeight: '600', marginTop: 10, textAlign: 'left' }}><span style={{ ...styles.TextTiny, fontWeight: '400' }}>{props.user.id == 1 ? data.id + ' ' : ''}</span>{data.company}</p>

                                            <div style={{ ...styles.BlockLeft, justifyContent: 'center', marginTop: 15 }}>
                                                <p style={{ ...styles.TextTiny, fontWeight: '400' }}>{data.name} {data.surname}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.mobil}: {data.mobil}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 0 }}>{lang.email_}: {data.email}</p>
                                                <p style={{ ...styles.TextTiny, marginTop: 10 }}>{lang.rep_}: {data.agent}</p>
                                            </div>
                                        </div>

                                        <div onClick={() => ChangeIndex(5)} style={{ ...styles.Block, width: creditSize + 10, height: titleHeight, cursor: 'pointer' }}>
                                            <div style={{ ...styles.Block, width: creditSize, height: creditSize, backgroundColor: global.theme_white, borderRadius: 10 }}>
                                                <p style={{ ...styles.TextTiny }}>{lang.bonus_credit}</p>
                                                <p style={{ ...styles.TextSmall, marginTop: 5, fontWeight: '600' }}>{FormatMoney(parseFloat(data.bonus_credit) < 0 ? 0 : data.bonus_credit)}</p>
                                            </div>
                                        </div>
                                    </div>
                                    : null
                                : null}
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? 80 : 100, height: titleHeight, justifyContent: 'flex-start', alignItems: 'center' }}>
                        <div style={{ ...styles.BlockRowRaw }}>
                            {/*
                            <IconButton onClick={() => setShowExport(true)} style={{ width: 36, height: 36, backgroundColor: global.theme_white, marginTop: 15, marginRight: props.isSmall ? 20 : 36 }}>
                                <FontAwesomeIcon style={{ width: 16, color: color_theme.theme_darker }} icon={faShare} />
                            </IconButton>
                            */}

                            <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white, marginTop: 15 }}>
                                <FontAwesomeIcon style={{ width: 10, color: color_theme.theme_darker }} icon={faXmark} />
                            </IconButton>
                        </div>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight, backgroundColor: undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...props.isSmall ? styles.BlockCenter : styles.BlockRow, height: dialogHeight - titleHeight }}>

                        {props.isSmall ?
                            <div style={{ ...styles.Block, height: selectHeight }}>
                                <Select
                                    value={index}
                                    onChange={event => ChangeIndex(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: global.theme_white, width: '100%', height: selectHeight }}
                                    MenuProps={{
                                        style: { zIndex: 10000 }
                                    }}
                                >
                                    <MenuItem disabled value={-1}>{lang.menu_title}</MenuItem>
                                    <MenuItem value={0}>{lang.info}</MenuItem>
                                    <MenuItem value={7}>{lang.agent}</MenuItem>
                                    <MenuItem value={1}>{lang.sc_salon_stock}</MenuItem>
                                    <MenuItem value={2}>{lang.statistic}</MenuItem>
                                    <MenuItem value={3}>{lang.sc_salon_booking_products}</MenuItem>
                                    <MenuItem value={4}>{lang.sc_salon_booking_bonus}</MenuItem>
                                    <MenuItem value={8}>{lang.reklamacie}</MenuItem>
                                    <MenuItem value={11}>{lang.expirations_}</MenuItem>
                                    <MenuItem value={5}>{lang.bonus_credit}</MenuItem>
                                    <MenuItem value={9}>{lang.lessons}</MenuItem>
                                    {data.salon1_id == 0 ?
                                        <MenuItem value={10}>{lang.eshop}</MenuItem>
                                        : null}
                                </Select>
                            </div>
                            :
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: dialogHeight - titleHeight, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                                <div style={{ ...styles.BlockCenter, width: menuWidth, height: dialogHeight - titleHeight - button_size - 20 }}>
                                    <Button onClick={() => ChangeIndex(0)} style={{ ...styles.ButtonSettings, width: menuWidth - 5, justifyContent: 'flex-start', backgroundColor: index == 0 ? global.theme_back : '#00000000', color: index == 0 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faInfoCircle} />
                                        {lang.info}
                                    </Button>
                                    <Button onClick={() => ChangeIndex(7)} style={{ ...styles.ButtonSettings, width: menuWidth - 5, justifyContent: 'flex-start', backgroundColor: index == 7 ? global.theme_back : '#00000000', color: index == 7 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faEdit} />
                                        {lang.agent}
                                    </Button>
                                    <Button onClick={() => ChangeIndex(1)} style={{ ...styles.ButtonSettings, width: menuWidth - 5, justifyContent: 'flex-start', backgroundColor: index == 1 ? global.theme_back : '#00000000', color: index == 1 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faLayerGroup} />
                                        {lang.sc_salon_stock}
                                    </Button>
                                    <Button onClick={() => ChangeIndex(2)} style={{ ...styles.ButtonSettings, width: menuWidth - 5, justifyContent: 'flex-start', backgroundColor: index == 2 ? global.theme_back : '#00000000', color: index == 2 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faChartBar} />
                                        {lang.statistic}
                                    </Button>
                                    <Button onClick={() => ChangeIndex(3)} style={{ ...styles.ButtonSettings, width: menuWidth - 5, justifyContent: 'flex-start', backgroundColor: index == 3 ? global.theme_back : '#00000000', color: index == 3 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faP} />
                                        {lang.sc_salon_booking_products}
                                    </Button>
                                    <Button onClick={() => ChangeIndex(4)} style={{ ...styles.ButtonSettings, width: menuWidth - 5, justifyContent: 'flex-start', backgroundColor: index == 4 ? global.theme_back : '#00000000', color: index == 4 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faB} />
                                        {lang.sc_salon_booking_bonus}
                                    </Button>
                                    <Button onClick={() => ChangeIndex(8)} style={{ ...styles.ButtonSettings, width: menuWidth - 5, justifyContent: 'flex-start', backgroundColor: index == 8 ? global.theme_back : '#00000000', color: index == 8 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 18 }} icon={faR} />
                                        {lang.reklamacie}
                                    </Button>
                                    <Button onClick={() => ChangeIndex(11)} style={{ ...styles.ButtonSettings, width: menuWidth - 5, justifyContent: 'flex-start', backgroundColor: index == 11 ? global.theme_back : '#00000000', color: index == 11 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 18 }} icon={faE} />
                                        {lang.expirations_}
                                    </Button>
                                    <Button onClick={() => ChangeIndex(5)} style={{ ...styles.ButtonSettings, width: menuWidth - 5, justifyContent: 'flex-start', backgroundColor: index == 5 ? global.theme_back : '#00000000', color: index == 5 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faCreditCard} />
                                        {lang.bonus_credit}
                                    </Button>
                                    <Button onClick={() => ChangeIndex(9)} style={{ ...styles.ButtonSettings, width: menuWidth - 5, justifyContent: 'flex-start', backgroundColor: index == 9 ? global.theme_back : '#00000000', color: index == 9 ? font_selected : font_disabled }}>
                                        <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faBookOpen} />
                                        {lang.lessons}
                                    </Button>
                                    {data.salon1_id == 0 ?
                                        <Button onClick={() => ChangeIndex(10)} style={{ ...styles.ButtonSettings, width: menuWidth - 5, justifyContent: 'flex-start', backgroundColor: index == 10 ? global.theme_back : '#00000000', color: index == 10 ? font_selected : font_disabled }}>
                                            <FontAwesomeIcon style={{ height: 16, marginRight: 10 }} icon={faGlobe} />
                                            {lang.eshop}
                                        </Button>
                                        : null}
                                </div>
                                <div style={{ ...styles.Block, width: menuWidth, height: button_size + 20, }}>

                                </div>
                            </div>
                        }
                        <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - titleHeight - selectHeight, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, }}>
                            {index == -1 ?
                                /*
                                *
                                * =================================================================================================================
                                * EMPTY
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - titleHeight - selectHeight }}>
                                    <SalonData />

                                    <img src={client_card} style={{ width: '100%', height: '100%', maxWidth: 200, maxHeight: 200, objectFit: 'contain' }}></img>
                                </div>
                                : null}

                            {index == 0 ?
                                /*
                                *
                                * =================================================================================================================
                                * INFORMACIE
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - titleHeight - selectHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                                    <SalonData />

                                    <div style={{ ...styles.BlockCenter, height: labelSize + 80 }}>
                                        <div style={{ ...styles.Block, height: 80, borderBottom: '1px solid ' + global.theme_light_gray }}>
                                            {props.isSmall == true ?
                                                <div style={{ ...styles.Block }}>
                                                    <div style={{ ...styles.Block, width: 200, height: 30, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextSmall, marginTop: 2, fontWeight: '600' }}>{lang.register_typ}</p>
                                                    </div>
                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                        <div style={{ ...styles.Block, width: 200, height: 50 }}>
                                                            <Button onClick={() => ChangeRegister(0)} style={{ ...styles.ButtonDark, width: 170, backgroundColor: parseInt(data.salon1_id) == 0 ? color_theme.theme_dark : global.theme_light_gray, color: parseInt(data.salon1_id) == 0 ? global.theme_white : global.theme_medium_gray }}>{lang.salon}</Button>
                                                        </div>
                                                        <div style={{ ...styles.Block, width: 180, height: 50 }}>
                                                            <Button onClick={() => ChangeRegister(1)} style={{ ...styles.ButtonDark, backgroundColor: parseInt(data.salon1_id) == 1 ? color_theme.theme_dark : global.theme_light_gray, color: parseInt(data.salon1_id) == 1 ? global.theme_white : global.theme_medium_gray }}>{lang.typ_other}</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div style={{ ...styles.BlockRowRaw }}>
                                                    <div style={{ ...styles.BlockRight, width: 200, height: 80, justifyContent: 'center' }}>
                                                        <p style={{ ...styles.TextSmall, marginTop: 2, fontWeight: '600' }}>{lang.register_typ}</p>
                                                        <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.register_typ_note}</p>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 200, height: 80 }}>
                                                        <Button onClick={() => ChangeRegister(0)} style={{ ...styles.ButtonDark, width: 170, backgroundColor: parseInt(data.salon1_id) == 0 ? color_theme.theme_dark : global.theme_light_gray, color: parseInt(data.salon1_id) == 0 ? global.theme_white : global.theme_medium_gray }}>{lang.salon}</Button>
                                                    </div>
                                                    <div style={{ ...styles.Block, width: 180, height: 80 }}>
                                                        <Button onClick={() => ChangeRegister(1)} style={{ ...styles.ButtonDark, backgroundColor: parseInt(data.salon1_id) == 1 ? color_theme.theme_dark : global.theme_light_gray, color: parseInt(data.salon1_id) == 1 ? global.theme_white : global.theme_medium_gray }}>{lang.typ_other}</Button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    {/* LABEL */}
                                    <p style={{ ...styles.TextLarge, fontWeight: '500', marginTop: 40 }}>{lang.info}</p>


                                    <StatsInfoSalons accountMedical={data.salon1_id == 0 ? false : true} isSmall={props.isSmall} country={props.country} language={props.language} lang={props.lang} user={props.user} salon={data} width={dialogWidth - menuWidth} offset={props.offset} theme={props.theme} menu_width={0} />

                                </div>
                                : null}

                            {index == 2 ?
                                /*
                                *
                                * =================================================================================================================
                                * ŠTATISTIKY
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - titleHeight - selectHeight }}>
                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - selectHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        {/* BODY */}
                                        <StatsSalons isSmall={props.isSmall} country={props.country} selector={true} admin={false} typ={0} language={props.language} lang={props.lang} user={props.user} salon={data} width={dialogWidth - menuWidth} offset={props.offset} theme={props.theme} menu_width={menuWidth} />
                                    </div>
                                </div>
                                : null}

                            {index == 1 ?
                                /*
                                *
                                * =================================================================================================================
                                * PRODUKTY - SKLADOVY STAV
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - titleHeight - selectHeight }}>
                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - selectHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        {/* BODY */}
                                        <ProductsStock isSmall={props.isSmall} salon_id={data.id} admin={props.admin} lang={props.lang} language={props.language} title={lang.products_list} sub_title={lang.products} height={dialogHeight - titleHeight - selectHeight} width={dialogWidth - menuWidth} radius={props.radius} offset={props.offset} theme={props.theme} />
                                    </div>
                                </div>
                                : null}

                            {index == 3 ?
                                /*
                                *
                                * =================================================================================================================
                                * OBJEDNAVKY PRODUKTOV
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - titleHeight - selectHeight }}>

                                    <div style={{ ...styles.Block, height: graphSize + labelSize + noteSize }}>
                                        <div style={{ ...styles.Block }}>
                                            <div style={{ ...styles.BlockRow, height: labelSize }}>
                                                <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '500', marginLeft: 20 }}>{lang.booking_products}</p>
                                                    <p style={{ ...styles.TextTiny, fontWeight: '400', marginLeft: 20 }}>{lang.sum_selected_season + ': ' + FormatMoney(costAll) + ' ' + money + ' ' + lang.with_dph + ' / ' + FormatMoney(costAll / dph) + ' ' + money + ' ' + lang.without_dph}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 200 }}>
                                                    <SelectYear min={2020} value={actualYear} func={ChangeYear.bind(this)} />
                                                </div>
                                            </div>

                                            <Graph
                                                lang={props.lang}
                                                elevation={0}
                                                mini={true}
                                                radius={props.radius}
                                                year={false}
                                                title={lang.info_clients_age}
                                                sub_title={''}
                                                width={props.isSmall ? width - 10 : 760}
                                                height={graphSize - 20}
                                                typ={0}
                                                data={graphData}
                                                barWidth={props.isSmall ? 26 : 40}
                                                highColor={global.theme_red}
                                                overColor={global.theme_blue}
                                                showMax={false}
                                                lines={true}
                                                units={''}
                                                marginTop={0}
                                                selectable={true}
                                                show_value={true}
                                                legend={false}
                                                legenda={lang.products}
                                                legenda2={lang.services}
                                                isSmall={props.isSmall}
                                                func={GraphResult.bind(this)}
                                            />
                                        </div>
                                        <div style={{ ...styles.Block, height: noteSize }}>
                                            <p style={{ ...styles.TextTiny, color: global.theme_dark_gray }}>{lang.prices_without_dph}</p>
                                        </div>
                                    </div>


                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - graphSize - labelSize - noteSize, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div style={{ ...styles.Block, width: '96%' }}>
                                            {itemsFiltered != false ?
                                                itemsFiltered.map((item, index) => (
                                                    <Paper onClick={() => SelectOrderProducts(item)} elevation={2} key={item.id} style={{ ...styles.Block, height: rowHeight, backgroundColor: item.count == 0 ? global.theme_light_red : global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div style={{ ...styles.BlockLeft, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                                                <div style={{ ...styles.Block }}>
                                                                    <div style={{ ...styles.Block, height: 36, width: 36, backgroundColor: global.booking_color_agent[item.status], borderRadius: '50%' }}>
                                                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={global.booking_icon_agent[item.status]} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {props.isSmall ?
                                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '90%', justifyContent: 'center', marginLeft: 10 }}>
                                                                    <p style={{ ...styles.TextTinyMobile, fontWeight: '600', textAlign: 'left' }}>{lang.booking_preview} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{item.number}</span></p>
                                                                    <p style={{ ...styles.TextTinyMobile, marginTop: 5 }}>{GetDate(item.date)}</p>
                                                                    {item.reklamacia == true ?
                                                                        <p style={{ ...styles.TextTinyMobile, color: global.theme_dark_red, fontWeight: '600' }}>{lang.booking_reklamacia_true}</p>
                                                                        :
                                                                        <p style={{ ...styles.TextTinyMobile, fontWeight: '600' }}>{lang.booking_status_agent[item.status]}</p>
                                                                    }
                                                                </div>
                                                                :
                                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '90%', justifyContent: 'center', marginLeft: 10 }}>
                                                                    <div style={{ ...styles.BlockRow }}>
                                                                        <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                                            <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{lang.booking_preview} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{item.number}</span></p>
                                                                        </div>
                                                                        <div style={{ ...styles.BlockRight, width: '70%' }}>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                                            <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                                                <p style={{ ...styles.TextTiny }}>{GetDate(item.date)}</p>
                                                                            </div>
                                                                            <div style={{ ...styles.Block, width: '30%' }}>
                                                                                <p style={{ ...styles.TextTiny }}>{lang.booking_items}: {item.count} {lang.pieces}</p>
                                                                            </div>
                                                                            <div style={{ ...styles.BlockRight, width: '40%' }}>
                                                                                {item.reklamacia == true ?
                                                                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_red, fontWeight: '600' }}>{lang.booking_reklamacia_true}</p>
                                                                                    :
                                                                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.booking_status_agent[item.status]}</p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Paper>
                                                )) :
                                                // ZIADNE OBJEDNAVKY                            
                                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                                    <EmptyList lang={lang} />
                                                </div>
                                            }

                                            {itemsFiltered != false ?
                                                pageMax > 1 ?
                                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                                    </div>
                                                    : <div style={{ ...styles.Block, minHeight: 100 }}></div>
                                                : null}
                                        </div>

                                    </div>
                                </div>
                                : null}


                            {index == 4 ?
                                /*
                                *
                                * =================================================================================================================
                                * OBJEDNAVKY BONUSU
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - titleHeight - selectHeight }}>

                                    <div style={{ ...styles.Block, height: graphSize + labelSize }}>
                                        <div style={{ ...styles.Block }}>
                                            <div style={{ ...styles.BlockRow, height: labelSize }}>
                                                <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                    <p style={{ ...styles.TextNormal, fontWeight: '500', marginLeft: 20 }}>{lang.bonus_bookings}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 200 }}>
                                                    <SelectYear min={2020} value={actualYear} func={ChangeYear.bind(this)} />
                                                </div>
                                            </div>

                                            <Graph
                                                lang={props.lang}
                                                elevation={0}
                                                mini={true}
                                                radius={props.radius}
                                                year={false}
                                                title={lang.info_clients_age}
                                                sub_title={''}
                                                width={props.isSmall ? width - 10 : 760}
                                                height={graphSize - 20}
                                                typ={0}
                                                data={graphData}
                                                barWidth={props.isSmall ? 26 : 40}
                                                highColor={global.theme_red}
                                                overColor={global.theme_blue}
                                                showMax={false}
                                                lines={true}
                                                units={''}
                                                marginTop={0}
                                                selectable={true}
                                                show_value={true}
                                                legend={false}
                                                legenda={lang.products}
                                                legenda2={lang.services}
                                                isSmall={props.isSmall}
                                                func={GraphResult.bind(this)}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - graphSize, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div style={{ ...styles.Block, width: '96%' }}>
                                            {itemsFiltered != false ?
                                                itemsFiltered.map((item, index) => (
                                                    <Paper onClick={() => SelectOrderBonus(item)} elevation={2} key={item.id} style={{ ...styles.Block, height: rowHeight, backgroundColor: item.count == 0 ? global.theme_light_red : global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div style={{ ...styles.BlockLeft, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                                                <div style={{ ...styles.Block }}>
                                                                    <div style={{ ...styles.Block, height: 36, width: 36, backgroundColor: global.booking_color_agent[item.status], borderRadius: '50%' }}>
                                                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={global.booking_icon_agent[item.status]} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {props.isSmall ?
                                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '90%', justifyContent: 'center', marginLeft: 10 }}>
                                                                    <p style={{ ...styles.TextTinyMobile, fontWeight: '600', textAlign: 'left' }}>{lang.booking_preview} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{item.number}</span></p>
                                                                    <p style={{ ...styles.TextTinyMobile, marginTop: 5 }}>{GetDate(item.date)}</p>
                                                                    {item.reklamacia == true ?
                                                                        <p style={{ ...styles.TextTinyMobile, color: global.theme_dark_red, fontWeight: '600' }}>{lang.booking_reklamacia_true}</p>
                                                                        :
                                                                        <p style={{ ...styles.TextTinyMobile, fontWeight: '600' }}>{lang.booking_status_agent[item.status]}</p>
                                                                    }
                                                                </div>
                                                                :
                                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '90%', justifyContent: 'center', marginLeft: 10 }}>
                                                                    <div style={{ ...styles.BlockRow }}>
                                                                        <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                                            <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{lang.booking_preview} <span style={{ fontSize: global.font_xtiny, fontWeight: '400' }}>{item.number}</span></p>
                                                                        </div>
                                                                        <div style={{ ...styles.BlockRight, width: '70%' }}>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                                            <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                                                <p style={{ ...styles.TextTiny }}>{GetDate(item.date)}</p>
                                                                            </div>
                                                                            <div style={{ ...styles.Block, width: '30%' }}>
                                                                                <p style={{ ...styles.TextTiny }}>{lang.booking_items}: {item.count} {lang.pieces}</p>
                                                                            </div>
                                                                            <div style={{ ...styles.BlockRight, width: '40%' }}>
                                                                                {item.reklamacia == true ?
                                                                                    <p style={{ ...styles.TextTiny, color: global.theme_dark_red, fontWeight: '600' }}>{lang.booking_reklamacia_true}</p>
                                                                                    :
                                                                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.booking_status_agent[item.status]}</p>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Paper>
                                                )) :
                                                // ZIADNE OBJEDNAVKY                            
                                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                                    <EmptyList lang={lang} />
                                                </div>
                                            }

                                            {itemsFiltered != false ?
                                                pageMax > 1 ?
                                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                                    </div>
                                                    : <div style={{ ...styles.Block, minHeight: 100 }}></div>
                                                : null}
                                        </div>

                                    </div>
                                </div>
                                : null}


                            {index == 7 ?
                                /*
                                *
                                * =================================================================================================================
                                * OBCHODNY ZASTUPCA
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - titleHeight - selectHeight }}>
                                    <div style={{ ...styles.Block, height: labelSize }}>
                                        {/* LABEL */}
                                        <p style={{ ...styles.TextLarge, fontWeight: '500' }}>{lang.agent_change}</p>
                                    </div>
                                    <div style={{ ...styles.BlockCenter, maxWidth: 350, height: dialogHeight - titleHeight - labelSize, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        {/* BODY */}
                                        {items != false ? items.map((item, index) => (
                                            <div onClick={() => ChangeAgent(item)} key={item.id} style={{ ...styles.Block, height: agentSize, backgroundColor: item.id == agentID ? global.theme_light_blue : global.theme_light_gray, marginTop: 5, marginBottom: 5, borderRadius: agentSize / 2, cursor: 'pointer' }}>
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div style={{ ...styles.Block, marginLeft: 4, width: agentSize - 4, marginTop: 4, height: agentSize - 8, borderRadius: (agentSize - 10) / 2 }}>
                                                        <img src={GetImageLink(item.flag)} style={{ width: '100%', height: '100%', width: agentSize - 12, height: agentSize - 12, objectFit: 'contain', borderRadius: 10 }}></img>
                                                    </div>

                                                    <div style={{ ...styles.Block, height: agentSize }}>
                                                        <p style={{ ...styles.TextXSmall, color: item.id == agentID ? global.theme_white : global.theme_black }}>{item.name} {item.surname}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        )) : null}
                                    </div>
                                </div>
                                : null}

                            {index == 5 ?
                                /*
                                *
                                * =================================================================================================================
                                * HISTORIA KREDITU
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - titleHeight - selectHeight }}>

                                    <div style={{ ...styles.Block, height: graphSize + labelSize }}>
                                        <div style={{ ...styles.Block }}>
                                            <div style={{ ...styles.BlockRow, height: labelSize }}>
                                                <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                    <p style={{ ...styles.TextNormal, fontWeight: '500', marginLeft: 20 }}>{lang.bonus_credit_history}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 200 }}>
                                                    <Button onClick={() => setShowStatus(true)} style={{ ...styles.ButtonDark, width: 180 }}>{lang.bonus_credit_status}</Button>
                                                </div>
                                                <div style={{ ...styles.Block, width: 200 }}>
                                                    <SelectYear min={2020} value={actualYear} func={ChangeYear.bind(this)} />
                                                </div>
                                            </div>

                                            <Graph
                                                lang={props.lang}
                                                elevation={0}
                                                mini={true}
                                                radius={props.radius}
                                                year={false}
                                                title={lang.info_clients_age}
                                                sub_title={''}
                                                width={props.isSmall ? width : 760}
                                                height={graphSize - 20}
                                                typ={0}
                                                data={graphData}
                                                barWidth={props.isSmall ? 26 : 40}
                                                highColor={global.theme_red}
                                                overColor={global.theme_blue}
                                                showMax={false}
                                                lines={true}
                                                units={'.'}
                                                marginTop={0}
                                                selectable={true}
                                                show_value={true}
                                                legend={false}
                                                legenda={lang.products}
                                                legenda2={lang.services}
                                                isSmall={props.isSmall}
                                                func={GraphResult.bind(this)}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, maxWidth: 600, height: dialogHeight - titleHeight - graphSize, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        {/* BODY */}
                                        {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                            <div onClick={() => BonusOrder(item)} key={item.id} style={{ ...styles.BlockRow, height: agentSize, backgroundColor: item.id == agentID ? global.theme_light_blue : global.theme_light_gray, marginTop: 5, marginBottom: 5, borderRadius: agentSize / 2, cursor: item.admin == false ? 'pointer' : 'default' }}>
                                                <div style={{ ...styles.BlockLeft, width: 100, height: agentSize, justifyContent: 'center' }}>
                                                    <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextXSmall, textAlign: 'left', marginLeft: 10 }}>{GetDate(item.updated)}</p>
                                                </div>
                                                <div style={{ ...styles.BlockLeft, width: '50%', height: agentSize, justifyContent: 'center' }}>
                                                    <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextXSmall, textAlign: 'left' }}>{item.order_id == 0 && item.agent_id == 0 ? lang.bonus_history_set : parseInt(item.order_id) > 0 && parseFloat(item.credit) <= 0 ? lang.bonus_history_buy : lang.bonus_history_return}</p>
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: '30%', height: agentSize, justifyContent: 'center' }}>
                                                    <p style={{ ...props.isSmall ? styles.TextTinyMobile : styles.TextXSmall, marginRight: 10, textAlign: 'left' }}>{item.credit}</p>
                                                </div>
                                            </div>
                                        )) : null}

                                        {/*itemsFiltered != false ?
                                            pageMax > 1 ?
                                                <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                                    <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                                </div>
                                                : null
                                    : null*/}

                                        <div style={{ width: '100%', minHeight: 50 }}></div>
                                    </div>
                                </div>
                                : null}


                            {index == 8 ?
                                /*
                                *
                                * =================================================================================================================
                                * REKLAMACIE
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - titleHeight - selectHeight }}>

                                    <div style={{ ...styles.Block, height: graphSize + labelSize }}>
                                        <div style={{ ...styles.Block }}>
                                            <div style={{ ...styles.BlockRow, height: labelSize }}>
                                                <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                    <p style={{ ...styles.TextNormal, fontWeight: '500', marginLeft: 20 }}>{lang.reklamacie}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 200 }}>
                                                    <SelectYear min={2020} value={actualYear} func={ChangeYear.bind(this)} />
                                                </div>
                                            </div>

                                            <Graph
                                                lang={props.lang}
                                                elevation={0}
                                                mini={true}
                                                radius={props.radius}
                                                year={false}
                                                title={lang.info_clients_age}
                                                sub_title={''}
                                                width={props.isSmall ? width - 10 : 760}
                                                height={graphSize - 20}
                                                typ={0}
                                                data={graphData}
                                                barWidth={props.isSmall ? 26 : 40}
                                                highColor={global.theme_red}
                                                overColor={global.theme_blue}
                                                showMax={false}
                                                lines={true}
                                                units={'x'}
                                                marginTop={0}
                                                selectable={true}
                                                show_value={true}
                                                legend={false}
                                                legenda={lang.products}
                                                legenda2={lang.services}
                                                isSmall={props.isSmall}
                                                func={GraphResult.bind(this)}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - graphSize, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div style={{ ...styles.Block, width: '96%' }}>
                                            {itemsFiltered != false ?
                                                itemsFiltered.map((item, index) => (
                                                    <Paper onClick={() => SelectReklamacia(item)} elevation={2} key={item.id} style={{ ...styles.Block, height: rowHeight, backgroundColor: item.count == 0 ? global.theme_light_red : global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div style={{ ...styles.BlockLeft, width: 50, height: rowHeight, justifyContent: 'center' }}>
                                                                <div style={{ ...styles.Block }}>
                                                                    <div style={{ ...styles.Block, height: 36, width: 36, backgroundColor: global.reklamacia_back_color[item.status], borderRadius: '50%' }}>
                                                                        <FontAwesomeIcon style={{ height: 16, color: global.theme_black, color: global.reklamacia_color[item.status] }} icon={global.reklamacia_icon[item.status]} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {props.isSmall ?
                                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '90%', justifyContent: 'center', marginLeft: 10 }}>
                                                                    <p style={{ ...styles.TextTinyMobile, fontWeight: '600', textAlign: 'left' }}>{item.typ == 0 ? lang.reklamacia_products : lang.reklamacia_bonus} <span style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'left' }}>{item.number}</span></p>
                                                                    <p style={{ ...styles.TextTinyMobile, marginTop: 5 }}>{GetDate(item.date)}</p>
                                                                    <p style={{ ...styles.TextTinyMobile, fontWeight: '600' }}>{lang.reklamacia_status[item.status]}</p>
                                                                </div>
                                                                :
                                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '90%', justifyContent: 'center', marginLeft: 10 }}>
                                                                    <div style={{ ...styles.BlockLeft }}>
                                                                        <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.typ == 0 ? lang.reklamacia_products : lang.reklamacia_bonus} <span style={{ ...styles.TextTiny, fontWeight: '400', textAlign: 'left' }}>{item.number}</span></p>
                                                                    </div>
                                                                    <div style={{ ...styles.BlockLeft, width: '100%' }}>
                                                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                                            <div style={{ ...styles.BlockLeft, width: '30%' }}>
                                                                                <p style={{ ...styles.TextTiny }}>{GetDate(item.date)}</p>
                                                                            </div>
                                                                            <div style={{ ...styles.Block, width: '30%' }}>
                                                                            </div>
                                                                            <div style={{ ...styles.BlockRight, width: '40%' }}>
                                                                                <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{lang.reklamacia_status[item.status]}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Paper>
                                                )) :
                                                // ZIADNE OBJEDNAVKY                            
                                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                                    <EmptyList lang={lang} />
                                                </div>
                                            }

                                            {itemsFiltered != false ?
                                                pageMax > 1 ?
                                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                                    </div>
                                                    : <div style={{ ...styles.Block, minHeight: 100 }}></div>
                                                : null}
                                        </div>

                                    </div>
                                </div>
                                : null}

                            {index == 11 ?
                                /*
                                *
                                * =================================================================================================================
                                * EXPIRACIE
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - titleHeight - selectHeight }}>

                                    <div style={{ ...styles.Block, height: graphSize + labelSize }}>
                                        <div style={{ ...styles.Block }}>
                                            <div style={{ ...styles.BlockRow, height: labelSize }}>
                                                <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                                    <p style={{ ...styles.TextNormal, fontWeight: '500', marginLeft: 20 }}>{lang.expirations_}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 200 }}>
                                                    <SelectYear min={2020} value={actualYear} func={ChangeYear.bind(this)} />
                                                </div>
                                            </div>

                                            <Graph
                                                lang={props.lang}
                                                elevation={0}
                                                mini={true}
                                                radius={props.radius}
                                                year={false}
                                                title={lang.info_clients_age}
                                                sub_title={''}
                                                width={props.isSmall ? width - 10 : 760}
                                                height={graphSize - 20}
                                                typ={0}
                                                data={graphData}
                                                barWidth={props.isSmall ? 26 : 40}
                                                highColor={global.theme_red}
                                                overColor={global.theme_blue}
                                                showMax={false}
                                                lines={true}
                                                units={'x'}
                                                marginTop={0}
                                                selectable={true}
                                                show_value={true}
                                                legend={false}
                                                legenda={lang.products}
                                                legenda2={lang.services}
                                                isSmall={props.isSmall}
                                                func={GraphResult.bind(this)}
                                            />
                                        </div>
                                    </div>

                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - titleHeight - graphSize, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                        <div style={{ ...styles.Block, width: '96%' }}>
                                            {itemsFiltered != false ?
                                                itemsFiltered.map((item, index) => (
                                                    <Paper onClick={() => SelectExpire(item)} elevation={2} key={item.id} style={{ ...styles.Block, height: rowHeight, backgroundColor: item.count == 0 ? global.theme_light_red : global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            {props.isSmall ?
                                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '96%', justifyContent: 'center' }}>


                                                                    <div style={{ ...styles.BlockRow }}>
                                                                        <div style={{ ...styles.Block, width: rowHeight, height: rowHeight }}>
                                                                            <img src={item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', width: rowHeight - 8, height: rowHeight - 8, objectFit: 'contain', borderRadius: 14 }}></img>
                                                                        </div>
                                                                        <div style={{ ...styles.BlockLeft, width: '66%', height: rowHeight, justifyContent: 'center', marginLeft: 8 }}>
                                                                            <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_dark_gray }}>{GetDatum2(item.date)}</p>
                                                                            <TextLine text={item.label} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                                            <Chip size='small' label={<p style={{ ...styles.TextTiny, color: global.expirations_colors[item.status] }}>{lang.expiration_statuses[item.status]}</p>} variant='outlined' style={{ borderColor: global.expirations_colors[item.status], marginTop: 2 }} />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                :
                                                                <div style={{ ...styles.BlockLeft, height: rowHeight, width: '96%', justifyContent: 'center' }}>
                                                                    <div style={{ ...styles.BlockRow }}>
                                                                        <div style={{ ...styles.Block, width: rowHeight, height: rowHeight }}>
                                                                            <img src={item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', width: rowHeight - 8, height: rowHeight - 8, objectFit: 'contain', borderRadius: 14 }}></img>
                                                                        </div>
                                                                        <div style={{ ...styles.BlockLeft, width: '70%', height: rowHeight, justifyContent: 'center', marginLeft: 8 }}>
                                                                            <p style={{ ...styles.TextTiny, textAlign: 'left', color: global.theme_dark_gray }}>{GetDatum2(item.date)} {props.agent == undefined ? '' : item.salon_name}</p>
                                                                            <TextLine text={item.label} fontSize={global.font_xsmall} fontWeight={'600'} color={color.black} />
                                                                            <p style={{ ...styles.TextXSmall }}>{lang.expiration}: {item.month}/{item.year}</p>
                                                                        </div>
                                                                        <div style={{ ...styles.BlockRight, width: 200, height: rowHeight, justifyContent: 'center' }}>
                                                                            <Chip size='small' label={<p style={{ ...styles.TextTiny, color: global.expirations_colors[item.status] }}>{lang.expiration_statuses[item.status]}</p>} variant='outlined' style={{ borderColor: global.expirations_colors[item.status] }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </Paper>
                                                )) :
                                                // ZIADNE EXPIRACIE                            
                                                <div style={{ ...styles.Block, paddingTop: 20 }}>
                                                    <EmptyList lang={lang} />
                                                </div>
                                            }

                                            {itemsFiltered != false ?
                                                pageMax > 1 ?
                                                    <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                                        <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                                    </div>
                                                    : <div style={{ ...styles.Block, minHeight: 100 }}></div>
                                                :
                                                null
                                            }
                                        </div>

                                    </div>
                                </div>
                                : null}

                            {index == 9 ?
                                /*
                                *
                                * =================================================================================================================
                                * ŠKOLENIA
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - titleHeight - selectHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <div style={{ ...styles.BlockRow, height: labelSize, marginTop: 5 }}>
                                        <div style={{ ...styles.BlockLeft, width: '80%' }}>
                                            <p style={{ ...styles.TextNormal, fontWeight: '500', marginLeft: 20 }}>{lang.lessons_finished}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 200 }}>
                                            <SelectYear min={2020} value={actualYear} func={ChangeYear.bind(this)} />
                                        </div>
                                    </div>
                                    {itemsFiltered != false ?
                                        itemsFiltered.map((item, index) => (
                                            <Paper onClick={() => LessonSelect(item)} elevation={2} key={item.id} style={{ ...styles.Block, width: '96%', height: rowHeight, backgroundColor: global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}
                                            >
                                                <div style={{ ...styles.BlockRow }}>
                                                    {props.isSmall ?
                                                        <div style={{ ...styles.BlockCenter, height: rowHeight, width: 80, justifyContent: 'center' }}>
                                                            <img src={item.image == false || item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: 60, maxHeight: 60, objectFit: 'contain', borderRadius: 10 }}></img>
                                                        </div>
                                                        :
                                                        <div style={{ ...styles.Block, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                            <img src={item.image == false || item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: rowHeight, maxHeight: rowHeight, objectFit: 'contain', borderRadius: global.row_radius }}></img>
                                                        </div>
                                                    }
                                                    {props.isSmall ?
                                                        <div style={{ ...styles.BlockLeft, height: rowHeight, width: '72%', justifyContent: 'center', marginLeft: 10 }}>
                                                            <div ostyle={{ ...styles.BlockLeft }}>
                                                                <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.title}</p>
                                                                <p style={{ ...styles.TextTiny, marginTop: 5, textAlign: 'left' }}>{GetDate(item.date)} {GetTimeHM(item.time)} {lang.hour_short}</p>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div style={{ ...styles.BlockLeft, height: rowHeight, width: '87%', justifyContent: 'center', marginLeft: 10 }}>
                                                            <div style={{ ...styles.Block, height: rowHeight, justifyContent: 'center' }}>

                                                                <div style={{ ...styles.BlockLeft }}>
                                                                    <div style={{ ...styles.BlockRowRaw }}>
                                                                        {item.typ != global.lesson_canceled && item.online == true ?
                                                                            <div style={{ ...styles.Block, width: 80, backgroundColor: global.theme_dark_gray, height: 20, borderRadius: 10, marginRight: 10 }}>
                                                                                <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.lesson_online_} </p>
                                                                            </div>
                                                                            : null}
                                                                        <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.title}</p>
                                                                    </div>
                                                                </div>

                                                                <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                                    <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                                                        <p style={{ ...styles.TextTiny }}>{GetDate(item.date)} {GetTimeHM(item.time)} {lang.hour_short}</p>
                                                                        <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.lesson_duration}: {GetHoursFromMinutes(item.duration, lang)}</p>
                                                                    </div>
                                                                    <div style={{ ...styles.BlockRight, width: '50%', marginRight: 15 }}>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </Paper>
                                        )) :
                                        // ZIADNE POLOZKY                            
                                        <div style={{ ...styles.Block, paddingTop: 20 }}>
                                            <EmptyList lang={lang} />
                                        </div>
                                    }

                                </div>
                                : null}


                            {index == 10 ?
                                /*
                                *
                                * =================================================================================================================
                                * ESHOP
                                * =================================================================================================================
                                *
                                */
                                <div style={{ ...styles.BlockCenter, width: dialogWidth - menuWidth, height: dialogHeight - titleHeight - selectHeight, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <div style={{ ...styles.BlockRow, height: labelSize, marginTop: 5 }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextNormal, fontWeight: '500', marginLeft: 20 }}>{lang.web_page_eshop}</p>
                                        </div>
                                    </div>
                                    <div style={{ ...styles.Block, marginTop: 50 }}>
                                        <div style={{ ...styles.Block, width: 200, height: 200 }}>
                                            <QRCode
                                                size={256}
                                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                value={GetLanguage(data.language).www_eshop + '/' + data.alias.toLowerCase()}
                                                viewBox={`0 0 256 256`}
                                            />
                                        </div>
                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{GetLanguage(data.language).www_eshop + '/' + data.alias.toLowerCase()}</p>
                                    </div>
                                    <div style={{ ...styles.Block, marginTop: 60 }}>
                                        <p style={{ ...styles.TextSmall }}>{lang.web_page}: {data.eshop == true ? lang.on_ : lang.off_}</p>
                                        {data.eshop == true ?
                                            <p style={{ ...styles.TextSmall }}>{data.eshop_ready == true ? lang.eshop_enabled_ : lang.eshop_disabled}</p>
                                            : null}
                                    </div>
                                </div>
                                : null}

                        </div>
                    </div>
                </div>
            </Paper >

            {showExpire == true ?
                <Expiration agent item={itemSelected} cardHide={true} isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} countries={props.countries} country={props.country} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={ExpirationResult.bind(this)} />
                : null}

            {showStatus == true ?
                <SalonCreditStatus salon_id={props.salon_id} isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} lang={lang} radius={props.radius} language={props.language} func={() => setShowStatus(false)} />
                : null}

            {showLesson ?
                <LessonDetails preview={true} item={itemSelected} remoteApp={props.remoteApp} user={props.user} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} func={() => setShowLesson(false)} />
                : null}

            {showExport == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.booking_export_} sub_title={''} text={lang.export_agent_data_ask} sub_text={lang.export_agent_data_text + ' ' + props.user.email} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={ExportResult.bind(this)} />
                : null}

            {showOrderProducts == true ?
                <ProductBookingDetailsAgent isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} countries={props.countries} country={props.country} order_id={itemSelected.id} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={OrderProductsResult.bind(this)} />
                : null}

            {showOrderBonus == true ?
                <BonusBookingDetailsAgent isSmall={props.isSmall} country={props.country} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} order_id={itemSelected.id} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={OrderBonusResult.bind(this)} />
                : null}

            {showReklamacia == true ?
                <ReklamaciaDetailsAgent isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} countries={props.countries} country={props.country} reklamacia_id={itemSelected.id} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={ReklamaciaResult.bind(this)} />
                : null}

            {
                showBonusOrder == true ?
                    <BonusBookingDetailsAgent isSmall={props.isSmall} preview={true} country={props.country} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} order_id={itemSelected.order_id} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} func={() => setShowBonusOrder(false)} />
                    : null
            }

            {
                showOK == true ?
                    <ShowOK offset={props.offset} theme={props.theme} side={menuWidth} func={ShowOKPress.bind(this)} />
                    : null
            }

            {
                isBusy == true || isLoading == true ?
                    <Loading offset={props.offset} theme={props.theme} side={menuWidth}></Loading>
                    : null
            }

        </Backdrop >
    );
}


export const SalonCreditStatus = (props) => {
    /*
            STAV KREDITOV
    */

    const [isBusy, setBusy] = useState(false);
    const [status, setStatus] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = 700;
    const dialogHeight = 500;
    const buttonHeight = 80;

    let { func } = props;
    var lang = props.lang;
    var running = false;

    useEffect(() => {

        if (running == false) {

            db_get();

            running = true;
        }
        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url_agent + 'salon_credit_status', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    salon_id: props.salon_id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setStatus(json.status);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Close = () => {
        func(false);
    }


    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: 45 }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.bonus_credit_status}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.bonus_booking}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    {status != false ?
                        <div style={{ ...styles.Block, width: '94%', height: dialogHeight - global.dialog_bar_height - buttonHeight }}>
                            {/* BODY */}
                            <div style={{ ...styles.Block, paddingTop: 15, paddingBottom: 15, backgroundColor: global.theme_white, borderRadius: 10 }}>
                                <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{lang.bonus_credit_year}: {status.credit_year}</p>
                                <p style={{ ...styles.TextXSmall, marginTop: 2, color: global.theme_dark_gray }}>{lang.bonus_credit_year_note1}</p>
                                <div style={{ ...styles.Block, width: '90%', height: 1, backgroundColor: global.theme_light_gray, marginTop: 6 }}></div>
                                <p style={{ ...styles.TextSmall, marginTop: 6, fontWeight: '600' }}>{lang.bonus_credit_year_left}: {status.credit_left}</p>
                                <p style={{ ...styles.TextXSmall, marginTop: 2, color: global.theme_dark_gray }}>{lang.bonus_credit_year_left_note}</p>
                            </div>
                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                <p style={{ ...styles.TextXSmall }}>{lang.bonus_credit_year_note2}</p>
                                <p style={{ ...styles.TextXSmall }}>{lang.bonus_credit_year_note3.replace('@n', status.periode_months)}</p>
                                <p style={{ ...styles.TextXSmall, marginTop: 10 }}>{lang.bonus_credit_periode.replace('@n', parseFloat(status.credit_period).toFixed(2))}</p>
                                <p style={{ ...styles.TextXSmall }}>{lang.bonus_credit_periode_left}: {status.periode_left}</p>
                            </div>
                            <div style={{ ...styles.Block, marginTop: 20 }}>
                                <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{lang.bonus_credit_actual}: {status.credit}</p>
                            </div>
                        </div>
                        :
                        <div style={{ ...styles.Block, width: '94%', height: dialogHeight - global.dialog_bar_height - buttonHeight }}></div>
                    }

                    <div style={{ ...styles.Block, height: buttonHeight }}>
                        <Button onClick={() => Close()} style={{ ...styles.ButtonDark }}>{lang.close}</Button>
                    </div>
                </div>
            </Paper>

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >
    );
}


