/*
*
* =================================================================================================================
* ŠKOLENIA - AGENT
* =================================================================================================================
*
*/
import React, { useEffect, useState, useRef } from 'react';
import './App.css';
import './items.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, CountrySelect, Debug, DialogEnterNumber, DialogEnterText, DialogImagePreview, DialogInfo, DialogLogin, DialogVideo, DialogYesNo, EmployeeSelect, EmptyList, FormError, FormOK, GetDate, GetDate3, GetDateMinusDays, GetDatum2, GetHours, GetHoursFromMinutes, GetImageLink, GetMinutesFromHours, GetMoney, GetPages, GetPrice, GetTimeHM, Init, IsEmpty, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, CircularProgress, FormControlLabel, Hidden, Icon, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, Tooltip, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faA, faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faBook, faBookOpen, faCheck, faCheckCircle, faComment, faCopy, faEdit, faEye, faEyeSlash, faIdCard, faImage, faInfo, faInfoCircle, faL, faLayerGroup, faList, faListAlt, faListCheck, faListOl, faPaperPlane, faPeace, faPen, faPlus, faPlusCircle, faQuestion, faQuestionCircle, faRefresh, faSave, faSearch, faShare, faShoppingBag, faShoppingBasket, faShoppingCart, faToggleOff, faToggleOn, faTrash, faUser, faUsers, faVideo, faWarning, faXmark, faXmarkCircle, faYen } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog, ProductPreview, ShoppingCard } from './product_dialog';
import { ClientSelect } from './client_select';
import { centerCrop } from 'react-image-crop';
import { ProductBookingCard } from './products_booking';
import { Photos } from './photos';
import { ProductSelect } from './product_select';
import { SalonCard } from './salon_card';
import { SalonSelect } from './salon_select';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { UserSelect } from './user_select';
import { Register } from './register';
import { LessonDetails } from './lessons';
import { LessonsCategories } from './lessons_categories';

export const LessonsAgent = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(props.masterclass == true ? 1 : 0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);
    const [actualYear, setActualYear] = useState(new Date().getFullYear());

    // PREDAJ - ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const [countryID, setCountryID] = useState(-1);
    const itemsMax = 24;

    // NOVY PRISPEVOK
    const [showCreate, setShowCreate] = useState(false);
    const [edit, setEdit] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // REGISTROVANY ZAKAZNICI NA SKOLENIE
    const [showRegistered, setShowRegistered] = useState(false);

    // NAHLAD SKOLENIA - AGENT
    const [showLesson, setShowLesson] = useState(false);

    // ODOSLAT NOTIFIKACIE
    const [showNotify, setShowNotify] = useState(false);
    const [showNotifySended, setShowNotifySended] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = props.isSmall ? 160 : 120;
    const rowWidth = 850;
    const selectHeight = props.isSmall ? 40 : 0;

    // COLORS
    const font_selected = global.theme_black;
    const font_disabled = global.theme_darker;

    const no_image = require('./react/app/no_image.jpg');
    const flag_sk = require('./react/app/flag_sk.png');
    const flag_cz = require('./react/app/flag_cz.png');

    // Aktuálna farebna téma
    const color = global.themes[props.theme];

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            let year = new Date().getFullYear();
            let typ = props.masterclass == true ? 1 : 0;
            db_get(typ, year);
        }

    }, []);

    const db_get = async (typ, year) => {
        setBusy(true);

        setItems(false);
        setItemsFiltered(false);
        try {
            const response = await fetch(
                global.db_url + 'lessons', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    language: props.country.language_id,
                    typ: typ,
                    admin: true,
                    year: year
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setIndex(typ);

                var tmp = json.items;

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const db_send_notify = async () => {
        setBusy(true);

        try {
            const response = await fetch(
                global.db_url + 'lessons_send_notify', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    language: props.country.language_id,
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                setShowOK(true);
            } else {
                setShowError(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Search = (text) => {
        setSearch(text);
        Redraw(items, 0, text, countryID);
    }

    const Redraw = (items, p, search, country_id) => {
        Debug(country_id);
        // REDRAW PRODUKTOV
        var data = items;

        if (country_id == 1) {
            data = data.filter(x => x.sk == '1');
        }
        if (country_id == 2) {
            data = data.filter(x => x.cz == '1');
        }

        if (search.trim() != '') {
            // filtrovanie podla hladaneho retazca
            var words = search.split(' ');
            data = data.filter(x => FindWords(x.title, GetDate(x.date), x.teacher, words));
        }

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    function FindWords(label, date, salon, words) {
        // hladanie retazca v nazve a keywords        
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || salon.toLowerCase().includes(word.toLowerCase()) || date.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }
        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '', countryID);
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search, countryID);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }


    const ChangeIndex = (id) => {
        setIndex(id);

        let year = new Date().getFullYear();
        setActualYear(year);

        setSearch('');
        db_get(id, year);
    }

    const AddNew = () => {
        setEdit(false);
        setShowCreate(true);
    }

    const LessonResult = (redraw, data) => {
        setShowCreate(false);
        if (redraw == true) {
            if (edit == false) {
                let index_ = data.typ == 0 ? 0 : data.typ == 1 ? 3 : 2;
                db_get(index_, actualYear);
            } else {
                db_get(index, actualYear);
            }
        }
    }

    const LessonPreview = () => {
        setShowLesson(false);
    }

    const SelectItem = (item) => {
        if (props.user.shared == true || props.admin == true) {
            setItemSelected(item);
            setEdit(true);
            setShowCreate(true);
        } else {
            setItemSelected(item);
            setShowLesson(true)
        }
    }


    const UsersList = (item) => {
        setItemSelected(item);

        setShowRegistered(true);
    }


    const RegisteredResult = (redraw) => {
        setShowRegistered(false);
        if (redraw == true) {
            db_get(index, actualYear);
        }
    }

    const ChangeYear = (year, month) => {
        setActualYear(year)
        db_get(index, year);
    }


    const CountryResult = (value) => {
        setCountryID(value.id);
        Redraw(items, 0, search, value.id);
    }

    const SendNotify = (value) => {
        setShowNotify(false);

        if (value == true) {
            db_send_notify();
        }
    }

    /*    
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faBookOpen} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '45%' : '38%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>
                {props.isSmall ? null :
                    <div style={{ ...styles.BlockLeft, width: 200, height: global.dialog_bar_height, justifyContent: 'center', marginRight: 20 }}>
                        <CountrySelect allCountries theme={props.theme} radius width={200} countries={props.countries} background={global.themes[props.theme].theme_lighter} value={{ id: countryID }} lang={lang} func={CountryResult.bind(this)} />
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? 160 : 160, height: global.dialog_bar_height }}>
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 140 : 140 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                </div>
                {props.isSmall == true ? null :
                    <div style={{ ...styles.Block, width: 200, height: global.dialog_bar_height, justifyContent: 'center', marginLeft: 10 }}>
                        {props.user.shared == true || props.admin == true ?
                            <Button onClick={() => setShowNotify(true)} style={{ ...styles.ButtonDark, width: 160, backgroundColor: color.theme_dark }}>
                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.lessons_send_notify}</p>
                            </Button>
                            : null}
                    </div>
                }
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 200, height: global.dialog_bar_height }}>
                        {props.user.shared == true || props.admin == true ?
                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonDark, backgroundColor: color.theme_dark, width: 160, marginLeft: 10 }}>
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPlusCircle} />
                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                    {lang.lesson_new}
                                </p>
                            </Button>
                            : null}
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                        {props.isSmall ?
                            <div style={{ ...styles.Block, height: selectHeight }}>
                                <Select
                                    value={index}
                                    onChange={event => ChangeIndex(event.target.value)}
                                    size='small'
                                    style={{ backgroundColor: global.theme_white, width: '100%', height: selectHeight }}
                                    MenuProps={{
                                        style: { zIndex: 10000 }
                                    }}
                                >
                                    <MenuItem value={0}>{lang.lessons_planned}</MenuItem>
                                    <MenuItem value={1}>{lang.lessons_finished}</MenuItem>
                                    <MenuItem value={2}>{lang.lessons_canceled}</MenuItem>
                                    <MenuItem value={3}>{lang.lessons_draft}</MenuItem>
                                </Select>
                            </div>
                            :
                            <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, background: global.themes[props.theme].theme_leftmenu_background, borderBottomLeftRadius: props.radius }}>
                                <div style={{ ...styles.BlockCenter, width: menuWidth, height: props.height - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                                    <Button onClick={() => ChangeIndex(0)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 0 ? global.theme_back : '#00000000', color: index == 0 ? font_selected : font_disabled }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.lessons_planned}</p>
                                        </div>
                                    </Button>
                                    <Button onClick={() => ChangeIndex(1)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 1 ? global.theme_back : '#00000000', color: index == 1 ? font_selected : font_disabled }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.lessons_finished}</p>
                                        </div>
                                    </Button>
                                    {props.masterclass == undefined ?
                                        <Button onClick={() => ChangeIndex(2)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 2 ? global.theme_back : '#00000000', color: index == 2 ? font_selected : font_disabled }}>
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.lessons_canceled}</p>
                                            </div>
                                        </Button>
                                        : null}
                                    {props.masterclass == undefined ?
                                        <Button onClick={() => ChangeIndex(3)} style={{ ...styles.ButtonSettingsBig, justifyContent: 'flex-start', backgroundColor: index == 3 ? global.theme_back : '#00000000', color: index == 3 ? font_selected : font_disabled }}>
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...styles.TextSmall, color: global.themes[props.theme].theme_darker, lineHeight: 1, textAlign: 'left', fontWeight: '500' }}>{lang.lessons_draft}</p>
                                            </div>
                                        </Button>
                                        : null}
                                </div>
                            </div>
                        }
                        <div style={{ ...styles.BlockCenter, width: props.width - menuWidth, height: props.height - global.dialog_bar_height - selectHeight, backgroundColor: global.theme_back, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>

                            < div id={'id_start'} style={{ ...styles.BlockCenter, width: '98%', paddingBottom: 100 }}>
                                {index == 1 || index == 2 ?
                                    <div style={{ ...styles.BlockRight, maxWidth: rowWidth }}>
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <div style={{ ...styles.Block, height: 60 }}>
                                                <p style={{ ...styles.TextTiny }}>{lang.lessons_list_year}</p>
                                            </div>
                                            <div style={{ ...styles.Block, width: 200, height: 60 }}>
                                                <SelectYear min={2020} value={actualYear} func={ChangeYear.bind(this)} />
                                            </div>
                                        </div>
                                    </div>
                                    : null}

                                {/* *********************************************************************************************************  
                                            ZOZNAM SKOLENI
                                    ********************************************************************************************************* */}
                                {props.isSmall ?
                                    <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                                        {props.user.shared == true || props.admin == true ?
                                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonDark, width: 180 }}>
                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPlusCircle} />
                                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                                    {lang.lesson_new}
                                                </p>
                                            </Button>
                                            : null}
                                    </div>
                                    : null}

                                {itemsFiltered != false ?
                                    itemsFiltered.map((item, index) => (
                                        <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow }}>
                                                {props.isSmall ?
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockCenter, height: rowHeight, width: 80, justifyContent: 'center' }}>
                                                        <img src={item.image == false || item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: 60, maxHeight: 60, objectFit: 'contain', borderRadius: 10 }}></img>
                                                        <div style={{ ...styles.BlockRow, width: 50, height: rowHeight, marginTop: 10 }}>
                                                            {item.sk == true ?
                                                                <img src={flag_sk} style={{ width: '100%', height: '100%', maxWidth: 25, maxHeight: 25, objectFit: 'contain' }}></img>
                                                                : null}
                                                            {item.cz == true ?
                                                                <img src={flag_cz} style={{ width: '100%', height: '100%', maxWidth: 25, maxHeight: 25, objectFit: 'contain', marginLeft: 5 }}></img>
                                                                : null}
                                                        </div>
                                                    </div>
                                                    :
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.Block, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                        <img src={item.image == false || item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: rowHeight, maxHeight: rowHeight, objectFit: 'contain', borderRadius: global.row_radius }}></img>
                                                    </div>
                                                }
                                                {props.isSmall ?
                                                    <div style={{ ...styles.BlockLeft, minHeight: rowHeight, width: '72%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft }}>
                                                            <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.title}</p>
                                                            <p style={{ ...styles.TextTiny, marginTop: 5 }}>{GetDate(item.date)} {GetTimeHM(item.time)} {lang.hour_short}</p>
                                                            {item.online == true ?
                                                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.lesson_registered}: {item.count}</p>
                                                                :
                                                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.lesson_registered}: {item.count}/{item.capacity}{parseInt(item.capacity_reserve) > 0 ? '+' + item.capacity_reserve : ''}</p>
                                                            }
                                                            <p style={{ ...styles.TextTiny, textAlign: 'left', marginRight: 20 }}>{lang.lesson_teacher}: {item.teacher}</p>
                                                            {item.online == false ?
                                                                <p style={{ ...styles.TextTiny, marginTop: 5 }}>{item.address}</p>
                                                                : null}
                                                        </div>
                                                        <div style={{ ...styles.BlockRight, marginTop: 20 }}>
                                                            <Button onClick={() => UsersList(item)} style={{ ...styles.ButtonDark, width: 180, backgroundColor: global.theme_white }}>
                                                                <p style={{ ...styles.TextTiny }}>
                                                                    {lang.lesson_users}
                                                                </p>
                                                            </Button>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: '87%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <div style={{ ...styles.BlockRow }}>
                                                            <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '80%', height: rowHeight, justifyContent: 'center' }}>
                                                                <div style={{ ...styles.BlockRowRaw }}>
                                                                    {item.online == true ?
                                                                        <div style={{ ...styles.Block, width: 80, backgroundColor: global.theme_dark_gray, height: 20, borderRadius: 10, marginRight: 10 }}>
                                                                            <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.lesson_online_} </p>
                                                                        </div>
                                                                        : null}
                                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.title}</p>
                                                                </div>
                                                                <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                                    <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                                                        <p style={{ ...styles.TextTiny }}>{GetDate(item.date)} {GetTimeHM(item.time)} {lang.hour_short}</p>
                                                                        <p style={{ ...styles.TextTiny }}>{lang.lesson_duration}: {GetHoursFromMinutes(item.duration, lang)}</p>
                                                                        {item.lesson_for != false ?
                                                                            <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.lesson_for_}: {item.lesson_for.includes(1) ? lang.lesson_for[1] : ''} {item.lesson_for.includes(2) ? lang.lesson_for[2] : ''}</p>
                                                                            : null}
                                                                    </div>
                                                                    <div style={{ ...styles.BlockRight, width: '50%', marginRight: 15 }}>
                                                                        {item.online == true ?
                                                                            <p style={{ ...styles.TextTiny }}>{lang.lesson_registered}: {item.count}</p>
                                                                            :
                                                                            <p style={{ ...styles.TextTiny }}>{lang.lesson_registered}: {item.count}/{item.capacity}{parseInt(item.capacity_reserve) > 0 ? '+' + item.capacity_reserve : ''}</p>
                                                                        }
                                                                        <p style={{ ...styles.TextTiny }}>{lang.lesson_teacher}: {item.teacher}</p>
                                                                        {item.online == false ?
                                                                            <p style={{ ...styles.TextTiny }}>{item.address}</p>
                                                                            : null}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 50, height: rowHeight }}>
                                                                {item.sk == true ?
                                                                    <img src={flag_sk} style={{ width: '100%', height: '100%', maxWidth: 30, maxHeight: 30, objectFit: 'contain', marginTop: 5, marginBottom: 5 }}></img>
                                                                    : null}
                                                                {item.cz == true ?
                                                                    <img src={flag_cz} style={{ width: '100%', height: '100%', maxWidth: 30, maxHeight: 30, objectFit: 'contain', marginTop: 5, marginBottom: 5 }}></img>
                                                                    : null}
                                                            </div>
                                                            <div style={{ ...styles.Block, width: 150, height: rowHeight }}>
                                                                <Button onClick={() => UsersList(item)} style={{ ...styles.ButtonDark, width: 120, height: rowHeight - 20, backgroundColor: global.theme_white, borderRadius: 10 }}>
                                                                    <div style={{ ...styles.Block }}>
                                                                        <div style={{ ...styles.Block, width: 30, height: 30, backgroundColor: global.theme_gray, borderRadius: 15 }}>
                                                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faUser} />
                                                                        </div>
                                                                        <p style={{ ...styles.TextTiny, marginTop: 10, lineHeight: 1.2 }}>{lang.lesson_users}</p>
                                                                    </div>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Paper>
                                    )) :
                                    // ZIADNE POLOZKY                            
                                    <div style={{ ...styles.Block, paddingTop: 20 }}>
                                        <EmptyList lang={lang} />
                                    </div>
                                }

                                {itemsFiltered != false ?
                                    pageMax > 1 ?
                                        <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                            <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                        </div>
                                        : null
                                    : null}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showNotifySended == true ?
                <DialogInfo radius={props.radius} title={lang.lessons_notify} sub_title={''} text={lang.lessons_notify_sended} icon={faQuestion} button={props.lang.close} icon_show={faCheckCircle} icon_color={global.theme_darker_green} theme={props.theme} func={() => setShowNotifySended(false)} />
                : null}

            {showNotify == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.lessons_send_notify} sub_title={''} text={lang.lessons_send_notify_ask} sub_text={lang.lessons_send_notify_ask_note} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={SendNotify.bind(this)} />
                : null}

            {showLesson == true ?
                <LessonDetails preview item={itemSelected} remoteApp={props.remoteApp} user={props.user} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} func={LessonPreview.bind(this)} />
                : null}

            {showCreate == true ?
                <DialogLesson edit={edit} item={itemSelected} remoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} func={LessonResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK side={menuWidth} offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError text={lang.db_error} offset={props.offset} theme={props.theme} func={ShowErrorPress.bind(this)} />
                : null}

            {showRegistered == true ?
                <LessonRegisteredUsers user={props.user} item={itemSelected} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} func={RegisteredResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading side={menuWidth} offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper >
    );
}


/*
*
* =================================================================================================================
* NOVE SKOLENIE / UPRAVA SKOLENIA
* =================================================================================================================
*
*/
export const DialogLesson = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <DialogLesson remoteApp={props.remoteApp} user={props.user} admin={props.admin} countries={props.countries} country={props.country} lang={props.lang} language={props.language}  offset={props.offset} theme={props.theme}  radius={props.radius} isSmall={props.isSmall} func={LessonResult.bind(this)} />

            const LessonResult = (typ,value) => {
                console.log(value)
            }
    */
    const no_image = require('./react/app/no_image.jpg');

    const [isBusy, setBusy] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [datas, setDatas] = useState(false);

    const [typ, setTyp] = useState(1);
    const [lesson, setLesson] = useState(false);
    const [services, setServices] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const [radius, setRadius] = useState(height > 850 ? props.radius : 0);

    // Detail školenia
    const [date, setDate] = useState(Today());
    const [dateRegister, setDateRegister] = useState(Today());
    const [time, setTime] = useState('10:00');
    const [duration, setDuration] = useState('60');
    const [title, setTitle] = useState('');
    const [note, setNote] = useState('');
    const [online, setOnline] = useState(false);
    const [meetingID, setMeetingID] = useState('');
    const [passcode, setPasscode] = useState('');
    const [address, setAddress] = useState('');
    const [teacher, setTeacher] = useState('');
    const [teacherID, setTeacherID] = useState(0);
    const [showSelectTeacher, setShowSelectTeacher] = useState(false);

    const [lessonFor, setLessonFor] = useState([]);
    const [categoryLabel, setCategoryLabel] = useState('');
    const [category, setCategory] = useState(0);
    const [link, setLink] = useState('');
    const [capacity, setCapacity] = useState('10');
    const [capacityReserve, setCapacityReserve] = useState(0);
    const [points, setPoints] = useState('0');
    const [credits, setCredits] = useState('0');
    const [youtube, setYoutube] = useState('');
    const [youtubeFilename, setYoutubeFilename] = useState('');
    const [youtubeLink, setYoutubeLink] = useState('');
    const [sk, setSK] = useState(false);
    const [cz, setCZ] = useState(false);

    // RESERVE
    const [serviceID, setServiceID] = useState(0);

    // VYBER KATEGORIE
    const [showCategory, setShowCategory] = useState(false);

    // ZRUSENIE SKOLENIA
    const [showCancel, setShowCancel] = useState(false);

    // KOPIROVANIE UDAJOV Z INEHO SKOLENIA
    const [showCopy, setShowCopy] = useState(false);

    // VIBER UPLOADED VIDEA
    const [showChooseVideo, setShowChooseVideo] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    // FOTO
    const [photo, setPhoto] = useState(props.edit == true ? props.item.image : false);
    const [showSelectPhoto, setShowSelectPhoto] = useState(false);

    // Description
    const blocksFromHTML = convertFromHTML('');
    const state = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap,);
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(state));
    const [contentState, setContentState] = useState();

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const imageSize = 200;
    const imageRadius = 10;
    const buttonHeight = 100;

    let { func } = props;
    var lang = props.lang;
    let color = global.themes[props.theme];

    useEffect(() => {

        if (props.edit == true) {
            db_get();
        } else {
            db_services();
            setCategoryLabel(lang.select);
        }

        return () => {
        };

    }, []);

    const db_get = async () => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'lesson', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    id: props.item.id,
                    language: props.country.language_id,
                    edit: true
                })
            })

            const json = await response.json();
            Debug(json);
            if (json.ok > 0) {
                var item = json.item;
                var lesson_for = JSON.parse(item.lesson_for);
                setLesson(item);
                setSK(item.sk == false ? false : true);
                setCZ(item.cz == false ? false : true);
                setTyp(item.typ);
                setCategory(item.category);
                setLessonFor(lesson_for == false ? [] : lesson_for);
                setOnline(item.online == false ? false : true);
                setDate(item.date);
                setDateRegister(item.date_register);
                setTime(item.time);
                setDuration(item.duration);
                setAddress(item.address);
                setLink(item.link);
                setPhoto(item.image);
                setTitle(item.title);
                setMeetingID(item.meeting_id);
                setPasscode(item.passcode);
                setYoutube(item.youtube);
                setYoutubeFilename(item.youtube_filename);
                setYoutubeLink(item.youtube_link);

                let html = convertFromHTML(item.description);
                let html_state = ContentState.createFromBlockArray(html.contentBlocks, html.entityMap,);
                setEditorState(EditorState.createWithContent(html_state));

                setNote(item.note);
                setCapacity(item.capacity);
                setCapacityReserve(item.capacity_reserve);
                setTeacherID(item.teacher_id);
                setTeacher(item.teacher);
                setPoints(item.points);
                setCredits(item.credits);

                setServiceID(item.service_id);
                setServices(json.services);

                setCategoryLabel(item.category_label);
            }
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const db_services = async () => {
        setLoading(true);

        try {
            const response = await fetch(
                global.db_url + 'lesson_services', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    language: props.country.language_id,
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setServices(json.services);
            }
            setLoading(false);

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    }

    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'lesson_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setDatas(data);
                setShowOK(true);
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }




    const Press = (value, item) => {
        func(value, item);
    }

    const Save = (publish) => {
        var err = 0;
        setError('');

        if (online == false) {
            err += IsEmpty(capacity) == true ? 1 : 0;
            if (parseInt(capacity) == 0) {
                err++;
            }
        }
        err += IsEmpty(title) == true ? 1 : 0;
        if (category == 0) {
            err++;
        }
        if (sk == false && cz == false) {
            err++;
        }

        if (err == 0) {
            let html = convertToHTML(editorState.getCurrentContent());

            let data = {
                id: props.edit == true ? props.item.id : 0,
                enabled: true,
                demo: props.user.demo,
                language: props.country.language_id,
                sk: sk == true ? true : false,
                cz: cz == true ? true : false,
                typ: publish == true ? 0 : typ,
                category: category,
                lesson_for: JSON.stringify(lessonFor == '' ? false : lessonFor),
                service_id: serviceID,
                online: online,
                date: date,
                date_register: dateRegister,
                time: time,
                duration: duration,
                address: address,
                link: link,
                image: photo,
                title: title,
                description: html,
                note: note,
                capacity: capacity,
                capacity_reserve: capacityReserve,
                teacher_id: teacherID,
                teacher: teacher,
                points: points,
                credits: credits,
                meeting_id: meetingID,
                passcode: passcode,
                youtube: youtube
            }

            db_update(data);

        }
        else {
            setError(lang.error + ' - ' + lang.required);
        }
    }


    const ShowOKPress = () => {
        setShowOK(false);
        Press(true, datas);
    }


    const PhotosResult = (typ, path, file) => {
        setShowSelectPhoto(false);

        if (typ == true) {
            setPhoto(path + file);
        }
    }

    const ChangeTyp = (value) => {
        setTyp(value);
    }

    const UserSelectResult = (value, data) => {
        setShowSelectTeacher(false);
        if (value == true) {
            setTeacher(data.name + ' ' + data.surname);
            setTeacherID(data.id);
        }
    }

    const ChangeLessonFor = (value) => {
        setLessonFor(value);
    }

    const ChangeDate = (date_) => {
        setDate(date_);
        let date_new = GetDateMinusDays(date_, 1);
        setDateRegister(date_new);
    }

    const CancelLesson = (value) => {
        setShowCancel(false);
        if (value == true) {
            let data = {
                id: props.edit == true ? props.item.id : 0,
                typ: global.lesson_canceled
            }

            db_update(data);
        }
    }

    const CategoryResult = (value, item) => {
        setShowCategory(false);

        if (value == true) {
            setCategoryLabel(item.label);
            setTitle(item.label);
            setCategory(item.id);
        }
    }


    const Preview = () => {

        let html = convertToHTML(editorState.getCurrentContent());

        let data = {
            id: props.edit == true ? props.item.id : 0,
            enabled: true,
            demo: props.user.demo,
            language: props.country.language_id,
            sk: sk == true ? true : false,
            cz: cz == true ? true : false,
            typ: 0,
            category: category,
            lesson_for: JSON.stringify(lessonFor == '' ? false : lessonFor),
            online: online,
            date: date,
            date_register: dateRegister,
            time: time,
            duration: duration,
            address: address,
            link: link,
            image: photo,
            title: title,
            description: html,
            note: note,
            capacity: capacity,
            capacity_reserve: capacityReserve,
            teacher_id: teacherID,
            teacher: teacher,
            points: points,
            credits: credits,
            youtube: youtube,
            youtube_link: youtubeLink
        }
        setDatas(data);

        setShowPreview(true);
    }

    const SelectResult = (value, item) => {
        setShowCopy(false);
        Debug(item);
        if (value == true) {
            var lesson_for = JSON.parse(item.lesson_for);

            //setDate(item.date);
            //setDateRegister(item.date_register);
            //setTime(item.time);
            //setDuration(item.duration);
            //setCapacity(item.capacity);
            //setCapacityReserve(item.capacity_reserve);

            setSK(item.sk == false ? false : true);
            setCZ(item.cz == false ? false : true);
            setTyp(item.typ);
            setCategory(item.category);
            setLessonFor(item.lesson_for == false ? [] : item.lesson_for);
            setOnline(item.online == false ? false : true);

            setAddress(item.address);
            setLink(item.link);
            setPhoto(item.image);
            setTitle(item.title);

            let html = convertFromHTML(item.description);
            let html_state = ContentState.createFromBlockArray(html.contentBlocks, html.entityMap,);
            setEditorState(EditorState.createWithContent(html_state));

            setNote(item.note);
            setTeacherID(item.teacher_id);
            setTeacher(item.teacher);
            setPoints(item.points);
            setCredits(item.credits);

            setServiceID(item.service_id);
            setCategoryLabel(item.category_label);

        }
    }

    const VideoResult = (value, item) => {
        setShowChooseVideo(false);
        Debug(item);
        if (value == true) {
            setYoutube(item.id);
            setYoutubeFilename(item.filename);
            setYoutubeLink(item.link);
        }
    }

    const DeleteYoutube = () => {
        setYoutubeFilename('');
        setYoutube(0);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: radius, borderTopRightRadius: radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faBookOpen} />
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: props.isSmall ? '40%' : '50%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.edit == true ? lang.lesson_update : lang.lesson_new_title}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextDialogSubLabel, textAlign: 'left', marginTop: 4 }}>{lang.lessons}</p>
                            }
                        </div>
                    </div>

                    <div style={{ ...styles.Block, width: 220, height: global.dialog_bar_height }}>
                        {props.edit == true ?
                            <Select
                                value={typ}
                                onChange={event => ChangeTyp(event.target.value)}
                                size='small'
                                style={{ backgroundColor: global.theme_white, height: 34, width: '100%', borderRadius: 17 }}
                            >
                                <MenuItem value={0}>{lang.lesson_typ[0]}</MenuItem>
                                <MenuItem value={1}>{lang.lesson_typ[1]}</MenuItem>
                                <MenuItem value={2}>{lang.lesson_typ[2]}</MenuItem>

                            </Select>
                            : null}
                    </div>

                    {props.isSmall == true ?
                        <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                            <IconButton onClick={() => Preview()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                                <FontAwesomeIcon style={{ width: 16, color: global.theme_black }} icon={faEye} />
                            </IconButton>
                        </div>
                        :
                        <div style={{ ...styles.BlockRight, width: 180, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <Button onClick={() => Preview()} style={{ ...styles.ButtonDark }}>{lang.preview}</Button>
                        </div>
                    }

                    <div style={{ ...styles.Block, width: props.isSmall ? 70 : 80, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Press(false, false)} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_white, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, paddingBottom: props.isSmall ? 400 : 0 }}>
                        <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow }}>
                            {props.isSmall == true ? null :
                                <div style={{ ...styles.BlockCenter, width: props.isSmall ? width : imageSize + 60, height: props.isSmall ? undefined : dialogHeight - global.dialog_bar_height - 11, paddingTop: 10, paddingBottom: props.isSmall ? 30 : 0, backgroundColor: color.theme_lighteen, borderBottomLeftRadius: props.isSmall ? 0 : props.radius }}>
                                    <p style={{ ...styles.TextSmall, marginBottom: 5, fontWeight: '400' }}>{lang.photo}</p>
                                    <Paper elevation={3} style={{ maxWidth: imageSize, maxHeight: imageSize, borderRadius: imageRadius }}>
                                        <img src={photo == false || photo == null || photo == '' ? no_image : GetImageLink(photo)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain', borderRadius: imageRadius }}></img>
                                    </Paper>
                                    <div style={{ ...styles.Block, marginTop: 20 }}>
                                        {photo != false ?
                                            <Button onClick={() => setPhoto(false)} style={{ ...styles.ButtonRed, height: 30, width: 200, marginBottom: 20 }}>
                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faXmark} />
                                                {lang.photo_delete}
                                            </Button>
                                            : null}
                                        <Button onClick={() => setShowSelectPhoto(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, height: 30, width: 200 }}>{photo == false ? lang.photo_choose : lang.photo_change}</Button>
                                    </div>
                                </div>
                            }
                            <div style={{ ...styles.BlockCenter, width: props.isSmall ? '100%' : dialogWidth - imageSize - 60 }}>
                                {isLoading == false ?
                                    <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height - buttonHeight, width: props.isSmall ? '100%' : dialogWidth - imageSize - 60, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>

                                        {props.isSmall == true ?
                                            <div style={{ ...styles.BlockCenter, width: props.isSmall ? width : imageSize + 60, height: props.isSmall ? undefined : dialogHeight - global.dialog_bar_height - 11, paddingTop: 10, paddingBottom: props.isSmall ? 30 : 0, backgroundColor: color.theme_lighteen, borderBottomLeftRadius: props.isSmall ? 0 : props.radius }}>
                                                <p style={{ ...styles.TextSmall, marginBottom: 5, fontWeight: '400' }}>{lang.photo}</p>
                                                <Paper elevation={3} style={{ maxWidth: imageSize, maxHeight: imageSize, borderRadius: imageRadius }}>
                                                    <img src={photo == false || photo == null || photo == '' ? no_image : GetImageLink(photo)} style={{ width: '100%', height: '100%', maxWidth: imageSize, maxHeight: imageSize, objectFit: 'contain', borderRadius: imageRadius }}></img>
                                                </Paper>
                                                <div style={{ ...styles.Block, marginTop: 20 }}>
                                                    {photo != false ?
                                                        <Button onClick={() => setPhoto(false)} style={{ ...styles.ButtonRed, height: 30, width: 200, marginBottom: 20 }}>
                                                            <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faXmark} />
                                                            {lang.photo_delete}
                                                        </Button>
                                                        : null}
                                                    <Button onClick={() => setShowSelectPhoto(true)} style={{ ...styles.ButtonDark, backgroundColor: global.themes[props.theme].button_dark, height: 30, width: 200 }}>{photo == false ? lang.photo_choose : lang.photo_change}</Button>
                                                </div>
                                            </div>
                                            : null}

                                        {/* DATUM / CAS / KAPACITA */}
                                        <div style={{ ...styles.BlockRow, width: '94%', marginTop: 20 }}>
                                            <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, width: props.isSmall ? '100%' : '50%' }}>
                                                <div style={{ ...styles.Block, width: props.isSmall ? 180 : '40%' }}>
                                                    <TextInput type={'date'} redraw enabled={true} lang={lang} value={date} label={lang.lesson_date} func={(txt) => { ChangeDate(txt) }} />
                                                </div>
                                                <div style={{ ...styles.Block, width: props.isSmall ? 180 : '25%', marginLeft: props.isSmall ? 0 : 10, marginTop: props.isSmall ? 20 : 0 }}>
                                                    <TextInput type={'time'} redraw enabled={true} lang={lang} value={time} label={lang.lesson_time} func={(txt) => { setTime(txt) }} />
                                                </div>

                                                <div style={{ ...styles.BlockLeft, width: props.isSmall ? 180 : '35%', marginLeft: props.isSmall ? 0 : 10, marginTop: props.isSmall ? 20 : 0 }}>
                                                    <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextInput, color: global.theme_dark_gray, marginLeft: 10, textAlign: 'left', marginBottom: 5 }}>{lang.lesson_duration}</p>
                                                    <Select
                                                        value={duration}
                                                        onChange={event => setDuration(event.target.value)}
                                                        size='small'
                                                        style={{ backgroundColor: global.theme_white, height: 38, width: '100%', borderRadius: 19 }}
                                                        sx={{
                                                            '.MuiOutlinedInput-notchedOutline': {
                                                                borderColor: '#444444'
                                                            },
                                                        }}
                                                    >
                                                        <MenuItem value={30}>30 {lang.minutes}</MenuItem>
                                                        <MenuItem value={60}>1 {lang.hour}</MenuItem>
                                                        <MenuItem value={120}>2 {lang.hours}</MenuItem>
                                                        <MenuItem value={180}>3 {lang.hours}</MenuItem>
                                                        <MenuItem value={240}>4 {lang.hours}</MenuItem>
                                                        <MenuItem value={300}>5 {lang.hours_}</MenuItem>
                                                        <MenuItem value={360}>6 {lang.hours_}</MenuItem>
                                                        <MenuItem value={420}>7 {lang.hours_}</MenuItem>
                                                        <MenuItem value={480}>8 {lang.hours_}</MenuItem>
                                                    </Select>
                                                </div>
                                            </div>
                                            <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, width: props.isSmall ? '100%' : '50%', marginLeft: props.isSmall ? 0 : 10, marginTop: props.isSmall ? 20 : 0 }}>
                                                {online == false ?
                                                    <div style={{ ...styles.Block, width: props.isSmall ? 180 : '30%', marginRight: props.isSmall ? 0 : 10 }}>
                                                        <TextInput required not_zero numeric redraw enabled={true} lang={lang} value={capacity} label={lang.lesson_capacity} func={(txt) => { setCapacity(txt) }} />
                                                    </div>
                                                    : null}
                                                {online == false ?
                                                    <div style={{ ...styles.BlockLeft, width: props.isSmall ? 180 : '30%', marginRight: props.isSmall ? 0 : 10, marginTop: props.isSmall ? 20 : 0 }}>
                                                        <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextInput, color: global.theme_dark_gray, marginLeft: 10, textAlign: 'left', marginBottom: 5 }}>{lang.lesson_capacity_reserve}</p>
                                                        <Select
                                                            value={capacityReserve}
                                                            onChange={event => setCapacityReserve(event.target.value)}
                                                            size='small'
                                                            style={{ backgroundColor: global.theme_white, height: 38, width: '100%', borderRadius: 19 }}
                                                            sx={{
                                                                '.MuiOutlinedInput-notchedOutline': {
                                                                    borderColor: '#444444'
                                                                },
                                                            }}
                                                        >
                                                            <MenuItem value={0}>0</MenuItem>
                                                            <MenuItem value={1}>1</MenuItem>
                                                            <MenuItem value={2}>2</MenuItem>
                                                            <MenuItem value={3}>3</MenuItem>
                                                        </Select>
                                                    </div>
                                                    : null}
                                                <div style={{ ...styles.Block, width: props.isSmall ? 180 : '40%', marginTop: props.isSmall ? 20 : 0 }}>
                                                    <TextInput type={'date'} redraw enabled={true} lang={lang} value={dateRegister} label={lang.lesson_register_to} func={(txt) => { setDateRegister(txt) }} />
                                                </div>
                                            </div>
                                        </div>


                                        {/* TEMATICKA KATEGORIA, urcené pre ..., KRAJINA */}
                                        <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, width: '94%', marginTop: 20 }}>
                                            <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, width: props.isSmall ? '100%' : '50%' }}>
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div style={{ ...styles.Block, width: 40 }}>
                                                        <IconButton onClick={() => setShowCopy(true)} style={{ width: 34, height: 34, backgroundColor: global.theme_gray, marginTop: 24 }}>
                                                            <FontAwesomeIcon style={{ width: 12, color: global.theme_black }} icon={faSearch} />
                                                        </IconButton>
                                                    </div>

                                                    <div onClick={() => setShowCategory(true)} style={{ ...styles.BlockLeft, cursor: 'pointer', marginLeft: 10 }}>
                                                        <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextInput, color: global.theme_dark_gray, textAlign: 'left', marginBottom: 5, marginLeft: 10 }}>{lang.lesson_category_select}</p>
                                                        <div style={{ ...styles.Block, height: 36, borderRadius: 20, border: '1px solid #444444' }}>
                                                            <div style={{ ...styles.BlockRow }}>
                                                                <div style={{ ...styles.BlockLeft, justifyContent: 'center', width: '85%', height: 36, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                                    <p style={{ ...styles.TextSmall, marginLeft: 20 }}>{categoryLabel == '' ? lang.select : categoryLabel.substring(0, categoryLabel.length > 36 ? 36 : categoryLabel.length)}{categoryLabel.length > 36 ? '...' : ''}</p>
                                                                </div>
                                                                <div style={{ ...styles.Block, width: '15%', height: 36 }}>
                                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_black }} icon={faAngleDown} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, width: props.isSmall ? '100%' : '50%', marginLeft: props.isSmall ? 0 : 10 }}>
                                                <div style={{ ...styles.BlockLeft, width: props.isSmall ? '100%' : '50%', }}>
                                                    <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextInput, color: global.theme_dark_gray, textAlign: 'left', marginBottom: 5, marginLeft: 10 }}>{lang.lesson_for_}</p>
                                                    <Select
                                                        value={lessonFor}
                                                        multiple
                                                        onChange={event => ChangeLessonFor(event.target.value)}
                                                        size='small'
                                                        style={{ backgroundColor: global.theme_white, height: 38, width: '100%', borderRadius: 19 }}
                                                        MenuProps={{
                                                            PaperProps: { sx: { ".MuiList-root": { backgroundColor: '#FFFFFF', }, } },
                                                            sx: {
                                                                width: 200, "& .MuiMenuItem-root.Mui-selected": { backgroundColor: props.multiple ? color.theme_light : color.theme_lighter, color: props.multiple ? color.white : undefined, borderRadius: 2, margin: 1 },
                                                                width: 200, "& .MuiMenuItem-root.Mui-selected:hover": { backgroundColor: props.multiple ? color.theme_lighter : undefined, color: props.multiple ? color.white : undefined },
                                                                zIndex: 30000,
                                                            }
                                                        }}
                                                        sx={{ '.MuiOutlinedInput-notchedOutline': { borderColor: color.xxgray } }}
                                                    >

                                                        {lang.lesson_for.map((item, index) => (
                                                            index > 0 ?
                                                                <MenuItem key={index} value={index}>
                                                                    {item}
                                                                </MenuItem>
                                                                : null
                                                        ))}
                                                    </Select>
                                                </div>

                                                <div style={{ ...styles.BlockLeft, width: props.isSmall ? '50%' : '50%', marginLeft: props.isSmall ? 0 : 10, marginTop: props.isSmall ? 20 : 0 }}>
                                                    <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextInput, color: global.theme_dark_gray, textAlign: 'left', marginBottom: 5, marginLeft: 10 }}>{lang.country_}</p>
                                                    <div style={{ ...styles.BlockRow, border: sk == false && cz == false ? '1px solid ' + global.theme_red : '1px solid #444444', borderRadius: 20, height: 38, backgroundColor: global.theme_white }}>
                                                        <div style={{ ...styles.Block, width: '50%' }}>
                                                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{'SK'}</p>}
                                                                control={<Checkbox checked={sk} size={'small'} onChange={() => setSK(!sk)} />}
                                                            />
                                                        </div>
                                                        <div style={{ ...styles.Block, width: '50%' }}>
                                                            <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{'CZ'}</p>}
                                                                control={<Checkbox checked={cz} size={'small'} onChange={() => setCZ(!cz)} />}
                                                            />
                                                        </div>
                                                    </div>
                                                    {sk == false && cz == false ?
                                                        <p style={{ ...styles.TextXTiny, color: global.theme_red, textAlign: 'left', marginTop: 5, marginLeft: 10 }}>{lang.required}</p>
                                                        : null}
                                                </div>
                                            </div>
                                        </div>

                                        {/* NAZOV */}
                                        <div style={{ ...styles.Block, width: '94%', marginTop: 20 }}>
                                            <TextInput required redraw enabled={true} lang={lang} value={title} label={lang.lesson_title} func={(txt) => setTitle(txt)} />
                                        </div>

                                        {/* POPIS SKOLENIA */}
                                        <div style={{ ...styles.Block, width: '94%', height: props.isSmall ? 320 : 320, marginTop: 20 }}>
                                            <div style={{ ...styles.BlockLeft }}>
                                                <p style={{ ...props.isSmall ? styles.TextXTiny : styles.TextInput, color: global.theme_dark_gray, marginLeft: 12, textAlign: 'left', marginBottom: 5 }}>{lang.lesson_description}</p>
                                            </div>
                                            <Editor
                                                editorState={editorState}
                                                onEditorStateChange={setEditorState}
                                                defaultContentState={contentState}
                                                onContentStateChange={setContentState}

                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"

                                                editorStyle={{ backgroundColor: global.theme_white, height: props.isSmall ? 170 : 235, border: '1px solid ' + global.theme_light_gray }}
                                                toolbarStyle={{ backgroundColor: global.theme_gray, width: props.isSmall ? width - 30 : dialogWidth - imageSize - 60 - 80 }}

                                                toolbar={{
                                                    options: ['inline', 'blockType', 'list', 'history'],
                                                    inline: { inDropdown: false },
                                                    list: { inDropdown: false },
                                                    textAlign: { inDropdown: false },
                                                    history: { inDropdown: false },
                                                    inline: {
                                                        inDropdown: false,
                                                        options: ['bold', 'italic', 'underline'],
                                                    },
                                                    blockType: {
                                                        inDropdown: false,
                                                        options: ['Normal', 'H1', 'H2', 'H3'],
                                                    },
                                                    list: {
                                                        inDropdown: false,
                                                        options: ['unordered', 'ordered'],
                                                    },
                                                }}
                                            />
                                        </div>

                                        {/* MIESTO KONANIA / WEBINAR */}
                                        <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, width: '94%', marginTop: 20 }}>
                                            <div style={{ ...props.isSmall ? styles.Block : styles.BlockLeft, width: props.isSmall ? '96%' : '30%', paddingTop: 20 }}>
                                                <FormControlLabel label={<p style={{ ...styles.TextTiny }}>{lang.lesson_online}</p>}
                                                    control={<Checkbox checked={online} size={'small'} onChange={() => setOnline(!online)} />}
                                                />
                                            </div>
                                            <div style={{ ...styles.Block, width: props.isSmall ? '96%' : '70%', marginTop: props.isSmall ? 20 : 0 }}>
                                                {online == false ?
                                                    <TextInput enabled redraw lang={lang} value={address} label={lang.lesson_address} func={(txt) => setAddress(txt)} />
                                                    :
                                                    <div style={{ ...styles.Block }}>
                                                        <TextInput enabled redraw lang={lang} value={link} label={lang.lesson_url} func={(txt) => setLink(txt)} />
                                                        <div style={{ ...styles.BlockRow, marginTop: 20 }}>
                                                            <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                                                <TextInput enabled redraw lang={lang} value={meetingID} label={lang.lesson_meeting_id} func={(txt) => setMeetingID(txt)} />
                                                            </div>
                                                            <div style={{ ...styles.BlockLeft, width: '50%', marginLeft: 20 }}>
                                                                <TextInput enabled redraw lang={lang} value={passcode} label={lang.lesson_passcode} func={(txt) => setPasscode(txt)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        {/* SKOLITEL / POCET BODOV, KREDITOBV / KRAJINA */}
                                        <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, width: '94%', marginTop: 30 }}>
                                            <div style={{ ...styles.BlockRow, width: props.isSmall ? '96%' : '30%' }}>
                                                <div style={{ ...styles.Block, width: props.isSmall ? '90%' : '75%' }}>
                                                    <TextInput enabled={false} redraw lang={lang} value={teacher} label={lang.lesson_teacher} func={(txt) => setTeacher(txt)} />
                                                </div>
                                                <div style={{ ...styles.Block, width: 40, paddingTop: 20 }}>
                                                    <IconButton onClick={() => setShowSelectTeacher(true)} style={{ width: 34, height: 34, backgroundColor: color.theme_dark }}>
                                                        <FontAwesomeIcon style={{ width: 14, color: global.theme_white }} icon={faSearch} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                            <div style={{ ...styles.Block, width: props.isSmall ? '50%' : '20%', marginTop: props.isSmall ? 20 : 0 }}>
                                                <TextInput numeric redraw enabled={true} lang={lang} value={points} label={lang.lesson_points} func={(txt) => setPoints(txt)} />
                                            </div>
                                            <div style={{ ...styles.Block, width: props.isSmall ? '50%' : '20%', marginTop: props.isSmall ? 20 : 0, marginLeft: props.isSmall ? 0 : 10 }}>
                                                <TextInput numeric redraw enabled={true} lang={lang} value={credits} label={lang.lesson_credits} func={(txt) => setCredits(txt)} />
                                            </div>
                                        </div>

                                        {/* VIDEO */}
                                        <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, width: '94%', marginTop: 20 }}>
                                            <div style={{ ...styles.BlockRow }}>
                                                <div style={{ ...styles.Block, width: '95%' }}>
                                                    <TextInput enabled={true} redraw lang={lang} value={youtubeFilename} label={lang.video_lesson} func={(txt) => setYoutubeFilename(txt)} />
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 50, paddingTop: 20 }}>
                                                    {parseInt(youtube) > 0 ?
                                                        <IconButton onClick={() => DeleteYoutube()} style={{ width: 34, height: 34, backgroundColor: global.theme_dark_red }}>
                                                            <FontAwesomeIcon style={{ height: 14, color: global.theme_white }} icon={faXmark} />
                                                        </IconButton>
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 50, paddingTop: 20 }}>
                                                    {parseInt(youtube) > 0 ?
                                                        <IconButton onClick={() => setShowVideo(true)} style={{ width: 34, height: 34, backgroundColor: global.theme_darker_green }}>
                                                            <FontAwesomeIcon style={{ width: 14, color: global.theme_white }} icon={faEye} />
                                                        </IconButton>
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.BlockRight, width: 50, paddingTop: 20 }}>
                                                    <IconButton onClick={() => setShowChooseVideo(true)} style={{ width: 34, height: 34, backgroundColor: color.theme_dark }}>
                                                        <FontAwesomeIcon style={{ width: 14, color: global.theme_white }} icon={faSearch} />
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ minHeight: props.isSmall ? global.keyboard_padding_small : global.keyboard_padding }}>
                                        </div>
                                    </div>
                                    : null}

                                {isLoading == false ?
                                    <div style={{ ...styles.Block, height: buttonHeight, borderTop: '1px solid ' + color.theme_light }}>
                                        {error != '' ?
                                            <div style={{ ...styles.Block, marginBottom: 10 }}>
                                                <FormError error={error} margin={0} small></FormError>
                                            </div>
                                            : null}
                                        <div style={{ ...styles.BlockRowRaw }}>
                                            <Button onClick={() => Save(false)} style={{ ...styles.ButtonDark, backgroundColor: color.theme_dark }}>{lang.save}</Button>
                                            {lesson != false ? lesson.typ == global.lesson_draft ?
                                                <Button onClick={() => Save(true)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_darker_green, marginLeft: 20 }}>
                                                    {lang.lesson_publish}
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginLeft: 10 }} icon={faPaperPlane} />
                                                </Button>
                                                : null : null}
                                            {props.edit == false ?
                                                <Button onClick={() => Save(true)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_darker_green, marginLeft: 20 }}>
                                                    {lang.lesson_publish}
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginLeft: 10 }} icon={faPaperPlane} />
                                                </Button>
                                                : null}
                                            {props.edit == true ? lesson.date >= Today() ? lesson != false ? lesson.typ == global.lesson_published ?
                                                <Button onClick={() => setShowCancel(true)} style={{ ...styles.ButtonDark, backgroundColor: global.theme_dark_red, marginLeft: 20 }}>
                                                    <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faXmark} />
                                                    <p style={{ ...styles.TextTiny, color: global.theme_white }}>{lang.lesson_cancel}</p>
                                                </Button>
                                                : null : null : null : null}
                                        </div>
                                    </div>
                                    : null}

                            </div>
                        </div>
                    </div>

                </div>

            </Paper>

            {showVideo == true ?
                <DialogVideo theme={props.theme} file={global.web_uploads + lesson.youtube_link} radius={props.radius} lang={props.lang} title={lesson.title} sub_title={'Video'} background={global.theme_dark_gray} crop={false} func={() => setShowVideo(false)} />
                : null}

            {showCategory == true ?
                <LessonsCategories select isSmall={props.isSmall} offset={props.offset} theme={props.theme} countries={props.countries} country={props.country} user={props.user} lang={lang} language={props.language} radius={props.radius} backcolor={true} background={true} func={CategoryResult.bind(this)} />
                : null}

            {showCopy == true ?
                <LessonSelect isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} lang={lang} language={props.language} radius={props.radius} backcolor={true} background={true} country={props.country} func={SelectResult.bind(this)} />
                : null}

            {showChooseVideo == true ?
                <SelectVideo isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} lang={lang} language={props.language} radius={props.radius} backcolor={true} background={true} country={props.country} func={VideoResult.bind(this)} />
                : null}

            {showPreview ?
                <LessonDetails item={datas} preview={true} data={datas} remoteApp={props.remoteApp} user={props.user} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} func={() => setShowPreview(false)} />
                : null}

            {showCancel == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.lesson_cancel_label} sub_title={''} text={lang.lesson_cancel_ask} sub_text={lang.lesson_cancel_text} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={CancelLesson.bind(this)} />
                : null}

            {showSelectTeacher == true ?
                <UserSelect isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} countries={props.countries} country={props.country} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} title={lang.lesson_teacher_search} sub_title={lang.vision_system} func={UserSelectResult.bind(this)} />
                : null}

            {showSelectPhoto == true ?
                <Photos lang={lang} typ={7} max_size={600} width={600} height={600} full={false} title={lang.photo_select} sub_title={lang.vision_system} radius={props.radius} offset={props.offset} theme={props.theme} func={PhotosResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {isBusy == true || isLoading == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop >
    );
}



/*
*
* =================================================================================================================
* ZOZNAM PRIHLASENYCH ZAKAZNIKOV NA SKOLENIE
* =================================================================================================================
*
*/
export const LessonRegisteredUsers = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <LessonRegisteredUsers item={itemSelected} theme={props.theme} lang={lang} radius={props.radius} title={'nazov'} sub_title={'podnázov'} func={RegisteredResult.bind(this)} />
 
            const DialogResult = (typ,value) => {
                console.log(value)
            }
    */

    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);

    // Zoznam
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;
    const [search, setSearch] = useState('');
    const [personEdit, setPersonEdit] = useState(false);

    const [lesson, setLesson] = useState(false);
    const [count, setCount] = useState(0);
    const [finished, setFinished] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [showSelectSalon, setShowSelectSalon] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1000 ? 1000 : width;
    const dialogHeight = height >= 750 ? 750 : height;

    const rowheight = props.isSmall ? 146 : 86;

    let { func } = props;
    var lang = props.lang;
    var running = false;
    let color = global.themes[props.theme];

    useEffect(() => {
        if (running == false) {
            db_get();

            running = true;
        }


        return () => {
        };

    }, []);


    const db_get = async () => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'lesson', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    admin: true,
                    id: props.item.id
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                // UKONCENE SKOLENIE ?
                var finish = false;
                var time = GetMinutesFromHours(json.item.time) + parseInt(json.item.duration);
                time = GetHours(time);
                var date = json.item.date.toString().replaceAll('-', '/') + ' ' + time;
                date = new Date(date).getTime();
                let today = new Date().getTime();

                if (date <= today) {
                    finish = true;
                    setFinished(true);
                }

                var tmp = json.users;

                if (tmp != false) {
                    if (finish == true) {
                        tmp.sort((a, b) => {
                            if (a.id > b.id) {
                                return 1;
                            } else {
                                return -1;
                            }
                        })
                    }
                    setItems(tmp);
                    setItemsFiltered(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

                setLesson(json.item);
                setCount(json.count);

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }


    const db_update = async (data) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'lesson_user_update', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    data: data
                })
            })

            const json = await response.json();

            if (json.ok > 0) {
                setRedraw(true);
                db_get();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = () => {
        func(redraw);
    }


    const Close = () => {
        Press();
    }

    const DeleteUser = (item) => {
        if (finished == true) {
            EjectUser(item);
        } else {
            setItemSelected(item);
            setShowDelete(true);
        }
    }

    const DeleteResult = (value) => {
        setShowDelete(false);

        if (value == true) {
            var item = itemSelected;
            let data = {};
            if (finished == true) {
                data = {
                    id: item.id,
                    enabled: true,
                    status: global.lesson_user_not_absolved
                }
            } else {
                data = {
                    id: item.id,
                    enabled: false
                }
            }

            db_update(data);
        }
    }

    const AcceptUser = (item) => {
        let data = {
            id: item.id,
            enabled: true,
            status: global.lesson_user_registered
        }
        db_update(data);
    }

    const EjectUser = (item) => {
        let data = {
            id: item.id,
            enabled: true,
            status: global.lesson_user_not_absolved
        }
        db_update(data);
    }

    const AddUser = (value, user) => {
        setShowSelectSalon(false);
        if (value == true) {
            let data = {
                id: 0,
                enabled: true,
                lesson_id: lesson.id,
                user_id: user.id,
                status: 0
            }

            db_update(data);
        }
    }

    const PlusMinusResult = (value, item) => {
        let data = {
            id: item.id,
            updated: item.updated,
            persons: value
        }
        db_update(data);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA' }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    {props.isSmall ? null :
                        <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                            <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                                <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                            </div>
                        </div>
                    }
                    <div style={{ ...styles.Block, width: '80%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{lang.lesson_users_list}</p>
                            {props.isSmall ? null :
                                <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.lesson}</p>
                            }
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 180, height: global.dialog_bar_height }}>
                        <Button onClick={() => setShowSelectSalon(true)} style={{ ...styles.ButtonDark, width: 140 }}>{lang.insert}</Button>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, paddingBottom: 50, height: dialogHeight - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id_start'} style={{ ...styles.Block, backgroundColor: color.theme_lighteen, marginBottom: 10 }}>
                            {lesson != false ?
                                <div style={{ ...styles.Block, paddingTop: 10, paddingBottom: 10 }}>
                                    <p style={{ ...styles.TextLarge, fontWeight: '600' }}>{lesson.title}</p>
                                    {finished == true ?
                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.lesson_finished}</p>
                                        :
                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{GetDatum2(lesson.date)}</p>
                                    }
                                    {lesson.online == false ?
                                        <div style={{ ...styles.Block, marginTop: 10 }}>
                                            {finished == false ?
                                                <p style={{ ...styles.TextTiny }}>{lang.lesson_capacity}: {lesson.capacity} + {lesson.capacity_reserve}</p>
                                                : null}
                                            <p style={{ ...styles.TextTiny }}>{finished == true ? lang.lesson_absolved_count : lang.lesson_registered}: {count}</p>
                                        </div>
                                        : null}
                                </div>
                                : null}
                        </div>
                        <div style={{ ...styles.Block, width: '96%' }}>
                            {/* BODY */}
                            {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                item.user != false ?
                                    props.isSmall ?
                                        <Paper elevation={1} key={item.id} style={{ ...styles.BlockRow, minHeight: rowheight, marginTop: 5, marginBottom: 5, paddingTop: 10, paddingBottom: 10, backgroundColor: item.in_capacity ? global.theme_white : global.theme_lighteen_red, borderRadius: 20 }}>
                                            <div style={{ ...styles.Block, width: '96%' }}>
                                                <div style={{ ...styles.Block, width: '90%', height: undefined, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{item.user.name} {item.user.surname}</p>
                                                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, marginTop: 6 }}>
                                                        <div style={{ ...props.isSmall ? styles.Block : styles.BlockLeft, width: props.isSmall ? '100%' : '60%' }}>
                                                            <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.mobil.toLowerCase()}: {item.user.mobil}</p>
                                                            <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.email_}: {item.user.email}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.Block, width: 120, height: undefined, marginTop: 5 }}>
                                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{item.in_capacity ? '' : lang.lesson_user_free}</p>
                                                </div>
                                                <div style={{ ...styles.Block, width: 160, height: undefined, marginRight: props.isSmall ? 0 : 20, marginTop: 10 }}>
                                                    {item.status == global.lesson_user_not_absolved ?
                                                        <div style={{ ...styles.Block, height: 30, borderRadius: 15, backgroundColor: global.theme_white, border: '1px solid ' + global.theme_dark_red }}>
                                                            <p style={{ ...styles.TextTiny, color: global.theme_black }}>{lang.lesson_not_absolved}</p>
                                                        </div>
                                                        : null}
                                                    {item.status == global.lesson_user_registered ?
                                                        <div style={{ ...styles.Block, height: 30, borderRadius: 15, backgroundColor: global.theme_white, border: '1px solid ' + global.theme_darker_green }}>
                                                            <p style={{ ...styles.TextTiny, color: global.theme_black }}>{finished == true ? lang.lesson_absolved : lang.lesson_registered_user_}</p>
                                                        </div>
                                                        : null}
                                                </div>
                                                {item.status == global.lesson_user_registered ?
                                                    <div style={{ ...styles.Block, width: '90%', marginTop: 20, paddingTop: 10, paddingBottom: 10, backgroundColor: item.in_capacity ? color.theme_lighteen : undefined, borderRadius: 10 }}>
                                                        <p style={{ ...styles.TextXTiny, marginBottom: 5 }}>{lang.lesson_count_persons}</p>
                                                        <PlusMinus min={1} max={100} value={parseInt(item.persons)} data={item} theme={props.theme} func={PlusMinusResult.bind(this)} />
                                                    </div>
                                                    : null}

                                                {finished == true ?
                                                    <div style={{ ...styles.Block, marginTop: 20 }}>
                                                        <p style={{ ...styles.TextXTiny, marginBottom: 5, color: global.theme_dark_gray }}>{lang.lesson_change_status}</p>
                                                        {item.status == global.lesson_user_not_absolved ?
                                                            <Tooltip title={lang.lesson_change_status_yes}>
                                                                <IconButton onClick={() => AcceptUser(item)} style={{ width: 34, height: 34, backgroundColor: '#00000000' }}>
                                                                    <FontAwesomeIcon style={{ height: 24, color: global.theme_dark_red }} icon={faToggleOff} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            :
                                                            <Tooltip title={lang.lesson_change_status_no}>
                                                                <IconButton onClick={() => DeleteUser(item)} style={{ width: 34, height: 34, backgroundColor: '#00000000' }}>
                                                                    <FontAwesomeIcon style={{ height: 24, color: global.theme_darker_green }} icon={faToggleOn} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ ...styles.Block, marginTop: 20 }}>
                                                        <Tooltip title={lang.lesson_unregistered_label}>
                                                            <IconButton onClick={() => DeleteUser(item)} style={{ width: 34, height: 34, backgroundColor: '#00000000', border: '1px solid #000000' }}>
                                                                <FontAwesomeIcon style={{ height: 24, color: global.theme_dark_red }} icon={faXmark} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                }
                                            </div>
                                        </Paper>
                                        :
                                        <Paper elevation={1} key={item.id} style={{ ...styles.Block, height: rowheight, marginTop: 5, marginBottom: 5, backgroundColor: finished ? global.theme_white : item.in_capacity ? global.theme_white : global.theme_lighteen_red, borderRadius: 10 }}>
                                            <div style={{ ...styles.BlockRow, width: '98%' }}>
                                                <div style={{ ...styles.BlockLeft, width: '55%', height: rowheight, justifyContent: 'center' }}>
                                                    <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{item.user.name} {item.user.surname}</p>
                                                    <div style={{ ...styles.BlockRow, marginTop: 6 }}>
                                                        <div style={{ ...styles.BlockLeft, width: '60%' }}>
                                                            <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.mobil.toLowerCase()}: {item.user.mobil}</p>
                                                            <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{lang.email_}: {item.user.email}</p>
                                                        </div>
                                                        <div style={{ ...styles.BlockLeft, width: '40%' }}>
                                                            <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.user.salon_name}</p>
                                                            <p style={{ ...styles.TextTiny, textAlign: 'left' }}>{item.user.salon_town}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style={{ ...styles.Block, width: 160, height: rowheight, marginRight: 20 }}>
                                                    {finished == true ? null :
                                                        <p style={{ ...styles.TextTiny, fontWeight: '600' }}>{item.in_capacity ? '' : lang.lesson_user_free}</p>
                                                    }
                                                    {item.status == global.lesson_user_not_absolved ?
                                                        <div style={{ ...styles.Block, height: 30, borderRadius: 15, backgroundColor: global.theme_white, border: '1px solid ' + global.theme_dark_red }}>
                                                            <p style={{ ...styles.TextTiny, color: global.theme_black }}>{lang.lesson_not_absolved}</p>
                                                        </div>
                                                        : null}
                                                    {item.status == global.lesson_user_registered ?
                                                        <div style={{ ...styles.Block, height: 30, borderRadius: 15, backgroundColor: global.theme_white, border: '1px solid ' + global.theme_darker_green }}>
                                                            <p style={{ ...styles.TextTiny, color: global.theme_black }}>{finished == true ? lang.lesson_absolved : lang.lesson_registered_user_}</p>
                                                        </div>
                                                        : null}
                                                </div>
                                                <div style={{ ...styles.Block, width: 200, height: rowheight }}>
                                                    {item.status == global.lesson_user_registered ?
                                                        <div style={{ ...styles.Block, width: '90%', backgroundColor: item.in_capacity ? color.theme_lighteen : undefined, height: rowheight - 8, borderRadius: 10 }}>
                                                            <p style={{ ...styles.TextXTiny, marginBottom: 5 }}>{lang.lesson_count_persons}</p>
                                                            <PlusMinus min={1} max={100} value={parseInt(item.persons)} data={item} theme={props.theme} func={PlusMinusResult.bind(this)} />
                                                        </div>
                                                        : null}
                                                </div>
                                                {finished == true ?
                                                    <div style={{ ...styles.Block, width: 100, height: rowheight }}>
                                                        <p style={{ ...styles.TextXTiny, marginBottom: 5, color: global.theme_dark_gray }}>{lang.lesson_change_status}</p>
                                                        {item.status == global.lesson_user_not_absolved ?
                                                            <Tooltip title={lang.lesson_change_status_yes}>
                                                                <IconButton onClick={() => AcceptUser(item)} style={{ width: 34, height: 34, backgroundColor: '#00000000' }}>
                                                                    <FontAwesomeIcon style={{ height: 24, color: global.theme_dark_red }} icon={faToggleOff} />
                                                                </IconButton>
                                                            </Tooltip>
                                                            :
                                                            <Tooltip title={lang.lesson_change_status_no}>
                                                                <IconButton onClick={() => DeleteUser(item)} style={{ width: 34, height: 34, backgroundColor: '#00000000' }}>
                                                                    <FontAwesomeIcon style={{ height: 24, color: global.theme_darker_green }} icon={faToggleOn} />
                                                                </IconButton>
                                                            </Tooltip>
                                                        }
                                                    </div>
                                                    :
                                                    <div style={{ ...styles.Block, width: 100, height: rowheight }}>
                                                        <Tooltip title={lang.lesson_unregistered_label}>
                                                            <IconButton onClick={() => DeleteUser(item)} style={{ width: 34, height: 34, backgroundColor: '#00000000', border: '1px solid #000000' }}>
                                                                <FontAwesomeIcon style={{ height: 16, color: global.theme_dark_red }} icon={faXmark} />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                }
                                            </div>
                                        </Paper>
                                    : null
                            )) : null}
                        </div>
                    </div>
                </div>
            </Paper>

            {showSelectSalon == true ?
                <UserSelect isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} user_id={props.user.id} remoteApp={props.remoteApp} admin={props.admin} countries={props.countries} country={props.country} lang={lang} radius={props.radius} backcolor={true} background={true} language={props.language} title={lang.lesson_add_user} sub_title={lang.vision_system} func={AddUser.bind(this)} />
                : null}

            {showDelete == true ?
                <DialogYesNo theme={props.theme} radius={props.radius} title={lang.lesson_delete_label} sub_title={lang.lesson} text={lang.lesson_delete_ask} sub_text={finished == true ? lang.lesson_delete_text : ''} warning_text={''} icon={faQuestion} yes={props.lang.yes} no={props.lang.no} yes_color={global.theme_dark_red} no_color={global.themes[props.theme].button_dark} func={DeleteResult.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Backdrop>
    );
}




export const LessonSelect = (props) => {
    /*
            OKNO - full screen
            
            USAGE:
            <LessonSelect isSmall={props.isSmall} offset={props.offset} theme={props.theme} user={props.user} lang={lang} language={props.language} radius={props.radius} backcolor={true} background={true} country={props.country} func={SelectResult.bind(this)} />

            const SelectResult = (typ,value) => {
                console.log(value)
            }
    */
    const no_image = require('./react/app/no_image.jpg');
    const flag_sk = require('./react/app/flag_sk.png');
    const flag_cz = require('./react/app/flag_cz.png');

    const [isBusy, setBusy] = useState(false);
    const [error, setError] = useState(false);
    const [overID, setOverID] = useState(-1);

    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const [search, setSearch] = useState('');
    const itemsMax = 24;

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 1200 ? 1200 : width;
    const dialogHeight = height >= 850 ? 850 : height;

    const rowOffset = 20;
    const errorHeight = 40;
    const buttonHeight = 80;
    const menuWidth = props.isSmall ? 0 : 250;
    const rowHeight = props.isSmall ? 160 : 120;
    const rowWidth = 850;
    const selectHeight = props.isSmall ? 40 : 0;

    let { func } = props;
    var lang = props.lang;

    useEffect(() => {
        db_get();

        return () => {
        };

    }, []);

    const db_get = async () => {
        setBusy(true);

        setItems(false);
        setItemsFiltered(false);
        try {
            const response = await fetch(
                global.db_url + 'lessons', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    language: props.country.language_id,
                    typ: 0,
                    admin: true,
                    all: true
                })
            })

            const json = await response.json();
            if (json.ok > 0) {

                var tmp = json.items;

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }


    const Search = (text) => {
        setSearch(text);
        Redraw(items, 0, text);
    }

    const Redraw = (items, p, search) => {
        // REDRAW PRODUKTOV
        var data = items;

        if (search.trim() != '') {
            // filtrovanie podla hladaneho retazca
            var words = search.split(' ');
            data = data.filter(x => FindWords(x.title, GetDate(x.date), x.teacher, words));
        }

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    function FindWords(label, date, salon, words) {
        // hladanie retazca v nazve a keywords        
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || salon.toLowerCase().includes(word.toLowerCase()) || date.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }
        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '');
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id-start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }


    const SelectItem = (item) => {
        func(true, item);
    }

    return (

        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: props.backcolor == false ? '#00000000' : '#000000AA', zIndex: 20000 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: global.themes[props.theme].theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: '90%', height: global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                            <p style={{ ...styles.TextDialogLabel }}>{lang.lesson_choose}</p>
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.lesson_choose_text}</p>
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                <div style={{ ...styles.BlockCenter, height: dialogHeight - global.dialog_bar_height, backgroundColor: props.background == true ? global.theme_white : undefined, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                    <div style={{ ...styles.BlockCenter, width: '95%', height: dialogHeight - global.dialog_bar_height, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id-start'} style={{ ...styles.Block }}></div>
                        {/* BODY */}
                        {itemsFiltered != false ?
                            itemsFiltered.map((item, index) => (
                                <Paper elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.BlockRow }}>
                                        {props.isSmall ?
                                            <div onClick={() => SelectItem(item)} style={{ ...styles.BlockCenter, height: rowHeight, width: 80, justifyContent: 'center' }}>
                                                <img src={item.image == false || item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: 60, maxHeight: 60, objectFit: 'contain', borderRadius: 10 }}></img>
                                                <div style={{ ...styles.BlockRow, width: 50, height: rowHeight, marginTop: 10 }}>
                                                    {item.sk == true ?
                                                        <img src={flag_sk} style={{ width: '100%', height: '100%', maxWidth: 25, maxHeight: 25, objectFit: 'contain' }}></img>
                                                        : null}
                                                    {item.cz == true ?
                                                        <img src={flag_cz} style={{ width: '100%', height: '100%', maxWidth: 25, maxHeight: 25, objectFit: 'contain', marginLeft: 5 }}></img>
                                                        : null}
                                                </div>
                                            </div>
                                            :
                                            <div onClick={() => SelectItem(item)} style={{ ...styles.Block, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                <img src={item.image == false || item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: rowHeight, maxHeight: rowHeight, objectFit: 'contain', borderRadius: global.row_radius }}></img>
                                            </div>
                                        }
                                        {props.isSmall ?
                                            <div style={{ ...styles.BlockLeft, minHeight: rowHeight, width: '72%', justifyContent: 'center', marginLeft: 10 }}>
                                                <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft }}>
                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.title}</p>
                                                    <p style={{ ...styles.TextTiny, marginTop: 5 }}>{GetDate(item.date)} {GetTimeHM(item.time)} {lang.hour_short}</p>
                                                    {item.online == true ?
                                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.lesson_registered}: {item.count}</p>
                                                        :
                                                        <p style={{ ...styles.TextTiny, marginTop: 5 }}>{lang.lesson_registered}: {item.count}/{item.capacity}{parseInt(item.capacity_reserve) > 0 ? '+' + item.capacity_reserve : ''}</p>
                                                    }
                                                    <p style={{ ...styles.TextTiny, textAlign: 'left', marginRight: 20 }}>{lang.lesson_teacher}: {item.teacher}</p>
                                                </div>

                                            </div>
                                            :
                                            <div style={{ ...styles.BlockLeft, height: rowHeight, width: '87%', justifyContent: 'center', marginLeft: 10 }}>
                                                <div style={{ ...styles.BlockRow }}>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.BlockLeft, width: '80%', height: rowHeight, justifyContent: 'center' }}>
                                                        <div style={{ ...styles.BlockRowRaw }}>
                                                            {item.online == true ?
                                                                <div style={{ ...styles.Block, width: 80, backgroundColor: global.theme_dark_gray, height: 20, borderRadius: 10, marginRight: 10 }}>
                                                                    <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.lesson_online_} </p>
                                                                </div>
                                                                : null}
                                                            <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.title}</p>
                                                        </div>
                                                        <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                            <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                                                <p style={{ ...styles.TextTiny }}>{GetDate(item.date)} {GetTimeHM(item.time)} {lang.hour_short}</p>
                                                                <p style={{ ...styles.TextTiny }}>{lang.lesson_duration}: {GetHoursFromMinutes(item.duration, lang)}</p>
                                                            </div>
                                                            <div style={{ ...styles.BlockRight, width: '50%', marginRight: 15 }}>
                                                                {item.online == true ?
                                                                    <p style={{ ...styles.TextTiny }}>{lang.lesson_registered}: {item.count}</p>
                                                                    :
                                                                    <p style={{ ...styles.TextTiny }}>{lang.lesson_registered}: {item.count}/{item.capacity}{parseInt(item.capacity_reserve) > 0 ? '+' + item.capacity_reserve : ''}</p>
                                                                }
                                                                <p style={{ ...styles.TextTiny }}>{lang.lesson_teacher}: {item.teacher}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div onClick={() => SelectItem(item)} style={{ ...styles.Block, width: 50, height: rowHeight }}>
                                                        {item.sk == true ?
                                                            <img src={flag_sk} style={{ width: '100%', height: '100%', maxWidth: 30, maxHeight: 30, objectFit: 'contain', marginTop: 5, marginBottom: 5 }}></img>
                                                            : null}
                                                        {item.cz == true ?
                                                            <img src={flag_cz} style={{ width: '100%', height: '100%', maxWidth: 30, maxHeight: 30, objectFit: 'contain', marginTop: 5, marginBottom: 5 }}></img>
                                                            : null}
                                                    </div>

                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Paper>
                            )) :
                            // ZIADNE POLOZKY                            
                            <div style={{ ...styles.Block, paddingTop: 20 }}>
                                <EmptyList lang={lang} />
                            </div>
                        }
                    </div>
                </div>
            </Paper>
        </Backdrop>
    );
}


export const SelectVideo = (props) => {

    var lang = props.lang;
    let radius = props.radius;
    let color = global.themes[props.theme];
    let permissions = global.permissions;

    let defaultFilter = {
        enabled: true,
    }

    // IMAGES
    const no_image = require('./react/app/no_image.jpg');

    // SYSTEM
    const [isBusy, setBusy] = useState(false);
    const [redraw, setRedraw] = useState(false);
    const [error, setError] = useState('');

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DATA LIST
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState([]);
    const [itemEditing, setItemEditing] = useState(false);
    const [page, setPage] = useState(0);
    const [pages, setPages] = useState(0);
    const [maxItems, setMaxItems] = useState(20);
    const [filter, setFilter] = useState(defaultFilter);
    const [overID, setOverID] = useState(-1);
    const [search, setSearch] = useState('');

    // DATA
    const [value, setValue] = useState(false);

    // SCREEN SETTINGS
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const dialogWidth = width >= 700 ? 700 : width;
    const dialogHeight = height >= 700 ? 700 : height;

    const menuHeight = 60;
    const rowHeight = 66;
    const viewWidth = '96%';

    let { func } = props;
    var running = false;

    useEffect(() => {
        if (running == false) {

            // UDAJE Z DB
            db_get(0, defaultFilter);

            running = true;
        }

    }, []);

    const db_get = async (page, filter_) => {
        setBusy(true);
        Debug(filter_);
        try {
            const response = await fetch(
                global.db_url + 'videos', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    // -------------------------------------

                    start: page * maxItems,
                    length: maxItems,
                    filter: filter_
                })
            })

            const json = await response.json();
            Debug('VIDEOS');
            Debug(json);
            if (json.ok > 0) {
                var tmp = json.items;
                setItems(tmp);
                setItemsFiltered(tmp);

                setPage(page);
                setPages(GetPages(json.count, maxItems));

                setFilter(filter_);
                GoToStart();
            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const Search = (txt) => {
        setSearch(txt);

        var filter = defaultFilter;

        if (txt.length > 1) {
            filter.search = txt;
            db_get(0, filter);
        }
        if (txt == '') {
            db_get(0, filter);
        }

    }

    const ChangePage = (value) => {
        db_get(value - 1, filter);
    }

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id-video-start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    const SelectItem = (item) => {
        func(true, item)
    }

    return (
        <Backdrop open={true} style={{ paddingLeft: width > global.max_screen ? global.menu_width : 0, backgroundColor: '#000000AA', zIndex: 500 }}>
            <Paper elevation={global.menu_elevation} style={{ width: dialogWidth, height: dialogHeight, background: color.theme_dialog_background, borderRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: color.window_bar_color }}>
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: color.icon_background, borderRadius: 10 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={props.icon != undefined ? props.icon : faList} />
                        </div>
                    </div>
                    <div style={{ ...styles.Block, width: dialogWidth - 120, height: global.dialog_bar_height, alignItems: 'flex-start' }}>
                        <p style={{ ...styles.TextDialogLabel }}>{lang.video_select}</p>
                        <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{lang.video_lesson}</p>
                    </div>
                    <div style={{ ...styles.Block, width: 60, height: global.dialog_bar_height }}>
                        <IconButton onClick={() => Close()} style={{ width: 34, height: 34, backgroundColor: global.theme_white }}>
                            <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                        </IconButton>
                    </div>
                </div>
                {/* BODY */}
                <div style={{ ...styles.BlockCenter, backgroundColor: global.theme_back, height: dialogHeight - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>

                    <div style={{ ...styles.BlockRow, width: viewWidth }}>
                        <div style={{ ...styles.BlockLeft, width: '50%', height: menuHeight }}>
                            <TextInput search theme={props.theme} enabled={true} lang={lang} value={search} label={''} placeholder={lang.search} func={(txt) => Search(txt)} />
                        </div>
                        <div style={{ ...styles.BlockRight, width: '50%', height: menuHeight }}>
                        </div>
                    </div>

                    <div style={{ ...styles.BlockCenter, width: dialogWidth, height: dialogHeight - global.dialog_bar_height - menuHeight, backgroundColor: color.back, paddingTop: 20, paddingBottom: 50, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, msOverflowStyle: 'none', scrollbarWidth: 'none', overflowY: 'scroll' }} className='container'>
                        <div id={'id-video-start'} style={{ ...styles.BlockLeft, width: viewWidth }}>

                            {itemsFiltered != false ? itemsFiltered.map((item, index) => (
                                <Paper
                                    key={item.id}
                                    elevation={item.id == overID ? 4 : 1}
                                    onClick={() => SelectItem(item)}
                                    style={{
                                        ...styles.Block,
                                        backgroundColor: item.enabled == false ? color.disabled : color.white,
                                        borderRadius: rowHeight / 2,
                                        minHeight: rowHeight,
                                        cursor: 'pointer',
                                        marginTop: 5, marginBottom: 5,
                                        paddingTop: 2, paddingBottom: 2,
                                        borderLeft: item.enabled == false ? color.disabled_border : '',
                                    }}
                                    onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                >
                                    <div style={{ ...styles.Block, width: '92%' }}>
                                        <div style={{ ...styles.BlockLeft }}>
                                            <p style={{ ...styles.TextXSmall, fontWeight: '600' }}>{item.filename}</p>
                                            <div style={{ ...styles.BlockLeft, maxHeight: 20, overflow: 'hidden', whiteSpace: 'nowrap' }}>
                                                <p style={{ ...styles.TextTiny, color: color.dark_gray }}>{item.note}</p>
                                            </div>
                                        </div>
                                    </div>
                                </Paper>
                            )) :
                                <EmptyList row small lang={lang} />
                            }

                            {/* PAGINATION */}
                            {pages > 1 ?
                                <div style={{ ...styles.Block, paddingTop: 10, marginTop: 20, paddingBottom: 20, borderTop: '1px solid ' + global.theme_light_gray }}>
                                    <Pagination count={pages} size={'medium'} page={parseInt(page) + 1} onChange={(event, value) => ChangePage(value)} showFirstButton showLastButton />
                                </div>
                                : null}

                        </div >

                    </div>
                </div >

                {showOK == true ?
                    <ShowOK theme={props.theme} func={() => setShowOK(false)} />
                    : null}

                {showError == true ?
                    <ShowError theme={props.theme} text={lang.db_error} sub_text={lang.db_error_text} func={() => setShowError(false)} />
                    : null}

                {isBusy == true ?
                    <Loading offset={props.offset}></Loading>
                    : null}

            </Paper >
        </Backdrop>
    );
}


