import React, { useEffect, useState, useRef } from 'react';

import './App.css';
import './items.css';
import { Routes, Route, Link, useParams, Navigate, useNavigate } from "react-router-dom";
import { sk } from './language.js';
import { CategorySelect, Colors, ConvertToPrice, CopyData, Debug, DialogEnterNumber, DialogEnterText, DialogImagePreview, DialogInfo, DialogLogin, DialogYesNo, EmployeeSelect, EmptyList, FormOK, GetDate, GetDate3, GetDatum, GetDatum2, GetHoursFromMinutes, GetImageLink, GetMinutesFromHours, GetMoney, GetPrice, GetTimeHM, Init, Loading, Menu, MenuTitle, MenuTop, PlusMinus, SelectYear, ShowError, ShowOK, TemplateWindow, TextInput, Today } from './items';
import { styles } from './styles';
import { Backdrop, Button, Checkbox, CircularProgress, FormControlLabel, Hidden, IconButton, InputAdornment, MenuItem, Pagination, Paper, Select, TextField, useMediaQuery } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faA, faAngleDown, faAngleLeft, faAngleRight, faAngleUp, faBook, faBookOpen, faCheck, faCheckCircle, faComment, faEdit, faEye, faEyeSlash, faIdCard, faImage, faInfo, faInfoCircle, faL, faLayerGroup, faList, faListAlt, faListCheck, faListOl, faPaperPlane, faPeace, faPen, faPlusCircle, faQuestion, faQuestionCircle, faSave, faSearch, faShare, faShoppingBag, faShoppingBasket, faShoppingCart, faTrash, faUser, faWarning, faXmark, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { ClientCard } from './client_card';
import { EditorHtml } from './Editor';
import { ProductDialog, ProductPreview, ShoppingCard } from './product_dialog';
import { ClientSelect } from './client_select';
import { centerCrop } from 'react-image-crop';
import { ProductBookingCard } from './products_booking';
import { Photos } from './photos';
import { ProductSelect } from './product_select';
import { SalonCard } from './salon_card';
import { SalonSelect } from './salon_select';

import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ContentState, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert';
import { UserSelect } from './user_select';
import { LessonDetails } from './lessons';

export const LessonsAbsolved = (props) => {
    const [isBusy, setBusy] = useState(false);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [overID, setOverID] = useState(-1);
    const [actualYear, setActualYear] = useState(new Date().getFullYear());

    // PREDAJ - ZOZNAM
    const [items, setItems] = useState(false);
    const [itemsFiltered, setItemsFiltered] = useState(false);
    const [itemSelected, setItemSelected] = useState(false);
    const [page, setPage] = useState(0);
    const [pageMax, setPageMax] = useState(0);
    const itemsMax = 24;

    // NOVY PRISPEVOK
    const [showCreate, setShowCreate] = useState(false);
    const [edit, setEdit] = useState(false);

    const [showOK, setShowOK] = useState(false);
    const [showError, setShowError] = useState(false);

    // DETAIL SKOLENIA
    const [showLesson, setShowLesson] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    const rowHeight = props.isSmall ? 160 : 100;
    const rowWidth = 850;
    const selectHeight = props.isSmall ? 40 : 0;

    const no_image = require('./react/app/no_image.jpg');

    // Aktuálna farebna téma
    const color = global.themes[props.theme];

    let { func } = props;
    var lang = props.lang;

    var running = false;

    useEffect(() => {
        if (running == false) {
            running = true;

            let year = new Date().getFullYear();
            db_get(year);
        }

    }, []);

    const db_get = async (year) => {
        setBusy(true);
        try {
            const response = await fetch(
                global.db_url + 'lessons_absolved', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    user_id: props.user.id,
                    language: props.user.language,
                    year: year
                })
            })

            const json = await response.json();
            
            if (json.ok > 0) {
                var tmp = json.items;

                if (tmp != false) {
                    setItems(tmp);
                    PageInit(tmp);
                } else {
                    setItems(false);
                    setItemsFiltered(false);
                }

            }
            setBusy(false);

        } catch (error) {
            console.log(error);
            setBusy(false);
        }
    }

    const Press = (typ, value) => {
        func(typ, value);
    }

    const Close = () => {
        Press(0, 0);
    }

    const ShowOKPress = (value) => {
        setShowOK(false);
    }

    const ShowErrorPress = (value) => {
        setShowError(false);
    }

    const Search = (text) => {
        setSearch(text);
        Redraw(items, 0, text);
    }

    const Redraw = (items, p, search) => {
        // REDRAW PRODUKTOV
        var data = items;

        if (search.trim() != '') {
            // filtrovanie podla hladaneho retazca
            var words = search.split(' ');
            data = data.filter(x => FindWords(x.title, GetDate(x.date), x.teacher, words));
        }

        var count = data.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        data = data.slice(itemsMax * p, (itemsMax * p) + itemsMax)
        setItemsFiltered(data);

        if (count == 0) {
            setItemsFiltered(false);
        }

        setPage(p);
        GoToStart();
    }

    function FindWords(label, date, salon, words) {
        // hladanie retazca v nazve a keywords        
        var result = 0;
        words.forEach(word => {
            if (label.toLowerCase().includes(word.toLowerCase()) || salon.toLowerCase().includes(word.toLowerCase()) || date.toLowerCase().includes(word.toLowerCase())) {
                result += 1;
            }
        });
        // ak je pocet najdených slov == poctu hladaných slov -> je zhoda
        return words.length == result;
    }

    const PageInit = (tmp) => {
        // VYPOCET POCTU STRAN
        setItemsFiltered(tmp);

        var count = tmp.length;
        count = Math.ceil(count / itemsMax);
        setPageMax(count);

        Redraw(tmp, 0, '');
    }

    const PageChange = (event, value) => {
        // PAGINATION
        var page_ = value - 1;

        setPage(page_);
        Redraw(items, page_, search);
    };

    const GoToStart = () => {
        setTimeout(() => {
            const element = document.getElementById('id_start');

            if (element != null) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }


    const AddNew = () => {
        setEdit(false);
        setShowCreate(true);
    }

    const LessonResult = (redraw) => {
        
        setShowLesson(false);
        if (redraw == true) {
            db_get();
        }
    }


    const SelectItem = (item) => {
        if (item.typ != global.lesson_canceled) {
            setItemSelected(item);
            setShowLesson(true);
        }
    }


    const ChangeYear = (year, month) => {
        setActualYear(year)
        db_get(year);
    }

    /*    
    *
    * =================================================================================================================
    * RENDER
    * =================================================================================================================
    *
    */
    return (
        <Paper elevation={global.menu_elevation} style={{ width: props.width, height: props.height, background: global.themes[props.theme].theme_window_background, borderRadius: props.radius }}>
            <div style={{ ...styles.BlockRow, height: global.dialog_bar_height, borderTopLeftRadius: props.radius, borderTopRightRadius: props.radius, background: global.themes[props.theme].window_bar_color }}>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 100, height: global.dialog_bar_height }}>
                        <div style={{ ...styles.Block, width: global.dialog_icon_size, height: global.dialog_icon_size, background: global.themes[props.theme].icon_background, borderRadius: 7 }} >
                            <FontAwesomeIcon style={{ height: 20, color: global.theme_white }} icon={faBookOpen} />
                        </div>
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? '45%' : '50%', height: global.dialog_bar_height, marginLeft: props.isSmall ? 10 : 0 }}>
                    <div style={{ ...styles.BlockLeft, height: global.dialog_bar_height, justifyContent: 'center' }}>
                        <p style={{ ...styles.TextDialogLabel, textAlign: 'left' }}>{props.title}</p>
                        {props.isSmall ? null :
                            <p style={{ ...styles.TextDialogSubLabel, marginTop: 4 }}>{props.sub_title}</p>
                        }
                    </div>
                </div>

                <div style={{ ...styles.BlockRow, justifyContent: 'flex-end', alignItems: 'center', width: props.isSmall ? 160 : '30%', height: global.dialog_bar_height }}>
                    <TextField
                        value={search}
                        onInput={e => Search(e.target.value)}
                        size="small"
                        autoCapitalize="off"
                        autoComplete="off"
                        autoCorrect="off"
                        style={{ width: props.isSmall ? 150 : 300 }}
                        placeholder={lang.search_in_category}
                        onKeyDown={(event) => {
                            if (event.key === 'Enter') {
                            }
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton onClick={() => Search('')} style={{ width: 20, height: 20 }}>
                                    <FontAwesomeIcon style={{ width: 10 }} icon={faXmark} />
                                </IconButton>
                            </InputAdornment>,
                            style: { backgroundColor: global.theme_white, borderRadius: 30 },
                        }}
                        variant="outlined"
                    />
                </div>
                {props.isSmall ? null :
                    <div style={{ ...styles.Block, width: 200, height: global.dialog_bar_height, marginLeft: 20 }}>
                        {props.user.shared == true || props.admin == true ?
                            <Button onClick={() => AddNew()} style={{ ...styles.ButtonDark, backgroundColor: color.theme_dark, width: 180 }}>
                                <FontAwesomeIcon style={{ height: 16, color: global.theme_white, marginRight: 10 }} icon={faPlusCircle} />
                                <p style={{ ...styles.TextTiny, color: global.theme_white }}>
                                    {lang.lesson_new}
                                </p>
                            </Button>
                            : null}
                    </div>
                }
                <div style={{ ...styles.Block, width: props.isSmall ? 70 : 100, height: global.dialog_bar_height }}>
                    <IconButton onClick={() => Close()} style={{ width: 36, height: 36, backgroundColor: global.theme_white }}>
                        <FontAwesomeIcon style={{ width: 10, color: global.theme_black }} icon={faXmark} />
                    </IconButton>
                </div>
            </div>
            {/* BODY */}
            <div style={{ ...styles.BlockCenter, height: props.height - global.dialog_bar_height, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius }}>
                <div style={{ ...styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                    <div style={{ ...props.isSmall ? styles.Block : styles.BlockRow, height: props.height - global.dialog_bar_height }}>
                        <div style={{ ...styles.BlockCenter, width: props.width, height: props.height - global.dialog_bar_height - selectHeight, backgroundColor: global.theme_back, borderBottomLeftRadius: props.radius, borderBottomRightRadius: props.radius, overflowY: 'scroll' }}>

                            < div id={'id_start'} style={{ ...styles.BlockCenter, width: '98%' }}>
                                <div style={{ ...styles.BlockRight, maxWidth: rowWidth }}>
                                    <div style={{ ...styles.BlockRowRaw }}>
                                        <div style={{ ...styles.Block, height: 60 }}>
                                            <p style={{ ...styles.TextTiny }}>{lang.lessons_list_year}</p>
                                        </div>
                                        <div style={{ ...styles.Block, width: 200, height: 60 }}>
                                            <SelectYear min={2020} value={actualYear} func={ChangeYear.bind(this)} />
                                        </div>
                                    </div>
                                </div>

                                {/* *********************************************************************************************************  
                                            ZOZNAM SKOLENI
                                    ********************************************************************************************************* */}
                                {itemsFiltered != false ?
                                    itemsFiltered.map((item, index) => (
                                        <Paper onClick={() => SelectItem(item)} elevation={overID == item.id ? 5 : 2} key={item.id} style={{ ...styles.Block, maxWidth: rowWidth, height: rowHeight, backgroundColor: global.theme_gray, marginTop: 8, marginBottom: 8, borderRadius: global.row_radius, cursor: 'pointer' }}
                                            onMouseOverCapture={() => setOverID(item.id)} onMouseLeave={() => setOverID(-1)}
                                        >
                                            <div style={{ ...styles.BlockRow }}>
                                                {props.isSmall ?
                                                    <div style={{ ...styles.BlockCenter, height: rowHeight, width: 80, justifyContent: 'center' }}>
                                                        <img src={item.image == false || item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: 60, maxHeight: 60, objectFit: 'contain', borderRadius: 10 }}></img>
                                                    </div>
                                                    :
                                                    <div style={{ ...styles.Block, height: rowHeight, width: rowHeight, justifyContent: 'center' }}>
                                                        <img src={item.image == false || item.image == null || item.image == '' ? no_image : GetImageLink(item.image)} style={{ width: '100%', height: '100%', maxWidth: rowHeight, maxHeight: rowHeight, objectFit: 'contain', borderRadius: global.row_radius }}></img>
                                                    </div>
                                                }
                                                {props.isSmall ?
                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: '72%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <div ostyle={{ ...styles.BlockLeft }}>
                                                            <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.title}</p>
                                                            <p style={{ ...styles.TextTiny, marginTop: 5 }}>{GetDate(item.date)} {GetTimeHM(item.time)} {lang.hour_short}</p>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ ...styles.BlockLeft, height: rowHeight, width: '87%', justifyContent: 'center', marginLeft: 10 }}>
                                                        <div style={{ ...styles.Block, height: rowHeight, justifyContent: 'center' }}>

                                                            <div style={{ ...styles.BlockLeft }}>
                                                                <div style={{ ...styles.BlockRowRaw }}>
                                                                    {item.typ != global.lesson_canceled && item.online == true ?
                                                                        <div style={{ ...styles.Block, width: 80, backgroundColor: global.theme_dark_blue, height: 20, borderRadius: 10, marginRight: 10 }}>
                                                                            <p style={{ ...styles.TextXTiny, color: global.theme_white }}>{lang.lesson_online_} </p>
                                                                        </div>
                                                                        : null}
                                                                    <p style={{ ...styles.TextSmall, fontWeight: '600', textAlign: 'left' }}>{item.title}</p>
                                                                </div>
                                                            </div>

                                                            <div style={{ ...styles.BlockRow, marginTop: 10 }}>
                                                                <div style={{ ...styles.BlockLeft, width: '50%' }}>
                                                                    <p style={{ ...styles.TextTiny }}>{GetDate(item.date)} {GetTimeHM(item.time)} {lang.hour_short}</p>
                                                                    <p style={{ ...styles.TextTiny, marginTop: 2 }}>{lang.lesson_duration}: {GetHoursFromMinutes(item.duration, lang)}</p>
                                                                </div>
                                                                <div style={{ ...styles.BlockRight, width: '50%', marginRight: 15 }}>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Paper>
                                    )) :
                                    // ZIADNE POLOZKY                            
                                    <div style={{ ...styles.Block, paddingTop: 20 }}>
                                        <EmptyList lang={lang} />
                                    </div>
                                }

                                {itemsFiltered != false ?
                                    pageMax > 1 ?
                                        <div style={{ ...styles.Block, paddingTop: 30, paddingBottom: 30 }}>
                                            <Pagination count={pageMax} size={'small'} page={page + 1} onChange={PageChange} showFirstButton showLastButton />
                                        </div>
                                        : null
                                    : null}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            {showLesson == true ?
                <LessonDetails preview={true} item={itemSelected} remoteApp={props.remoteApp} user={props.user} lang={props.lang} language={props.language} offset={props.offset} theme={props.theme} radius={props.radius} isSmall={props.isSmall} func={LessonResult.bind(this)} />
                : null}

            {showOK == true ?
                <ShowOK offset={props.offset} theme={props.theme} func={ShowOKPress.bind(this)} />
                : null}

            {showError == true ?
                <ShowError text={lang.db_error} offset={props.offset} theme={props.theme} func={ShowErrorPress.bind(this)} />
                : null}

            {isBusy == true ?
                <Loading offset={props.offset} theme={props.theme} ></Loading>
                : null}

        </Paper >
    );
}

